import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {Alert, Button, Col, Drawer, Form, Grid, List, Row, Space} from 'antd';
import {useTranslation} from "react-i18next";
import {
    ExternalFile,
    Task,
    TaskMember,
    TaskSaveMutation,
    TasksView,
    useTaskQuery,
    useTaskSaveMutation
} from "../../generated-types";
import TaskCard, {prepareTask, valuesToTaskInput} from "./TaskCard";
import {makeVar, ReactiveVar, useReactiveVar} from "@apollo/client";
import Spinner from "../Spinner";
import ProjectBreadcrumb from "../ProjectBreadcrumb";
import TaskResolved from "./TaskResolved";
import TaskRemoveBtn from "./TaskRemoveBtn";
import TaskEvents, {taskAuthorAndDate} from "./TaskEvents";
import styled, {css} from 'styled-components';
import useToken from 'antd/es/theme/useToken';
import TooltipButton from '../TooltipButton';
import {IconCommentHide} from '../Icon/IconCommentHide';
import {IconCommentShow} from '../Icon/IconCommentShow';
import {IconClose} from '../Icon/IconClose';
import TaskLinkButton from './TaskLinkButton';
import {IconAdditionalMenu} from '../Icon/IconAdditionalMenu';
import Dropdowned from '../Dropdowned';
import UserAvatar from '../User/UserAvatar';
import dayjs from 'dayjs';


const createDateFormat='DD.MM.YYYY HH:MM'
const DrawerBody = styled.div<{ $isMobile: boolean }>`
    display: flex;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    position: relative;
    padding: ${({$isMobile}) => $isMobile ? '0px 16px' : '0'};
    box-sizing: border-box;
    overflow-x: hidden;
`

const widthTransition = css`
transition: max-width, width .1s ease-in-out;
`


const LeftPanel = styled.div<{ $historyOpen: boolean, $isMobile: boolean }>`

position: relative;
    width: ${({$historyOpen, $isMobile}) => $isMobile ? '100%' : ($historyOpen ? '50%' : '100%')};
    height: ${({$isMobile}) => $isMobile ? 'auto' : '100%'};
overflow-x: visible;
${widthTransition};

`

const TaskCreatedBy=styled.div`
    display: flex;
    gap: 4px;
    margin-bottom: 4px;
    font-size: 12px;


    >span.text{
        opacity: .5;
        margin-right: 4px;
        user-select: none;
    }
`

const RightPanel = styled.div<{ $historyOpen: boolean, $isMobile: boolean }>`
    
    max-height: 100%;
    height: ${({$isMobile}) => $isMobile ? 'calc(100% - 80px)' : '100%'};
    max-width: ${({$historyOpen, $isMobile}) => $isMobile ? '100%' : ($historyOpen ? '50%' : '0%')};
    width: ${({$historyOpen, $isMobile}) => $isMobile ? '100%' : ($historyOpen ? '50%' : '0%')};
    ${widthTransition};
  
`

const LeftPanelContent=styled.div<{$isMobile: boolean}>`
    height: 100%;
    overflow-y: auto;
    padding: ${({$isMobile})=>$isMobile? '16px 0px' : '16px 32px'};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
`
interface TaskEditProps {
    // taskId: Task
}

const ListItemStyled=styled(List.Item)`
    padding: 4px 0px !important;
`

export const taskEditorState = makeVar<{ref: React.RefObject<HTMLElement>, historyOpen: boolean} | null>(null)

interface TaskCardShowState{
    historyOpen: boolean
}
const getTaskEditorShowState=(id: string)=>{
    const txt=localStorage.getItem(`taskCard${id}`);

    let res: TaskCardShowState={
        historyOpen: true
    }
    
    if(txt){
        const saved=JSON.parse(txt)
        for(const key in res)
            if(saved[key]===undefined)
                return res

        return saved as TaskCardShowState   
    }

    return res
}

const saveTaskEditorState=(id: string, state: TaskCardShowState)=>{
    localStorage.setItem(`taskCard${id}`, JSON.stringify(state))
    
}

const TaskEditorModal: React.FC<TaskEditProps> = () => {
    const { t } = useTranslation();
    // const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [form] = Form.useForm();
    // const [task, setTask] = useState<Task>({title: "", description: ""} as Task);
    const ctx = useReactiveVar(editTaskContext);
    // const allP = useReactiveVar(allProjects);
    const [, token] = useToken()
    const rightBlockRef = useRef<HTMLDivElement>(null)
    const rightBlockContentRef = useRef<HTMLDivElement>(null)
    const drawerBodyContentRef = useRef<HTMLDivElement>(null)
    const [hbpOpen, setHbpOpen]=useState(false)
    const screen = Grid.useBreakpoint()
    const taskId = ctx.taskId;
    const [taskCardShowState, setTaskCardShowState] = useState(getTaskEditorShowState(taskId || ''))
    const createByCtx=useReactiveVar(taskAuthorAndDate)
    
    useEffect(()=>{
        taskEditorState({historyOpen: taskCardShowState.historyOpen, ref: drawerBodyContentRef})
    }, [taskCardShowState.historyOpen])

    useEffect(()=>{
        taskAuthorAndDate({author: undefined, date: undefined})
    },[])


    // TODO: тут не надо пустую строку передавать. как лучше поступить, чтобы избавиться от этого?
    const { data, error, loading } = useTaskQuery({
        variables: { id: taskId ?? "" },
        onCompleted: (data) => {
            const preparedTask = prepareTask(data.task as Task);
            form.setFieldsValue(preparedTask);
        }
    });
    const isBlocked = data?.task.project.paymentAccount?.isBlocked;
    const isArchived = data?.task.project.archived;
    
    const [taskSave] = useTaskSaveMutation({
        optimisticResponse: ({ id, task: ttt }) => {
            const members = ttt.members.map(m => ({
                id: m.userId,
                memberType: m.memberType,
                user: project.members.find(pm => pm.user.id == m.userId)?.user
            } as any));

            // Почему-то не пересчитывается счетчик файлов на карточке на доске.
            const files = ttt.files
            const { title, tags, status, endTime, endDate, startTime, startDate, description, importance } = ttt;
            const res = {
                taskSave: {
                    ...task, title, tags, status,
                    // пока убрал поля дат из обновления. нужно вернуть, когда займёмся датами
                    // endTime, endDate, startTime, startDate,
                    description, importance, members, files
                }
            } as TaskSaveMutation;
            return res;
        },

        // TODO: эта штука не работает для моих задач. Из-за этого пришлось впендюрить pollInterval в TaskViewList
        // refetchQueries: [RefetchesList.TasksByViewId],
    });

    if (!taskId)
        return null;

    /*if (!open)
        setOpen(true)*/

    if (!data)
        return <Spinner />

    const task = data.task as Task;
    const { project } = task;

    const onClose = () => {
        
        form.submit();//Валидируем поля формы
        saveTaskEditorState(taskId!, taskCardShowState)
        setOpen(false);
        //TODO: Придумать что-то другое по этой теме, попап должен плавно закрываться, но если мы ставим undefined, то он просто резко исчезает,
        //пока я поставил задержку, чтопы попап успел закрыться
        setTimeout(() => editTaskContext({ taskId: undefined }), 1000)
        taskAuthorAndDate(undefined)


    };

    const save = (values: any, members: TaskMember[], files: ExternalFile[], tags: string[]) => {
        const variables = {
            id: taskId,
            task: valuesToTaskInput(values, members, files, tags)
        };
        //variables.task.status = task.status
        variables.task.projectId = task.project.id

        taskSave({ variables })
    };

    const historyButtonStyle: CSSProperties = {
        border: 0,
        borderRadius: 4,
        width: 24,
        height: 24,
        fontSize: 16,
        backgroundColor: token.colors.ui.bgLight,
        position: 'absolute',
        boxShadow: 'none',
        top: '50%',
        right: 0,
        transform: taskCardShowState.historyOpen ? 'translate(50%,-50%)' : 'translate(-4px,-50%)',
        color: token.colors.font.primary,
        transition: 'transform .3s ease-in-out'
    }

    const drawerWidth = () => {
        if (screen.md)
            return taskCardShowState.historyOpen ? 1131 : 564
        if (screen.sm)
            return taskCardShowState.historyOpen ? '100svw' : '50svw'
        if (screen.xs)
            return '100%'

    }
    //Закрытие блока истории без его сжатия
    const historyButtonClickHandler = () => {
        if (rightBlockContentRef.current && taskCardShowState.historyOpen) {
            const width = rightBlockContentRef.current.clientWidth
            rightBlockContentRef.current.style.width = width + 'px'

            window.requestAnimationFrame(()=>setTaskCardShowState(prev=>({...prev, historyOpen: false})))

        }

        if (rightBlockContentRef.current && drawerBodyContentRef.current && !taskCardShowState.historyOpen) {
            const rightBlockContent = rightBlockContentRef.current
            const width = drawerBodyContentRef.current.clientWidth
            rightBlockContent.style.width = width + 'px'



            const onTransitonEnd = () => {
                if (rightBlockRef.current) {

                    rightBlockContent.style.width = '100%'
                    rightBlockRef.current?.removeEventListener("transitionend", onTransitonEnd)

                }
            }

            rightBlockRef.current?.addEventListener("transitionend", onTransitonEnd)

            setTaskCardShowState(prev=>({...prev, historyOpen: true}))
        }
    }

    const removeButton=<TaskRemoveBtn 
    taskTitle={task.title}
    reverse={!screen.xs}
    buttonStyle={{paddingLeft: screen.xs? 8 : undefined }}
     fullView 
     disabled={isBlocked || isArchived}
     id={task?.id}
     onChange={(value) => {
        if (value)
            onClose()
            }}
            />

    const copyLinkButton = <TaskLinkButton fullView={screen.xs} taskId={taskId} projectId={task.project.id}/>

    const headerButton=screen.xs? <><Button
    icon={<IconAdditionalMenu style={{opacity: 0.5}}/>}
    style={{color: token.colors.ui.bgDark,
        fontSize: 16,
        backgroundColor: token.colors.ui.bgLight,
        border: 'unset',
        padding: 4,
        boxShadow: 'unset'}}
    onClick={()=>setHbpOpen(true)}
    />
    {hbpOpen && <Dropdowned title='Еще' opened={hbpOpen} onClose={()=>setHbpOpen(false)}>
        <List style={{marginBottom: 12}}>
            <ListItemStyled>
                {copyLinkButton}
            </ListItemStyled>
            <ListItemStyled>
                {removeButton}
            </ListItemStyled>
        </List>
    </Dropdowned>}
    </>
    :
    copyLinkButton
        
    return (
            <Drawer key="2"
                title={<>
                    <Row style={{alignItems: 'center', marginRight: screen.xs? 16 : 8}}>
                        <Col flex={"auto"}>{task?.id && <TaskResolved
                            disabled={isBlocked || isArchived}
                            id={task.id}
                            value={task.resolved}
                            fullView
                             />}
                        </Col>
                        <Col>
                            <Space size={screen.xs? 16 : 8}>
                                <span style={{opacity: 0.5, fontWeight: 500}}>{t('task.task')} #{task.number}</span>
                                {headerButton}
                            </Space>
                        </Col>
                    </Row>
                    {/*<TaskChangeColumn taskView={ta} task={task} />*/}
                </>}
                width={drawerWidth()}
                push={false}
                onClose={onClose}
                open={open}
                closeIcon={<IconClose style={{fontSize: 24}}/>}
                styles={{
                    body: { padding: 0 },
                    header: {
                            height: screen.xs? 90 : 60,
                            padding: screen.xs? '50px 16px 8px 16px' : '16px 32px',
                            backgroundColor: token.colors.ui.bgLight2
                    },
                    
                }
                }
                
            // footer={
            //     <Space direction="vertical" style={{ width: '100%' }}>
            //         <Button disabled={isBlocked ||isArchived} type="primary" block onClick={form.submit} htmlType="submit">
            //             {t('task.save')}
            //         </Button>
            //     </Space>
            // }
            >
                <Spinner loading={loading} />
                {error && <Alert type="error" message={error.message} banner />}
                {!loading && !error && <DrawerBody ref={drawerBodyContentRef}
                    style={{ flexDirection: screen.xs ? 'column' : 'row' }}
                                                   $isMobile={screen.xs!}>
	                <LeftPanel $historyOpen={taskCardShowState.historyOpen} $isMobile={screen.xs!}>
                        <LeftPanelContent $isMobile={screen.xs?? false}>
                            <div>
                                {createByCtx?.author && <TaskCreatedBy>
	                                <UserAvatar user={createByCtx.author} size='16' addTitle={true}/>
                                    <span className='text'>{dayjs(createByCtx.date).format(createDateFormat)}</span>
                                </TaskCreatedBy>}
                                <ProjectBreadcrumb projectId={project.id} />
                            </div>
                            <TaskCard projectId={project.id}
                                task={task} save={save}
                                project={project}
                                form={form}
                            />
                            {!screen.xs && <Row style={{flexDirection: 'row-reverse'}}>
                                {removeButton}
                                </Row>}
                        </LeftPanelContent>
                        {!screen.xs && <TooltipButton style={historyButtonStyle}
                            onClick={historyButtonClickHandler}
                            icon={taskCardShowState.historyOpen ? <IconCommentHide /> : <IconCommentShow />}
                            styles={{icon: {opacity: 0.5}}}
                            tooltipProps={{ title: taskCardShowState.historyOpen ? t('events.hideHistory') : t('events.showHistory'), placement: "leftTop" }} />}
                    </LeftPanel>
                    <RightPanel style={{
                        backgroundColor: token.colors.ui.bgLight2,
                        borderRadius: screen.xs ? 16 : 0
                    }}
                                $historyOpen={taskCardShowState.historyOpen}
                                $isMobile={screen.xs!}
                        ref={rightBlockRef}
                    >
                            <div style={{ height: '100%', transition: 'width .3s ease-in-out', width: screen.xs? '100%' : undefined }} ref={rightBlockContentRef}>
                                <TaskEvents key={task.id} taskId={task.id} />
                            </div>
                    </RightPanel>
                </DrawerBody>}
            </Drawer>
    );
};


// обертка, чтобы не делать лишний запрос на сервер с пустым идентификатором задачи
const TaskEditor: React.FC<TaskEditProps> = (props) => {
    const ctx = useReactiveVar(editTaskContext);
    const taskId = ctx.taskId;

    if (!taskId)
        return null;

    return <TaskEditorModal {...props} />;
};

interface EditTaskContext {
    taskId: string | undefined
    taskViewId?: string
    taskView?: TasksView
}

function emptyState(): EditTaskContext {
    return {
        taskId: undefined,
        taskViewId: undefined,
        taskView: undefined
    }
}

export const editTaskContext: ReactiveVar<EditTaskContext> = makeVar<EditTaskContext>(emptyState());

export default TaskEditor;