import React, {useEffect, useMemo, useState} from 'react';
import {SearchOutlined} from '@ant-design/icons';
import {Col, Divider, Input, Row} from 'antd';
import {useTranslation} from "react-i18next";
import {Project, ProjectMemberInvite, UsersUnion} from "../../generated-types";
import ProjectNew from "../Project/ProjectNew";
import ProjectsFilter, {AvailableFilters, AvailableFlags, FilterKeyItem} from "../Project/ProjectsFilter";
import {isMobile} from "react-device-detect";
import {IconBoards} from "../Icon/IconBoards";
import {IconTasks} from "../Icon/IconTasks";
import IconStateSwitcher, {IconState} from "../Tasks/IconStateSwitcher";
import UsersUnionProjectsBoard from "./UsersUnionProjectsBoard";
import ProjectsGroup from "../Project/ProjectsGroup";
import ProcessProjectInvite from "./ProcessProjectInvite";
import ProjectsListCard from "../Project/ProjectsListCard";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../../routes/Auth/authContext";


enum DisplayView {
    list,
    board
}


// TODO: настройки конкретного отображения задач надо сохранять на сервере для конкретного пользователя и оттуда же считывать
const getDefaultProjectsDisplayParams = (id: String): UUDisplayParams => {
    const txt = localStorage.getItem(`uu${id}`);

    let res = {} as UUDisplayParams;

    if (txt) {
        const saved = JSON.parse(txt);
        if (saved) {
            res = saved as UUDisplayParams
        }
    }
    if (!res.displayView)
        res.displayView = DisplayView.board;

    return res
}

const saveProjectsDisplayParams = (id: String, p: UUDisplayParams) => {
    const s = JSON.stringify(p);
    localStorage.setItem(`uu${id}`, s);
}

interface UUDisplayParams {
    displayView: DisplayView,
    archived: boolean,
    filter: FilterKeyItem[],
}

export interface UsersUnionProjectsListProps {
    union: UsersUnion;
    onChange?: (checked: boolean) => void;
}


const UsersUnionProjectsList: React.FC<UsersUnionProjectsListProps> = ({ union, onChange }) => {
    const { t } = useTranslation();
    const [displayParams, setDisplayParams] = useState<UUDisplayParams>({} as UUDisplayParams);
    const [inviteInfo, setInviteInfo] = useState<ProjectMemberInvite | null>(null);
    const [search, setSearch] = useState<string | undefined>();
    const authInfo = useReactiveVar(authState);

    useEffect(() => {
        // TODO: надо разобраться, как не вызывать сохранение сразу после загрузки из локалстореджа
        if (displayParams.displayView === undefined) {
            setDisplayParams(getDefaultProjectsDisplayParams(union.id))
        } else
            saveProjectsDisplayParams(union.id, displayParams)
    }, [displayParams]);

    const onSearch = (value: string) => {
        setSearch(value)
    }

    const allProjects: Project[] = useMemo(() => {
        let res = union.ownedProjects
        res.concat(union.projectInvites.map(i => i.project));
        return res;
    }, [union, displayParams, search])

    const projects: Project[] = useMemo(() => {
        let res: Project[] = [];

        const f = displayParams?.filter;

        if (f && f.find(v => v.type == AvailableFilters.flags && v.key == AvailableFlags.invites)) {
            res = union.projectInvites.map(i => i.project);
        } else {
            res = union.ownedProjects
            res.concat(union.projectInvites.map(i => i.project));
        }

        if (search?.length && search) {
            res = res.filter((p) => (
                p.title.toUpperCase().includes(search) ||
                p.usersUnionAsOwner?.title.toUpperCase().includes(search) ||
                p.members.some((m) => m.user.username.toUpperCase().includes(search) || m.user.fullName?.toUpperCase().includes(search))
            ))
        }

        if (f) {
            const iamMember = f.some(v => v.type == AvailableFilters.flags && v.key == AvailableFlags.iamMember);
            if (iamMember) {
                res = res.filter(t => t.members.some(m => m.user.id === authInfo.user.id))
            }

            if (f.filter(f => f.type == AvailableFilters.tags).length > 0) {
                const ff = f.filter(f => f.type == AvailableFilters.tags).map(t => t.key);
                res = res.filter(t => t.attachedTags.some(tg => ff.some(f => tg == f)))
            }

            if (f.filter(f => f.type == AvailableFilters.members).length > 0) {
                const ff = f.filter(f => f.type == AvailableFilters.members).map(t => t.key);
                res = res.filter(t => t.members.some(m => ff.some(f => m.user.id == f)))
            }

            if (f.filter(f => f.type == AvailableFilters.usersUnions).length > 0) {
                const ff = f.filter(f => f.type == AvailableFilters.usersUnions).map(t => t.key);
                res = res.filter(t => ff.some(f => t.usersUnionAsOwner?.id == f))
            }
        }

        return res;
    }, [allProjects, displayParams, search])

    return <>
        <div style={{width: "100%", flexGrow: 1, display: "flex", flexDirection: "row", height: "100%", gap: "8px"}}>
            <IconStateSwitcher
                defaultValue={displayParams.displayView}
                onChange={(v => {
                    setDisplayParams({...displayParams, displayView: v})
                })}
                title={isMobile ? undefined : t('events.view')}
                items={[
                    {
                        state: DisplayView.board,
                        icon: <IconBoards/>,
                        tooltipText: t('task.displayType.' + DisplayView[DisplayView.board])
                    },
                    {
                        state: DisplayView.list,
                        icon: <IconTasks/>,
                        tooltipText: t('task.displayType.' + DisplayView[DisplayView.list])
                    }
                ] as IconState<DisplayView>[]
                }
            />
            {<ProjectsFilter projects={allProjects}
                             disable={!allProjects}
                             showFilters={{
                                 usersUnions: true,
                                 tags: true,
                                 flags: true,
                                 members: true
                             }}
                             onFilterChanged={(filter: FilterKeyItem[]) => {
                                 const archived = filter.some(v => v.type == AvailableFilters.flags && v.key == AvailableFlags.archived);
                                 const active = filter.some(v => v.type == AvailableFilters.flags && v.key == AvailableFlags.active);

                                 // Правильно выключение-включение лишних галочек, т.к. могут показываться либо проекты в архиве либо активные
                                 if (archived && active && !displayParams.archived) {
                                     filter = filter.filter(v => v.key !== AvailableFlags.active)
                                 } else if (active && archived && displayParams.archived) {
                                     filter = filter.filter(v => v.key !== AvailableFlags.archived)
                                 }

                                 setDisplayParams({...displayParams, archived, filter})

                                 if (displayParams.archived != archived && onChange)
                                     onChange(archived);

                                 return filter;
                             }}/>}

            <Input allowClear variant={"outlined"} width={"100%"}
                   prefix={<SearchOutlined/>}
                   placeholder={t('task.search') as string}
                   onChange={(e) => onSearch(e.target.value?.toUpperCase())}/>

            <ProjectNew isBlocked={union.paymentAccount?.isBlocked} context={union}/>
        </div>

        <div>
            {displayParams.displayView == DisplayView.board &&
					    <UsersUnionProjectsBoard union={union} projects={projects} invites={union.projectInvites}/>}
            {displayParams.displayView == DisplayView.list &&
			        <span>
                <ProjectsGroup projects={projects} context={union.currentUserIsMember ? union : undefined}/>
                  {union.projectInvites.length > 0 && <>
                      {inviteInfo && <ProcessProjectInvite key={inviteInfo?.id} invite={inviteInfo} union={union}
							                                             onClose={() => setInviteInfo(null)}/>}
						        <Divider>{t('usersUnion.projectInvites')}</Divider>
						        <Row gutter={[16, 16]}>{
                        union.projectInvites.map((inv: ProjectMemberInvite) => {
                                const {project} = inv;
                                return <Col key={inv.project.id} flex="300px">
                                    <ProjectsListCard key={project.id} project={project}
                                                      showButtons={false} showFavorite={true}
                                                      onProjectClick={() => {
                                                          setInviteInfo(inv)
                                                      }}
                                    /></Col>;
                            }
                        )}</Row></>
                  }
            </span>
            }
        </div>
    </>
};

export default UsersUnionProjectsList;
