import React, {useState} from 'react'
import {AccessLevel, AttachedFolder, ExternalFile, ExternalFileType, Project} from '../../generated-types'
import AttachFilesButton from '../File/AttachFilesButton'
import {ExternalFileWithId} from './TaskCard'
import {Button, GlobalToken, List, theme, Tooltip} from 'antd'
import {t} from 'i18next'
import DownloadFileButton from '../File/DownloadFileButton'
import PointDivider from '../PointDivider'
import {IconAttantion} from '../Icon/IconAttantion'
import {IconClose} from '../Icon/IconClose'
import {IconFolder} from '../Icon/IconFolder'
import styled from 'styled-components'
import {CenterJustify} from "../Dropdowned";
import {useReactiveVar} from "@apollo/client";
import {editTaskContext, taskEditorState} from "./TaskEditor";
import { authState, yandexAuthState } from '../../routes/Auth/authContext'

interface taskFilesProps {
    folders: AttachedFolder[]
    selectedFiles: ExternalFileWithId[]
    setSelectedFiles: React.Dispatch<React.SetStateAction<ExternalFileWithId[]>>
    project: Project
}

const DeleteFileButton = ({ onClick }: { onClick: () => void }) => {
    const [hover, setHover] = useState(false)

    const { token } = theme.useToken()

    return <Button type="text"
        icon={<IconClose fontSize={16} strokeWidth={1.5} />}
        style={{
            color: hover ? token.colors.ui.accent : token.colors.font.primary,
            border: 'unset',
            backgroundColor: 'unset',
            boxShadow: 'unset',
            padding: 0,
            justifyContent: 'end',
            width: 24,
            opacity: hover ? 1 : 0.5
        }}
        styles={{
            icon: {
                display: 'flex', alignItems: 'center'
            }
        }}
        onClick={() => {
            onClick()
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)} />
}

const StyledListItem = styled(List.Item) <{ token: GlobalToken }>`
    padding: 0;
    justify-content: start;
    box-shadow: none;
    
    &:hover{
        background-color: ${({ token }) => token.colors.ui.bgLight}
    }
`
function TaskFiles({ folders, selectedFiles, setSelectedFiles, project }: taskFilesProps) {

    const { token } = theme.useToken()
    const folderIsAttached = folders.length > 0
    const {ref, historyOpen} = useReactiveVar(taskEditorState)!
    const {user} = useReactiveVar(authState);
    const yandexAuth=useReactiveVar(yandexAuthState);
    
    const isAdmin=project.members.some(m => m.user.id == user.id && m.accessLevel == AccessLevel.Admin)
    

    const tooltipMessage=t(`file.${isAdmin? (yandexAuth? 'isAdminAndNoFolderAttachMessage' : 'isAdminAndNoYandexDiskAttachMessage') : 'isntAdminAttachMessage'}`)

    return (<div style={
        {
            padding: 16,
            borderRadius: 16,
            backgroundColor: token.colors.ui.bgLight2,
            width: '100%',
        }
    }>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
                <span style={{ opacity: 0.5 }}>
                    {`${t('task.files')}`}
                </span>
                <PointDivider value={selectedFiles.length ?? 0} />
            </span>


            {folderIsAttached && <AttachFilesButton
			        folders={folders} selectable={true} anchor={ref}
			        centerJustify={historyOpen ? CenterJustify.center : CenterJustify.start}
                    onChange={(files: ExternalFile[]) => {
                    setSelectedFiles(files as ExternalFileWithId[])
                }}
                selectedItems={selectedFiles} multipleSelect={true}
                buttonTitle={t('task.addFiles')}
                btnProps={{
                    type: "link", size: "small", color: "primary",
                    style: { height: "100%", flexDirection: 'row-reverse', color: token.colors.font.accent, alignItems: 'center' },
                    styles: { icon: { display: 'flex', alignItems: 'center' } }
                }} />}
        </div>
        {!folderIsAttached && <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            padding: 8,
            backgroundColor: token.colors.ui.bgLight,
            borderRadius: 8,
            opacity: 0.7,
        }}>
            <Tooltip title={tooltipMessage} >
                <IconAttantion style={{ fontSize: 16, color: token.colors.font.primary, width: 16, opacity: 1}} />
            </Tooltip>
            <span style={{ fontSize: 12 }}>
                {t(`file.${isAdmin? 'noConnectedStorage' : 'adminHasToAttachFolder'}`)}
            </span>
        </div>
        }

        {selectedFiles.length > 0 &&
            <List size={"small"} dataSource={selectedFiles} renderItem={(item) => {
                return (item &&
                    <StyledListItem
                        token={token}
                        actions={[
                            <DeleteFileButton onClick={() => setSelectedFiles(selectedFiles.filter(f => f.path !== item.path))} />
                        ]}
                        style={{ padding: 0, justifyContent: 'start', boxShadow: 'none', flexWrap: 'nowrap' }}
                    >
                        <DownloadFileButton file={item} />
                        {item.type === ExternalFileType.Dir && <IconFolder style={{
                            fontSize: 16,
                            opacity: 0.5,
                            marginRight: 4,
                            strokeWidth: 1.5
                        }} />}
                        <span style={{ flexGrow: 1, flexShrink: 1, wordBreak: 'normal', padding: '5px 0px'}}>{item.name}</span>
                    </StyledListItem>
                )
            }}>
            </List>
        }
    </div>
    )
}

export default TaskFiles