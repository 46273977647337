import React, {type SVGProps} from "react"

export function IconFile(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=file, size=24">
        <g id="Frame 3">
            <path id="Vector"
                  d="M4.72705 18.875V6.04169C4.72705 5.28229 5.35665 4.66669 6.1333 4.66669H15.2753C15.4245 4.66669 15.5676 4.72463 15.673 4.82778L18.6248 7.71392C18.7303 7.81708 18.7896 7.95697 18.7896 8.10284V18.875C18.7896 19.6344 18.1599 20.25 17.3833 20.25H6.1333C5.35665 20.25 4.72705 19.6344 4.72705 18.875Z"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2" d="M15.0396 4.66669V7.78335C15.0396 8.08711 15.2914 8.33335 15.6021 8.33335H18.7896"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Line 60" d="M7.7002 14H15.7002" stroke="currentColor" strokeLinecap="round"/>
            <path id="Line 61" d="M7.7002 17H15.7002" stroke="currentColor" strokeLinecap="round"/>
        </g>
    </g>
</svg>

    )
}
