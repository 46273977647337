import React from 'react';
import {
    InviteResolution,
    User,
    UsersUnionMemberInvite,
    useUsersUnionProcessInviteMutation
} from "../../generated-types";
import {useTranslation} from "react-i18next";
import {useReactiveVar} from "@apollo/client";
import {allUsersUnions} from "../../subscriptions/allUsersUnions";
import TopAlertsList from "../Project/TopAlertsList";

const UsersUnionsInvitesList: React.FC<{}> = () => {
    const {t} = useTranslation();
    let myUnions = useReactiveVar(allUsersUnions);

    const [processInvite] = useUsersUnionProcessInviteMutation({ });

    const acceptInvite = (invite: UsersUnionMemberInvite) => {
        if (!invite) return;
        processInvite({
            variables: {
                inviteId: invite.id,
                resolution: InviteResolution.Accepted
            }
        })
    };

    const declineInvite = (invite: UsersUnionMemberInvite) => {
        if (!invite) return;
        processInvite({
            variables: {
                inviteId: invite.id,
                resolution: InviteResolution.Declined
            }
        })
    };


    return <TopAlertsList<UsersUnionMemberInvite>
        onRender={(i) => {
            return <div style={{display: "flex"}}>
                {t('usersUnion.newInvite')} {i.usersUnion.title}
            </div>
        }}
        items={myUnions.invites}
        getUser={i => i.inviter as User}
        acceptTitle={t('project.acceptInvite')}
        declineTitle={t('project.declineInvite')}
        declinePopupTitle={t('project.transferRejectTitle')}
        declinePopupText={t('project.transferRejectBody')}
        onAccept={acceptInvite}
        onDecline={declineInvite}
    ></TopAlertsList>
};

export default UsersUnionsInvitesList;