import React, {useEffect, useState} from 'react';
import {Button, ColorPicker, Input, Popconfirm, theme} from 'antd';
import {
    AllPersonalTagsDocument,
    Project,
    Tag,
    TagFragmentDoc,
    useEditTagMutation,
    useRemoveTagMutation,
    UsersUnion
} from "../../generated-types";
import {useTranslation} from 'react-i18next';
import {Color} from 'antd/es/color-picker';
import {InputStatus} from "antd/es/_util/statusUtils";
import Spinner from "../Spinner";
import {ApolloCache, useReactiveVar} from "@apollo/client";
import {allProjects} from "../../subscriptions/allProjects";
import {allUsersUnions} from "../../subscriptions/allUsersUnions";
import styled from "styled-components";
import type {GlobalToken} from "antd/es/theme/interface";
import {IconEnter} from "../Icon/IconEnter";
import {IconDelete} from "../Icon/IconDelete";

const defaultColors = [
    'rgba(245,34,45,0.5)',
    'rgba(250,140,22,0.46)',
    'rgba(250,219,20,0.49)',
    'rgba(139,187,17,0.48)',
    'rgba(82,196,26,0.51)',
    'rgba(19,168,168,0.49)',
    'rgba(22,119,255,0.51)',
    'rgba(47,84,235,0.46)',
    'rgba(114,46,209,0.47)',
    'rgba(235,47,150,0.46)',
];

export const tagDefaultColor = 'rgba(19,168,168,0.49)'

export interface TagEditorContext {
    projectId?: string | undefined,
    usersUnionId?: string | undefined,
    userId?: string | undefined
}

export interface TagEditorProps {
    context: TagEditorContext,
    tag: Tag,
    removable: boolean
    newItem: boolean
}

const TagEditorStyled = styled.div<{ $token: GlobalToken }>`
    display: flex;

    .ant-input-group-addon {
        cursor: pointer;
        background-color: ${({$token}) => $token.colors.ui.bgLight};
    }

    .ant-input-group-addon:hover {
        background-color: ${({$token}) => $token.colors.ui.bgLight2};
    }

    .ant-color-picker-trigger {
        border: none;
    }

    .ant-color-picker-color-block {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-color-picker-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input {
        line-height: 1.6;
    }

    button {
        font-size: 120%;
        color: ${({$token}) => $token.colors.font.red};
    }
`

const TagEditor = ({tag, removable, context, newItem}: TagEditorProps) => {
    const {t} = useTranslation();
    const [title, setTitle] = useState<string | undefined>(tag.title);
    const [color, setColor] = useState(tag.color ?? tagDefaultColor);
    const [titleStatus, setTitleStatus] = useState<InputStatus>("");
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const allP = useReactiveVar(allProjects);
    const allUnions = useReactiveVar(allUsersUnions);
    const {token} = theme.useToken();

    const [tagEdit] = useEditTagMutation({});

    const [tagRemove] = useRemoveTagMutation({});

    const deleteTag=(tag:Tag)=>{
        setDeleteInProgress(true)
        tagRemove({
            variables:{
                removeTagId:tag.id
            },
            onCompleted:()=> setDeleteInProgress(false),
            update:(cache,{data})=>{

                if (data?.removeTag && context.projectId) {
                    const prj = allP.projects.find(p=>p.id === context.projectId);
                    if (prj)
                        updateCachedRemoveTag(cache, tag, prj)
                }
                else if (data?.removeTag && context.usersUnionId) {
                    const uu = allUnions.unions.find(p=>p.id === context.usersUnionId);
                    if (uu)
                        updateCachedRemoveTag(cache, tag, uu)
                } else if (data?.removeTag && context.userId) {
                    removeCachedPersonalTags(cache, tag)
                }
            }
        })
    }


    const saveTag = () => {
        setTitleStatus(!title ? "error" : "")
        if (!title)
            return;
        setSaveInProgress(true);
    }


    useEffect(() => {
        if (!title) {
            setSaveInProgress(false);
            return;
        }

        if (saveInProgress) {
            tagEdit({
                variables: {
                    projectId: context.projectId,
                    usersUnionId: context.usersUnionId,
                    userId: context.userId,
                    title: title,
                    color: color ?? tagDefaultColor,
                    id: tag.id
                },
                onCompleted: () => {
                    if (newItem) setTitle(undefined)
                    setSaveInProgress(false);
                },
                update: (cache, {data}) => {
                    const tag = data?.editTag as Tag;
                    if (tag && context.projectId) {
                        const prj = allP.projects.find(p=>p.id === context.projectId);
                        if (prj)
                            updateCachedTags(cache, tag, prj)
                    }
                    else if (data?.editTag && context.usersUnionId) {
                        const uu = allUnions.unions.find(p=>p.id === context.usersUnionId);
                        if (uu)
                            updateCachedTags(cache, tag, uu)
                    } else if (data?.editTag && context.userId) {
                        updateCachedPersonalTags(cache, tag)
                    }

                }
            })
        }
    }, [saveInProgress]);

    const ChangeColor = (clr: Color) => {
        const c = clr.toHexString()
        setColor(c)
        if (!newItem) saveTag()
    }

    const ChangeTitle = (ttl: string) => {
        setTitleStatus(!ttl ? "error" : "")
        setTitle(ttl);
    }

    const picker = <ColorPicker
        styles={{
            popupOverlayInner: {zIndex: 1100},
        }}
        key={tag.id}
        value={color}
        onChange={ChangeColor}
        presets={[
            {
                label: null,
                colors: defaultColors,
            }
        ]}
    />;

    if (newItem)
        return (
            <TagEditorStyled $token={token}>
                <Spinner loading={saveInProgress}/>
                {picker}
                <Input placeholder={t("tags.add") as string}
                       value={title}
                       status={titleStatus}
                       onKeyPress={(e) => {
                           if (e.charCode === 13) {
                               saveTag()
                           }
                       }}
                       onChange={(e) => {
                           ChangeTitle(e.target.value)
                       }}
                       addonAfter={<IconEnter className={"save-button"} onClick={saveTag}/>}
                />
            </TagEditorStyled>)

    return (
        <TagEditorStyled $token={token}>
            <Spinner loading={saveInProgress}/>
            {picker}

            <Input placeholder={t("tags.addText") as string}
                   value={title}
                   status={titleStatus}
                   onKeyPress={(e) => {
                       if (e.charCode === 13) {
                           saveTag()
                       }
                   }}
                   onChange={(e) => {
                       ChangeTitle(e.target.value)
                   }}
            />

            {removable && <Popconfirm
					    icon={null}
					    title={t('tags.removeConfirmTitle')}
					    onConfirm={() => deleteTag(tag)}
					    onCancel={() => {
              }}
				    >
					    <Spinner loading={deleteInProgress}/>
					    <Button type="text" danger><IconDelete/></Button>
				    </Popconfirm>}

        </TagEditorStyled>)
}

export default TagEditor;

export const updateCachedTags = (cache: ApolloCache<any>, tag: Tag, obj: Project | UsersUnion) => {
    cache.modify({
        id: cache.identify(obj),
        fields: {
            tags(existsTags) {
	            const tagRef = cache.writeFragment({
                    data: tag,
                    overwrite: true,
                    fragment: TagFragmentDoc
	            });
                if (existsTags.some((v: any)=>v.__ref == tagRef?.__ref)) {
                    return existsTags;
                }
                return [...existsTags, tagRef];
            },
        },
        optimistic: true
    });
}

export const updateCachedPersonalTags = (cache: ApolloCache<any>, tag: Tag) => {
    const __ref = cache.identify(tag)
    cache.writeFragment({
        id: __ref,
        fragment: TagFragmentDoc,
        data: tag
    });

    let {allPersonalTags} = cache.readQuery({query: AllPersonalTagsDocument}) as { allPersonalTags: Tag[] }
    const isNew = !allPersonalTags.some((t)=>t.id==tag.id)

    if (isNew) {
        allPersonalTags = [...allPersonalTags, tag]
        cache.writeQuery({
            query: AllPersonalTagsDocument,
            data: {allPersonalTags}
        });
    }
}

export const updateCachedRemoveTag = (cache: ApolloCache<any>, tag: Tag, obj: Project | UsersUnion)=>{
    const __ref = cache.identify(tag)
    cache.modify({
        id: cache.identify(obj),
        fields: {
            tags(existsTags) {
                return existsTags.filter((v:any)=>v.__ref !== __ref)
            },
        }
    });
}

export const removeCachedPersonalTags = (cache: ApolloCache<any>, tag: Tag) => {
    const tags = cache.readQuery({query: AllPersonalTagsDocument}) as { allPersonalTags: Tag[] }
    const newTags = tags.allPersonalTags.filter((t)=>t.id!==tag.id)
    cache.writeQuery({
        query: AllPersonalTagsDocument,
        data: {allPersonalTags: newTags}
    });
}