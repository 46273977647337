import React, {type SVGProps} from "react"

export function IconStart(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=start, size=16">
        <g id="Frame">
            <g id="Group 8">
                <path id="Vector" d="M11.4004 12L15.4004 8L11.4004 4" stroke="currentColor" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <line id="Line 21" x1="1.09961" y1="1.01855" x2="1.09961" y2="14.9814" stroke="currentColor"/>
                <line id="Line 22" x1="15.2363" y1="8.02734" x2="4.0332" y2="8.02734" stroke="currentColor"/>
            </g>
        </g>
    </g>
</svg>

    )
}
