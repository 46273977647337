import React, {type SVGProps} from "react"

export function IconColorPurple(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=color-purple, size=24" clipPath="url(#clip0_31_6049)">
        <rect id="Rectangle 23" width="24" height="24" rx="4" fill="#CB63C1"/>
    </g>
    <defs>
        <clipPath id="clip0_31_6049">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}
