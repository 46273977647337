import React from "react";
import styled from "styled-components";
import {IconCheck} from "./Icon/IconCheck";
import {Icon} from "./Icon/Icon";

interface CheckboxProps {
    checked?: boolean | undefined
    className?: string
    children: React.ReactNode
}


const StyledLabel = styled.label<{}>`
    /* Customize the label (the container) */

    & {
        display: block;
        position: relative;
        padding-left: 24px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */

    .checkmark {
        position: absolute;
        /* Такая фигня сделана, т.к. я не понимаю, как выровнять этот чек-бокс */
        top: calc(50% - 8px);
        left: 0;
        height: 16px;
        width: 16px;
        background-color: inherit;
        border: 2px solid ${({theme}) => theme.colors.ui.bgLight3};
        border-radius: 4px;

        svg {
            stroke: ${({theme}) => theme.colors.ui.white};
            color: ${({theme}) => theme.colors.ui.white};
            width: 13px !important;
            height: 13px !important;
            position: absolute;
            z-index: 1;
        }
    }

    /* On mouse-over, add a grey background color */

    &:hover input ~ .checkmark {
        background-color: ${({theme}) => theme.colors.ui.bgLight2};
    }

    /* When the checkbox is checked, add a blue background */

    & input:checked ~ .checkmark {
        background-color: ${({theme}) => theme.colors.ui.succes};
        border: 2px solid ${({theme}) => theme.colors.ui.succes};
    }

    /* Create the checkmark/indicator (hidden when not checked) */

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */

    & input:checked ~ .checkmark:after {
        display: block;
    }

    ///* Style the checkmark/indicator */
    //& .checkmark:after {
    //    width: 90%;
    //    height: 90%;
    //    border: solid white;
    //    border-width: 0 3px 3px 0;
    //    -webkit-transform: rotate(45deg);
    //    -ms-transform: rotate(45deg);
    //    transform: rotate(45deg);
    //}
`

export const Checkbox: React.FC<CheckboxProps> = (props) => {
    return (
        <StyledLabel className={props.className}>{props.children}
            <input type="checkbox" checked={props.checked}/>
            <span className="checkmark">
                {props.checked && <Icon icon={<IconCheck/>} size={"16"}/>}
            </span>
        </StyledLabel>
    )
};

export default Checkbox;