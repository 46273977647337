import React, { useEffect, useRef, useState } from 'react';
import { Button, GlobalToken, Space, theme, Tooltip } from "antd";
import { Task, TasksView, TasksViewColumn, useTaskChangeColumnInViewMutation, useTasksViewsQuery } from "../../generated-types";
import { useTranslation } from "react-i18next";
import { IconArrowDown } from '../Icon/IconArrowDown';
import SelectMenuDropdown, { CustomDropdownItem } from '../AntCastomComponents/SelectMenuDropdown';
import Dropdowned from '../Dropdowned';
import SelectMenuDropdown2 from '../AntCastomComponents/SelectMenuDropdown2';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import RefetchesList from '../../queries/RefetchesList';

export const getStyleByStatus = (s: TasksViewColumn, token: GlobalToken) => {
    switch (s.color) {
        case "blue":
            return { color: token.colors.ui.project3 }
        case "orange":
            return { color: token.colors.ui.accent }
        case "green":
            return { color: token.colors.ui.project4 }
        case "red":
            return {}
        default:
            return {}
    }
}

export enum taskChangeColumnView{
    button,
    toggle
}

interface TaskChangeColumnProps {
    taskView: TasksView;
    task: Task
    allowEdit?: boolean,
    onChange?: (column: TasksViewColumn)=>void
    sendOnChange?: boolean //отправлять запрос при изменении
}

interface TaskChangeColumnViewProps{
    taskView: TasksView;
    task: Task
    allowEdit?: boolean,
    onChange: (column: TasksViewColumn)=>void
    selectedColumnId: string
}

const SelectorRowContainer=styled.div<{$selected: boolean, $isMobile: boolean}>`
    padding: ${({$isMobile})=>$isMobile? '12px 16px' : '4px 16px'};;
    user-select: none;
    cursor: pointer;
    position: relative;
    transition: all .3s ease-in-out;
    font-size: ${({$isMobile})=>$isMobile? '14px' : '12px'};

    &:hover{
        background-color: ${({theme})=>theme.colors.ui.bgLight};
    }

    &::before {
        content: '';
        position: absolute;
        top: ${({$isMobile})=>$isMobile? '50%' : '0'};
        transform: ${({$isMobile})=>$isMobile? 'translateY(-50%)' : 'none'};
        left: 0;
        height: ${({$isMobile})=>$isMobile? 'calc(100% - 24px)' : '100%'};
        width: 2px;
        background-color: ${({$selected, theme}) => $selected ? theme.colors.ui.accent : 'unset'};
    }
`
const SelectorRow=styled.div<{$isMobile: boolean}>`
    width: '100%';
    height: 16px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4px;
`

const SelectDropdownStyled=styled(SelectMenuDropdown2)<{$isMobile: boolean}>`
    padding: ${({$isMobile})=>$isMobile? '4px 0' : '16px 0'};

`

const RowDivider=styled.div`
    background-color: ${({theme})=>theme.colors.ui.bgLight3};
    height: 1px;
    margin: 0 16px;
`
const TaskChangeColumnLabel = ({ column }: { column: TasksViewColumn }) => {

    const { t } = useTranslation()
    const { token } = theme.useToken()

    return <SelectorRow $isMobile={isMobileOnly}>
        <span style={getStyleByStatus(column, token)}>•</span>
        <span style={{ lineHeight: 1, textAlign: 'start' }}>{t('task.columns.' + column.title)}</span>
    </SelectorRow>
}

const findColumn=(predicate: (value: TasksViewColumn, index: number, obj: TasksViewColumn[]) => unknown, taskView: TasksView)=>{
    return taskView.columns.find(predicate)?? taskView.columns[0]
}

const TaskChangeColumnButtonView=({taskView, task, allowEdit=true, selectedColumnId, onChange}: TaskChangeColumnViewProps)=>{

    const [hover, setHover] = useState(false)
    const btnRef = useRef<HTMLButtonElement>(null)
    const [opened, setOpened] = useState(false)
    const { t } = useTranslation();
    const { token } = theme.useToken();

    const backgroundColor = hover ? token.colors.ui.bgLight : token.colors.ui.bgLight3

    const dropdownItems = taskView.columns?.filter((c: TasksViewColumn) => c != undefined).map((clmn: TasksViewColumn) => ({
        //label: <TaskChangeColumnSelectRow column={clmn} />,
        type: 'item',
        selected: clmn.id===selectedColumnId,
        key: clmn.id,
    }) as CustomDropdownItem)


    
    return <>
        <Button style={{
            width: 165,
            backgroundColor: backgroundColor,
            border: '0px',
            boxShadow: 'unset',
            color: token.colors.font.primary,
            flexDirection: 'row-reverse',
            boxSizing: 'border-box',
            borderRadius: 8,
            padding: 8,
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 16,
            height: 'auto'

        }}
            styles={{ icon: { display: "flex", alignItems: 'center' } }}
            icon={<IconArrowDown strokeWidth={'1.5px'} />}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => setOpened(true)}
            ref={btnRef}
        >
            {   <div style={{display: 'flex', gap: 8, fontSize: 12}}>
                    <TaskChangeColumnLabel column={findColumn(col=>col.id===selectedColumnId, taskView)}/>
                </div>
                }
        </Button>
        {opened && <Dropdowned opened={opened}
            title={t('task.priority.prioritySelectTitle')}
            onClose={() => setOpened(false)}
            //styles={{dropdown: {width: 'auto'}}}
            anchor={btnRef}
        >
            <SelectDropdownStyled $isMobile={isMobileOnly} 
            divider={isMobileOnly? <RowDivider/> : undefined}
            items={dropdownItems} 
            renderItem={(item)=>{

                const column=findColumn(elem=>elem.id===item.key, taskView)

                return <SelectorRowContainer $selected={item.selected} $isMobile={isMobileOnly}>
                    <TaskChangeColumnLabel column={column}/>
                </SelectorRowContainer>
            }}
            onSelect={(key)=>{
                const clmn=findColumn(col=>col.id===key.toString(), taskView)
                if (clmn)
                    onChange(clmn)

                setOpened(false)
                return true;
            }}
            />
        </Dropdowned>}
    </>
}

const ToggleContainer=styled.div`
    display: flex;
    overflow: hidden;
    gap: 1px;
    background-color: ${p=>p.theme.colors.ui.bgLight3};
    border-radius: 8px;
    width: fit-content;
`

const ToggleButton=styled(Button)<{$columnColor: string}>`
        position: relative;
        border: none;
        background-color: ${p=>p.theme.colors.ui.bgLight};
        box-shadow: none;
        border-radius: 0;
        padding: 8px;
        color: ${p=>p.theme.colors.font.primary} !important;

        >div{
            z-index: 1;
        }

        &.toggle-btn-selected{
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: ${p=>p.$columnColor};
                z-index: 0;
                opacity: .3;
            }
        }
`
const TaskChangeColumnToggleView=({taskView, task, selectedColumnId, onChange}: TaskChangeColumnViewProps)=>{

    const {token}=theme.useToken()

    return <ToggleContainer>
                    {
                        taskView.columns?.filter((c: TasksViewColumn) => c != undefined).map((clmn: TasksViewColumn) => 
                            <ToggleButton key={clmn.id} 
                            $columnColor={getStyleByStatus(clmn, token).color?? ''}
                            className={selectedColumnId===clmn.id? 'toggle-btn-selected' : ''}
                            onClick={()=>{
                                if(selectedColumnId!==clmn.id)
                                    onChange(clmn)
                            }}>
                                <TaskChangeColumnLabel column={clmn}/>
                            </ToggleButton>)
                    }
            </ToggleContainer>
}

const TaskChangeColumn = ({ taskView, task, allowEdit = true, view=taskChangeColumnView.button, sendOnChange=true, onChange }: TaskChangeColumnProps & {view?: taskChangeColumnView}) => {

    const [selectedColumnId, setSelectedColumnId]=useState('')
    const [changeColumn] = useTaskChangeColumnInViewMutation()

    useEffect(()=>{
        const selectedCol=findColumn(clm=>clm.title===task.status, taskView)

        setSelectedColumnId(selectedCol.id)
    },[task.status])

    const onColumnChange=(column: TasksViewColumn)=>{
        if(sendOnChange)
            changeColumn({
                variables: {
                    taskId: task.id,
                    taskViewId: taskView.id,
                    columnId: column.id
                },
                onCompleted: ()=>{
                    setSelectedColumnId(column.id)
                    onChange && onChange(column)
                }
        })
        else {
            setSelectedColumnId(column.id)
            onChange && onChange(column)
        }
    }

    const props={taskView, task, onChange: onColumnChange};

    switch(view){
        case taskChangeColumnView.toggle:
            return <TaskChangeColumnToggleView {...props} selectedColumnId={selectedColumnId}/>
        case taskChangeColumnView.button:
                return <TaskChangeColumnButtonView {...props} selectedColumnId={selectedColumnId}/>;
        default:
            return <TaskChangeColumnButtonView {...props} selectedColumnId={selectedColumnId}/>
    }
}

export default TaskChangeColumn;
