import React, {type SVGProps} from "react"

export function IconSend(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=send, size=16">
        <path id="Vector"
              d="M2.06251 14.024C1.81387 14.024 1.57541 13.9252 1.39959 13.7494C1.22378 13.5736 1.12501 13.3351 1.12501 13.0865V9.79054C1.12415 9.5673 1.20335 9.35115 1.34824 9.18132C1.49313 9.01149 1.69411 8.89923 1.91469 8.86491L7.33688 7.99991L1.91469 7.13523C1.69411 7.10078 1.49316 6.98844 1.34829 6.81857C1.20342 6.6487 1.1242 6.43255 1.12501 6.20929V2.91366C1.12499 2.75894 1.16327 2.60662 1.23643 2.47028C1.30959 2.33395 1.41536 2.21784 1.54429 2.13232C1.67323 2.04679 1.82133 1.99451 1.97539 1.98013C2.12944 1.96575 2.28466 1.98973 2.42719 2.04991L14.2338 7.03304C14.4238 7.11336 14.586 7.24791 14.7 7.41988C14.814 7.59184 14.8748 7.79359 14.8748 7.99991C14.8748 8.20624 14.814 8.40799 14.7 8.57995C14.586 8.75191 14.4238 8.88647 14.2338 8.96679L2.42719 13.9499C2.3118 13.9987 2.1878 14.0239 2.06251 14.024ZM2.06251 2.60054C2.00131 2.60083 1.94155 2.61909 1.89063 2.65304C1.84721 2.6812 1.81157 2.71982 1.78699 2.76537C1.76242 2.81092 1.7497 2.86191 1.75001 2.91366V6.20929C1.74997 6.28364 1.77644 6.35557 1.82466 6.41216C1.87289 6.46875 1.93971 6.50629 2.01313 6.51804L8.91376 7.61866C9.0046 7.63295 9.08736 7.67921 9.14712 7.74911C9.20689 7.81901 9.23973 7.90795 9.23973 7.99991C9.23973 8.09188 9.20689 8.18082 9.14712 8.25072C9.08736 8.32061 9.0046 8.36688 8.91376 8.38116L2.01313 9.4821C1.9396 9.49348 1.8726 9.53087 1.82431 9.58747C1.77602 9.64408 1.74966 9.71614 1.75001 9.79054V13.0862C1.74986 13.1378 1.76252 13.1887 1.78685 13.2342C1.81118 13.2798 1.84642 13.3186 1.88942 13.3472C1.93242 13.3758 1.98184 13.3933 2.03326 13.3982C2.08468 13.403 2.13649 13.395 2.18407 13.3749L13.9906 8.39085C14.0673 8.3583 14.1327 8.3039 14.1787 8.23444C14.2246 8.16498 14.2492 8.08352 14.2492 8.00023C14.2492 7.91693 14.2246 7.83548 14.1787 7.76601C14.1327 7.69655 14.0673 7.64215 13.9906 7.6096L2.18407 2.62491C2.14552 2.60895 2.10423 2.60067 2.06251 2.60054Z"
              fill="currentColor"/>
    </g>
</svg>

    )
}
