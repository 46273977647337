import React, {useState} from "react";
import {Task, TaskStatus, useAddTaskMutation} from "../../generated-types";
import {Button, Input, theme, Tooltip} from "antd";
import {EnterOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {valuesToTaskInput} from "./TaskCard";
import {AddTaskContext, addTaskContext} from "./TaskNew";
import {useReactiveVar} from "@apollo/client";
import RefetchesList from "../../queries/RefetchesList";
import { IconEnter } from "../Icon/IconEnter";
import styled, { createGlobalStyle } from "styled-components";
import { IconPlus } from "../Icon/IconPlus";
import { IconDocs } from "../Icon/IconDocs";

interface AddTaskInputProps {
    placeholder?: string,
    ctx: AddTaskContext,
    onCompleted?: (task: Task) => void,
    isBlocked?: boolean | undefined
    showPrefix?: boolean
    fastAddOnly?:boolean
}

const InputStyled=styled(Input)`
    background-color: ${({theme})=>theme.colors.ui.bgLight3};
    padding: 8px 16px;
    border-radius: 8px;
    gap: 4px;
    &>input::placeholder{
        color: ${({theme})=>theme.colors.font.primary};
        opacity: .5;
    }

    &::before{
        display: none;

    }
`

const SuffixButton=styled(Button)`

margin: 0 !important;
background-color: unset;

&>.ant-btn-icon{
    font-size: 24px;
    color: ${({theme})=>theme.colors.font.primary};
    stroke-width: 1.5;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

&:hover{
    background-color: unset !important;
}
    
`

const InputSuffix=styled.div`
    display: flex;
    gap: 8px;
`

const TooltipStyled=createGlobalStyle`
    .ant-tooltip-inner{
        background-color: ${({theme})=>theme.colors.ui.white} !important;
        color: ${({theme})=>theme.colors.font.primary} !important;
        font-size: 12px;
        border-radius: 8px !important;
    }

    .ant-tooltip-arrow::before{

        background-color: ${({theme})=>theme.colors.ui.white} !important;

    }
`
const AddTaskInput = ({ctx, placeholder, onCompleted,isBlocked, showPrefix, fastAddOnly=true}: AddTaskInputProps) => {
    const {t} = useTranslation();
    const [newTaskFocused, setNewTaskFocused] = useState(false);
    const {token}=theme.useToken();
    const [newTaskInProgress, setNewTaskInProgress] = useState(false);
    const [newTaskTitle, setNewTaskTitle] = useState("");


    const addTaskCtx = useReactiveVar(addTaskContext);

    const [addTask] = useAddTaskMutation({
        onCompleted: (data) => {

            setNewTaskTitle("")
            setNewTaskInProgress(false)
            if (onCompleted)
                onCompleted(data.addTask as Task)
        },
        refetchQueries: [RefetchesList.TasksByViewId, RefetchesList.Task],
    });

    const AddTask = (fastAdd: boolean) => {
        if (newTaskInProgress)
            return

        if (!newTaskTitle || !newTaskTitle.trim())
            return

        if (fastAdd) {
            const task = valuesToTaskInput({
                status: ctx.status,
                title: newTaskTitle
            }, undefined, undefined, undefined);
            task.status = TaskStatus.Todo
            task.projectId = ctx.projectId as string

            setNewTaskInProgress(true)
            addTask({
                variables: {
                    input: {
                        tasksViewColumnId: ctx.tasksViewColumnId,
                        tasksViewId: ctx.taskViewId,
                        parentId: ctx.parentId,
                        task
                    }
                }
            })
        } else {
            addTaskCtx.title = newTaskTitle;
            addTaskCtx.taskViewId = ctx.taskViewId;
            addTaskCtx.tasksViewColumnId = ctx.tasksViewColumnId;
            addTaskCtx.projectId = ctx.projectId;
            addTaskCtx.parentId = ctx.parentId;
            addTaskCtx.taskView=ctx.taskView;
            addTaskCtx.status=ctx.status;
            addTaskContext({...addTaskCtx});
            setNewTaskTitle("")
        }
    }

    return <InputStyled disabled={isBlocked} placeholder={placeholder ?? (t('task.addTask') as string + ' (Ctrl+\u21b2)')}
                  variant={newTaskFocused ? "outlined" : "borderless"}
                  onChange={(e) => {
                      if (newTaskInProgress) {
                          e.preventDefault()
                          e.stopPropagation()
                          return;
                      }
                      setNewTaskTitle(e.target.value as any)
                  }}
                  onKeyDown={(e) => {
                      if (e.key !== 'Enter')
                          return;
                      AddTask(!e.ctrlKey)
                  }}
                  size="middle"
                  prefix={showPrefix && <IconPlus fontSize={24} opacity={0.5} strokeWidth={1.5}/>}
                  suffix={<InputSuffix>
                                <TooltipStyled/>
                                {(newTaskFocused || newTaskTitle) && !fastAddOnly && <Tooltip arrow={false} trigger={'contextMenu'} title={t('task.addMoreInformation')} placement={'right'}>
                                    <SuffixButton type={"text"} size={"small"}
                                icon={<IconDocs/>}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    AddTask(false)
                                }}>
                                </SuffixButton>
                                </Tooltip>}

                                <SuffixButton type={"text"} size={"small"}
                                icon={<IconEnter/>}
                                onClick={(e) => {
                                    AddTask(!e.ctrlKey)
                                }}>
                                </SuffixButton>
                  </InputSuffix>}
                  onFocus={() => setNewTaskFocused(true)}
                  onBlur={() => setNewTaskFocused(false)}
                  value={newTaskTitle}
    />
}

export default AddTaskInput