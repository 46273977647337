import {Tag} from "../../generated-types";
import React from "react";
import {theme} from "antd";
import {useTranslation} from "react-i18next";
import {Icon} from "../Icon/Icon";
import TooltipButton from "../TooltipButton";
import {IconClose} from "../Icon/IconClose";
import styled, {css} from "styled-components";
import {GlobalToken} from "antd/es/theme/interface";


const TagDisplayItemStyled = styled.div<{
    $clickable: string,
    $color: string, $token: GlobalToken
}>`
    background-color: ${({$color}) => $color};
    border-radius: 8px;
    height: 24px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    float: left;
    /*margin-right: 4px;
    margin-bottom: 4px;*/

    ${({$clickable}) => $clickable === "true" && css`
        cursor: pointer;
    `}
    &:hover {
        opacity: 80%;
    }

    .ant-btn {
        width: 16px;
        height: 16px;
    }

    .ant-btn-icon {
        display: flex;
    }

    .tag-name {
        padding: 0;
        font-weight: 200;
        line-height: 1;
        font-size: 12px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        align-content: space-around;
    }
`

interface TagDisplayItemProps {
    tag: Tag,
    removable?: boolean
    onClick?: () => void
    onRemove?: () => void
}

const TagDisplayItem: React.FC<TagDisplayItemProps> = ({
                                                           tag,
                                                           removable = true,
                                                           onRemove,
                                                           onClick
                                                       }) => {
    const {token} = theme.useToken();
    const {t} = useTranslation()

    return <TagDisplayItemStyled $token={token}
                                 $color={tag.color}
                                 $clickable={(onClick != undefined).toString()}
                                 className={"tag-item"}
                                 onClick={e => {
                                     if (onClick) onClick();
                                     e.stopPropagation();
                                     e.preventDefault();
                                 }}>
        <span className={"tag-name"}>{tag.title}</span>
        {removable && <TooltipButton
					icon={<Icon size={"16"} color={token.colors.ui.bgDark} icon={<IconClose/>}/>}
					type={"text"}
					onClick={onRemove}
					tooltipProps={{title: t('tags.remove')}}>
				</TooltipButton>}
    </TagDisplayItemStyled>
}

export default TagDisplayItem;