import React, {type SVGProps} from "react"

export function IconDocsAdd(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=docs+, size=24">
        <g id="Frame 3">
            <path id="Vector" d="M6.84375 2H16.6406L21.2812 6.5V19" stroke="currentColor" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path id="Vector_2"
                  d="M2.71875 20.5V6.5C2.71875 5.67157 3.41131 5 4.26562 5H14.3219C14.4859 5 14.6433 5.06321 14.7593 5.17574L18.0063 8.32426C18.1223 8.43679 18.1875 8.5894 18.1875 8.74853V20.5C18.1875 21.3284 17.4949 22 16.6406 22H4.26562C3.41131 22 2.71875 21.3284 2.71875 20.5Z"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_3" d="M14.0625 5V8.4C14.0625 8.73137 14.3395 9 14.6813 9H18.1875" stroke="currentColor"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <g id="Group 28">
                <path id="Vector 4" d="M7.5 13.5L13.5 13.5" stroke="currentColor" strokeLinecap="round"/>
                <path id="Vector 5" d="M10.5 10.5L10.5 16.5" stroke="currentColor" strokeLinecap="round"/>
            </g>
        </g>
    </g>
</svg>

    )
}
