import React, {type SVGProps} from "react"

export function IconColorOrange(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=color-orange, size=24" clipPath="url(#clip0_31_5827)">
        <rect id="Rectangle 23" width="24" height="24" rx="4" fill="#F96D46"/>
    </g>
    <defs>
        <clipPath id="clip0_31_5827">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}
