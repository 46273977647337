import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Drawer, Form, Space} from 'antd';
import {useTranslation} from "react-i18next";
import {
    AddTaskInput,
    ExternalFile,
    Project,
    Task,
    TaskFile,
    TaskMember,
    TasksView,
    useAddTaskMutation
} from "../../generated-types";
import TaskCard, {valuesToTaskInput} from "./TaskCard";
import {makeVar, ReactiveVar, useReactiveVar} from "@apollo/client";

import {allProjects, updateProjectInCache} from "../../subscriptions/allProjects";
import RefetchesList from "../../queries/RefetchesList";
import ProjectBreadcrumb from "../ProjectBreadcrumb";
import { taskEditorState } from './TaskEditor';
import styled from 'styled-components';


interface TaskNewModalProps {
}

const SaveButton=styled(Button)`
    background-color: ${({theme})=>theme.colors.ui.accent};

    &:hover{
        background-color: ${({theme})=>theme.colors.ui.accentHover} !important;
    }
`

export const TaskNewModal: React.FC<TaskNewModalProps> = ({ }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const addTaskCtx = useReactiveVar(addTaskContext);
    const drawerBodyContentRef = useRef<HTMLDivElement>(null)
    
    useEffect(()=>{
            taskEditorState({historyOpen: false, ref: drawerBodyContentRef})
    }, [])

    const [task, setTask] = useState<Task>({
        title: "", description: "", members: [] as TaskMember[], files: [] as TaskFile[]
    } as Task);
    
    useEffect(() => {
        const title = addTaskCtx.title ?? "";

        setTask({
            title: title,
            description: "",
            members: [] as TaskMember[],
            files: [] as TaskFile[],
            startDate: addTaskCtx.startDate,
            endDate: addTaskCtx.endDate,
            startTime: addTaskCtx.startTime,
            endTime: addTaskCtx.endTime,
            importance: 0,
            status: addTaskCtx.status
        } as Task)

        form.setFieldValue("title", title)

        // Эта строка открывает окно добавления задачи при изменении реактивной переменной addTaskCtx
        if (addTaskCtx.taskViewId != undefined)
            setOpen(true)
    }, [addTaskCtx.title, addTaskCtx.startTime, addTaskCtx.startDate, addTaskCtx.endDate, addTaskCtx.endTime, addTaskCtx.projectId]);

    const allP = useReactiveVar(allProjects);
    const project = useMemo(() => {
        return allP.projects.find(p => p.id === addTaskCtx.projectId)
    }, [addTaskCtx.projectId])

    const [addTask] = useAddTaskMutation({
        onCompleted: () => {
            setOpen(false);
            form.resetFields();
            addTaskContext(emptyState());
        },
        refetchQueries: [RefetchesList.TasksByViewId],
        update: (cache) => {
            //Обновление статистики проекта для отображения актуальных данных в карточке
            let statistics = {...project?.statistics};

            if (statistics.totalTasks!==undefined) {
                statistics.totalTasks++;
            }

            const values = { ...project, statistics}
            updateProjectInCache(cache,values as Project)
        }
    });

    //Обработчик клика по кнопке сохранения новой задачи, при удачном submit выполнится мутация
    const save = () => {
        form.submit();
    };

    if (!project)
        return null

    const onClose = () => {
        addTaskContext(emptyState());//сбрасываем контекст, чтобы после закрытия без сохранения можно было создать задчу
        setOpen(false);
    };
    //Функция передаваемая в форму, отрабатывающая при успешном submit
    const saveTask = (values: any, members: TaskMember[], files: ExternalFile[], tags: string[]) => {
        const addData = valuesToTaskInput(values, members, files, tags);
        
        const columnId=addTaskCtx.taskView?.columns.find(col=>col.title===addData.status)?.id

        const input: AddTaskInput = {
            tasksViewId: addTaskCtx.taskViewId,
            tasksViewColumnId: columnId ?? addTaskCtx.tasksViewColumnId,
            task: addData
        }
        input.task.projectId = project.id;
        addTask({ variables: { input } })

    };

    return (
            <Drawer key="2"
                title={<>{t('task.createWindowTitle')}<ProjectBreadcrumb projectId={project.id} /></>}
                width={564}
                onClose={onClose}
                open={open}
                    styles={{body: {paddingBottom: 80}}}
                    push={false}
                footer={
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <SaveButton type="primary" block onClick={save} htmlType="submit">
                            {t('task.create')}
                        </SaveButton>
                    </Space>
                }
            >
                <div ref={drawerBodyContentRef}>
                {open && <TaskCard
                    newTask
                    key={task.id ?? task.title}
                    projectId={project.id}
                    task={task} save={saveTask}
                    project={project}
                    form={form}
                />}
                </div>
            </Drawer>
    );
};


export interface AddTaskContext {
    projectId?: string | undefined,
    taskViewId?: string | undefined,
    tasksViewColumnId?: string | undefined,
    title?: string | undefined
    status?: string | undefined
    parentId?: string | undefined
    startDate?: Date | undefined
    endDate?: Date | undefined
    startTime?: number | undefined
    endTime?: number | undefined
    taskId: string | undefined
    taskView?: TasksView
}

function emptyState(): AddTaskContext {
    return {
        projectId: undefined,
        taskViewId: undefined,
        tasksViewColumnId: undefined,
        title: undefined,
        status: undefined,
        startDate: undefined,
        endDate: undefined,
        startTime: undefined,
        endTime: undefined,
        taskId: undefined
    }
}

export const addTaskContext: ReactiveVar<AddTaskContext> = makeVar<AddTaskContext>(emptyState());
