import {User} from "../../generated-types";
import {UserOutlined} from "@ant-design/icons";
import {Avatar, Tooltip} from "antd";
import styled from "styled-components";

interface UserAvatarProps {
    user?: User | null,
    style?: any,
    size?: "12" | "16" | "24" | "32",
    className?: string,
    addTitle?: boolean,
    onClickAvatar?: () => void
}

const UserAvatarStyled = styled.span`
    display: flex;
    align-items: center;
    
    .user-title {
        padding-left: 3px;
        overflow: hidden;
        line-break: anywhere;
        white-space: nowrap;
    }
`

const StyledAvatar=styled(Avatar)`
    
        margin: 0 !important;
        width: 24px;
        height: 24px;
        border: none;
    

    &.responsibleAvatar{
        border: ${({theme})=>`${theme.colors.ui.accent} 1px solid`};
    }
`

const UserAvatar = (props: UserAvatarProps) => {
    const {user, onClickAvatar, size, addTitle, ...otherProps} = props
    let sssize = 1 * (size as any ?? "24");

    let url = user?.avatarUrl && user?.avatarUrl.indexOf("null") < 0 ? user.avatarUrl : undefined;

    const style = {...props.style};
    style.width = sssize + "px";
    style.height = sssize + "px";

    const iconSize = sssize - 8;
    const icon = <UserOutlined style={{fontSize: iconSize}}/>;

    let res = !user || !url
        ? <StyledAvatar key={url} {...otherProps} src={url} icon={icon} className={props.className}
                        onClick={onClickAvatar} style={style}
        />
        : <Tooltip key={user.id} title={user.username} placement="top" className={props.className}>
            <StyledAvatar src={url} icon={icon} className={props.className}
                          style={style} onClick={onClickAvatar}/>
        </Tooltip>;

    if (addTitle) {
        res =
            <UserAvatarStyled>{res}<span className={"user-title"}>{user?.fullName ?? user?.username ?? user?.id}</span></UserAvatarStyled>
    }

    return res;
}

export default UserAvatar