import React, {RefObject, useEffect, useMemo, useRef, useState} from "react"
import {DropdownedStyled} from "./Dropdowned.styled";
import {Drawer, theme} from "antd";
import {isMobile} from "react-device-detect";

export enum CenterJustify {
    start,
    center,
    end
}

export interface DropdownedProps {
    opened: boolean
    title?: string
    onClose: (opened: boolean) => void
    children: React.ReactNode
    anchor?: RefObject<HTMLElement> 
    backdrop?: boolean
    popupOnly?: boolean
    centerJustify?: CenterJustify //выравнивание центра попапа по центру экрана и краям/центру якоря
}

const useMousePosition = () => {
    const [
        mousePosition,
        setMousePosition
    ] = React.useState({ x: null, y: null });
    React.useEffect(() => {
        const updateMousePosition = (ev: any) => {
            console.log(ev)
            setMousePosition({ x: ev.screenX, y: ev.screenY });
        };
        window.addEventListener('mousemove', updateMousePosition);
        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
        };
    }, []);
    return mousePosition;
};

let mousePosition: { x: number, y: number } = { x: 0, y: 0 };

window.addEventListener('mousemove', e => mousePosition = { x: e.clientX, y: e.clientY });

/**
 * Отображение выпадающих список
 * @param date
 * @constructor
 */
type BaseRect={width: number, height: number, x: number, y: number}
const positionByRect=(rect: BaseRect, centerJustify: CenterJustify)=>{
    let x=0, y=0

    if (centerJustify === CenterJustify.start) {
        x = rect.x
        y = window.innerHeight / 2

    }

    if (centerJustify === CenterJustify.center) {
        x = rect.x + rect.width / 2
        y = window.innerHeight / 2
    }

    if (centerJustify === CenterJustify.end) {
        x = rect.x + rect.width
        y = window.innerHeight / 2
    }


    return { x, y }
}
const Dropdowned: React.FC<DropdownedProps> = ({ children, opened, onClose, title, anchor, backdrop, popupOnly, centerJustify }) => {
    const [open, setOpen] = useState(opened);
    const { token } = theme.useToken();
    const contentRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState<any>(0)
    const width='auto'

    const justifyed = !(centerJustify === undefined)

    const onCloseEvent = () => {
        setOpen(false);
        onClose(false)
    };
    
    const p = useMemo(() => {

        let x = (mousePosition.x ?? 0) + 14;
        let y = (mousePosition.y ?? 0) + 14;
        //Если указан элемент-якорь, то пытаемся позиционироваться по нему
        if (anchor) {
            const anchorElement = anchor.current
            
            if (anchorElement) {
                
                const rect = anchorElement.getBoundingClientRect()
                
                if (!justifyed) {
                    x = rect.x
                    y = rect.y + rect.height + 5

                    //Выход за границы экрана
                    const dropdownWidth = contentRef.current?.offsetWidth || 200;
                    const dropdownHeight = contentRef.current?.offsetHeight || 100;
                    const { innerWidth, innerHeight } = window;

                    //Нижняя граница, сделал чтоб сверху якоря появлялся
                    if (y + dropdownHeight >= innerHeight) {
                        y = rect.y-dropdownHeight-5;
                    }

                    //Правая граница-просто ствится по краю с отступом в 16 пикселей
                    if (x + dropdownWidth >= innerWidth) {
                        x = innerWidth - dropdownWidth - 16;
                    }

                } else
                    ({x, y} = positionByRect(rect, centerJustify!))     
            }
        }

        //Если указано позиционирование, но не указан якорь-равняемся по окну
        if(justifyed && !anchor){
            const windowRect={width: window.innerWidth, height: window.innerHeight, x: 0, y: 0};

            ({x,y}= positionByRect(windowRect, centerJustify!))
        }

        return { x, y }
    }, [open, height]);

    useEffect(() => {
        if (contentRef.current && containerRef.current) {
            if (open) {
                setHeight(contentRef.current.scrollHeight + 'px')

                const onTransitionEnd = () => {
                    setHeight('auto')
                    containerRef.current?.removeEventListener('transitionend', onTransitionEnd)
                }

                containerRef.current.addEventListener('transitionend', onTransitionEnd)
            } else {
                setHeight(0 + 'px')
            }
        }
    }, [open])

    useEffect(()=>{

    },[])

    if (isMobile && !popupOnly) {
        return <Drawer
            styles={{
                body: { padding: 0 },
                content: {
                    borderRadius: "16px 16px 0px 0px"
                }
            }}
            title={title}
            placement={"bottom"}
            closable={true}
            onClose={onCloseEvent}
            open={open}
            height={'auto'}

        >
            {children}
        </Drawer>
    }

    return <DropdownedStyled
        className={"dropdowned"}
        $position={p}
        $size={{width, height}}
        $token={token}
        onClick={e => {
            onCloseEvent()
            e.preventDefault()
            e.stopPropagation()
        }}
        ref={containerRef}
        $backdrop={backdrop}
        $justified={justifyed}
    >
        <div className={"content-container"}
            onClick={e => {
                e.preventDefault()
                e.stopPropagation()
            }}

            ref={contentRef}
        >
            {children}
        </div>
    </DropdownedStyled>
};

export default Dropdowned;