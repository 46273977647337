import React, {type SVGProps} from "react"

export function IconLabel(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=label, size=16">
        <g id="Frame">
            <path id="Vector"
                  d="M1.62599 6.37401L6.71716 1.28284C6.87337 1.12663 7.12663 1.12663 7.28284 1.28284L13.4483 7.4483C13.5429 7.54286 13.5839 7.67842 13.5577 7.80957L12.7091 12.0522C12.6775 12.2106 12.5537 12.3344 12.3954 12.366L8.15273 13.2145C8.02158 13.2407 7.886 13.1997 7.79144 13.1051L1.62599 6.9397C1.46978 6.78349 1.46977 6.53023 1.62599 6.37401Z"
                  stroke="currentColor"/>
        </g>
    </g>
</svg>

    )
}
