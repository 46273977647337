import React from 'react';
import {Button, Popconfirm} from 'antd';
import {User} from "../../generated-types";
import {useTranslation} from "react-i18next";
import UserAvatar from "../User/UserAvatar";
import styled from "styled-components";

export interface TopAlertsListItem {
    id: any
}

export interface TopAlertsListProps<T> {
    items: T[],
    onRender: (item: T) => React.ReactNode,
    getUser: (item: T) => User,
    onAccept: (item: T) => void,
    onDecline: (item: T) => void,
    acceptTitle: string,
    declinePopupTitle: string,
    declinePopupText: string,
    declineTitle: string,
}


const TopAlertsListStyled = styled.span`
    .item {
        padding: 10px;
            // height: ${p => p.theme.isMobile ? "80px" : "61px"};
        display: flex;
        flex-direction: ${p => p.theme.isMobile ? "column" : "row"};
        gap: 4px;
        align-items: center;
        justify-content: center;

        .ant-btn {
            font-size: 12px;
            height: 29px;
        }
    }

    .data-holder {
        display: flex;
        flex-direction: ${p => p.theme.isMobile ? "column" : "row"};
        align-items: flex-start;
        gap: 4px;
    }

    .btn-holder {
        flex-grow: 0;
        width: 350px;
    }

    .accept-btn {
        margin-left: 32px;
        width: 125px;
    }

    .decline-btn {
        margin-left: 4px;
        width: 125px;
    }

    .user-title {
        padding-left: 3px;
        overflow: hidden;
        line-break: anywhere;
        white-space: nowrap;
    }
`

const TopAlertsStyled = styled.div`
    & {
        background-color: ${p => p.theme.colors.ui.bg};
        color: ${p => p.theme.colors.font.white};
        display: flex;
        flex-direction: column;
        max-height: 200px;
        overflow-y: auto;
    }
`
export const TopAlerts: React.FC<{ children: React.ReactNode }> = ({children}) => {
    return <TopAlertsStyled>{children}</TopAlertsStyled>
}

function TopAlertsList<T extends TopAlertsListItem>(props: TopAlertsListProps<T>) {
    const {t} = useTranslation();

    return <TopAlertsListStyled>
        {props.items.map((i: T) => {
            return <div key={i.id} className={"item"}>
                <div className={"data-holder"}>
                    <UserAvatar user={props.getUser(i)} addTitle={true}/>
                    {props.onRender(i)}
                </div>
                <div className={"btn-holder"}>
                    <Button type={"primary"} className={"accept-btn"} size={"small"}
                            onClick={() => props.onAccept(i)}>{props.acceptTitle}</Button>
                    <Popconfirm
                        title={props.declinePopupTitle}
                        description={props.declinePopupText}
                        onConfirm={() => props.onAccept(i)}
                        onCancel={() => {
                        }}
                        okText={t('decline')}
                        cancelText={t('no')}
                    >
                        <Button ghost size={"small"} className={"decline-btn"}>{props.declineTitle}</Button>
                    </Popconfirm>
                </div>
            </div>
        })}</TopAlertsListStyled>
}

export default TopAlertsList;