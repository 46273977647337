import React, {CSSProperties, useRef, useState} from 'react';
import {Breadcrumb, Button, ButtonProps, Checkbox, ConfigProvider, Row, List, theme, Typography, Col, Space} from 'antd';
import {AttachedFolder, ExternalFile, ExternalFileType, useYandexDiskGetFolderItemsQuery} from "../../generated-types";
import {useTranslation} from 'react-i18next';
import Spinner from "../Spinner";
import {IconFiles} from '../Icon/IconFiles';
import Dropdowned, {CenterJustify} from '../Dropdowned';
import {isMobile, isMobileOnly, TabletView} from 'react-device-detect';
import {IconClose} from '../Icon/IconClose';
import {IconHome} from '../Icon/IconHome';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {IconFolder} from '../Icon/IconFolder';
import styled from 'styled-components';
import {IconArrowRight} from '../Icon/IconArrowRight';
import { IconFile } from '../Icon/IconFile';
import { format } from 'path';
import { t } from 'i18next';
import { IconArrowLeft } from '../Icon/IconArrowLeft';

const formatColumnWidth=100
const sizeColumnWidth=100

interface SelectFilesProps {
    title?: string
    /***
     * Базовых путей может быть несколько (например, из проекта и из тимы.
     */
    basePath?: string
    folders?: AttachedFolder[]
    buttonTitle?: string
    loading?: boolean
    onChange?: (items: ExternalFile[]) => void
    onFinish?: (items: ExternalFile[]) => void
    selectedItems?: ExternalFile[]
    onlyFolders?: boolean
    /***
     Показывать или чекбоксы выбора файлов
     ***/
    selectable?: boolean
    multipleSelect?: boolean
    btnProps?: ButtonProps
    tableView?: boolean
}

interface FilesListRowProps {
    item: ExternalFile,
    selected: boolean,
    onSelectionChange: (event: CheckboxChangeEvent) => void
    onClick: () => void,
    selectable?: boolean
    tableView?: boolean,
    multipleSelect?: boolean
}

const FilesListRowStyled=styled(List.Item)<{$tableView: boolean}>`
    cursor: pointer;
    padding: ${p=>p.theme.isMobile ? '8px 16px' : (p.$tableView? '8px 16px' : '8px 24px')} !important;
    justify-content: start;
    align-items: center;
    gap: 8px;
    box-shadow: none !important;
    background-color: unset;
    flex-wrap: nowrap;
    transition: all .3s ease-in-out;
    
    &:hover{
        background-color:${p=>p.$tableView? p.theme.colors.ui.bgLight3 : p.theme.colors.ui.bgLight} !important;
    }
`

const RowStyled=styled(Row)<{$tableView: boolean}>`
    width: 100%;
    pointer-events: none;  
    align-items: center;
    gap: ${({$tableView})=>$tableView? '8px' : '4px'};

    >.ant-col{
        
        display: flex;
        flex-direction: column;
    }
`

const SpanStyled=styled.span<{$tableView: boolean, $isMobile: boolean}>`
    font-size: ${({$tableView})=>$tableView? '16px' : '14px'};
    font-weight: 300;
    word-break: break-all;
    text-align: start;
    &.file-info{
        font-size: ${({$isMobile})=>$isMobile? '12px' : '16px'};
    }
`

const CheckboxStyled=styled(Checkbox)<{$multipleSelect?: boolean}>`
    
    
    .ant-checkbox-inner{
        border-radius: ${p=>p.$multipleSelect? '4px' : '100%'};
    }
    
    .ant-checkbox-checked{
        background-color: unset !important;

        >.ant-checkbox-inner{
            ${p=>!p.$multipleSelect && 'background-color: unset !important;'}
            border-color: ${p=>p.theme.colors.ui.succes} !important;

            &::after{
            ${p=>!p.$multipleSelect && 'display: none;'}
            }

            ${p=>!p.$multipleSelect && `&::before{
                content: '';
                position: absolute;
                background-color: ${p.theme.colors.ui.succes};
                width: 50%;
                height: 50%;
                border-radius: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%)
                
            }`
            }
            
            &:hover{
                ${p=>!p.$multipleSelect && 'background-color: unset;'}
                

            }
        }
        
    }
`
const FileInfoMobileContainer=styled.div`
    display: flex;
    opacity: 0.5;
    gap: 8px;
`
const formatFileSize=(size: number)=>{
    const units=['b', 'Kb', 'Mb', 'Gb' ]

    const i = Math.floor(Math.log(size) / Math.log(1024));
    const formatSize = (size / Math.pow(1024, i)).toFixed(2);
    return `${formatSize} ${t(`file.${units[i]}`)}`
}
const FilesListRow = ({ item, selected, onSelectionChange, onClick, selectable, tableView=false, multipleSelect  }: FilesListRowProps) => {

    const rowRef = useRef<HTMLDivElement>(null)
    const { token } = theme.useToken()
    const isFile=item.type == ExternalFileType.File

    const fileNameSplited=item.name.split('.')

    const fileFormat=fileNameSplited.at(-1)

    if(isFile && tableView)
        fileNameSplited.pop()

    const fileName=fileNameSplited.join('.')

    const fileInfo=<>
                        <Col flex={isMobileOnly? undefined : `${formatColumnWidth}px`}>
                            <SpanStyled className='file-info' $isMobile={isMobileOnly} $tableView={tableView}>{isFile && fileFormat}</SpanStyled>
                        </Col>
                        <Col flex={isMobileOnly? undefined : `${sizeColumnWidth}px`}>
                            <SpanStyled className='file-info' $isMobile={isMobileOnly} $tableView={tableView}>{item.size? formatFileSize(item.size) : ''}</SpanStyled>
                        </Col>
                    </>
    return (
        <FilesListRowStyled 
        $tableView={tableView}
            
            onClick={(event) => {

                if (event.target !== rowRef.current) { return }
                onClick()
            }
            }

        >
            {selectable && <CheckboxStyled
                $multipleSelect={multipleSelect}
                checked={selected}
                onChange={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    onSelectionChange(event)
                }}
            />}
            <Col flex='auto' ref={rowRef}>
            <RowStyled wrap={false} $tableView={tableView}>
                <Col>
                    {!isFile && <IconFolder pointerEvents={'none'} opacity={0.5} fontSize={tableView? 24 : 16} strokeWidth={1.5} />}
                    {isFile && tableView && <IconFile pointerEvents={'none'} opacity={0.5} fontSize={tableView? 24 : 16} strokeWidth={1.5}/>}
                </Col>
                    <Col flex={'auto'}>                    
                        <SpanStyled $isMobile={isMobileOnly} $tableView={tableView}>{fileName}</SpanStyled>
                        {tableView && isMobileOnly && <FileInfoMobileContainer>
                                    {fileInfo}
                            </FileInfoMobileContainer>}
                    </Col>
                    {tableView && !isMobileOnly && fileInfo}
                
            {item.type == ExternalFileType.Dir && !tableView && <IconArrowRight pointerEvents={'none'} fontSize={16} strokeWidth={2} />}
            </RowStyled>
            </Col>
        </FilesListRowStyled>
    )
}

const ListContainer=styled.div`
flex-grow: 1;
overflow: auto;
`

const FilesListContainer=styled.div`
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
`
const TableHeadContainer=styled(Row)`
    padding: 8px 16px;
    margin-bottom: 12px;
    border-bottom: solid 1px ${({theme})=>theme.colors.ui.bgLight3};
    font-weight: 300;
    gap: 8px;
`

const MobileBreadCrumb=styled.button`
    margin-bottom: 4px;
    padding: 4px 8px;
    border: none;
    background-color: ${({theme})=>theme.colors.ui.bgLight};
    width: fit-content;
    margin-left: 16px;
    border-radius: 8px;
    display: flex;
    gap: 4px;
    
`
export const FilesList: React.FC<SelectFilesProps> = (props) => {
    const { onChange, onlyFolders, multipleSelect } = props;

    const [selectedFolder, setSelectedFolder] = useState(props.folders != null && props.folders.length > 0 ? props.folders[0] : null); // может быть лучше бы сделать массивом?
    const [basePath, setBasePath] = useState(props.basePath || (selectedFolder != null ? selectedFolder.path : "/"));

    const [currentPath, setCurrentPath] = useState(basePath); // может быть лучше бы сделать массивом?
    const [selectedFiles, setSelectedFiles] = useState<ExternalFile[]>(props.selectedItems ?? []);
    const { token } = theme.useToken()
    const {t}=useTranslation()
    const { data, loading } = useYandexDiskGetFolderItemsQuery({
        variables: {
            path: currentPath,
            attachedFolderId: selectedFolder ? selectedFolder.id : null,
            onlyFolders: onlyFolders ?? false
        }
    })
    
    const items = data?.yandexDiskGetFolderItems as ExternalFile[];

    const breadCrumbButtonStyle: CSSProperties = {
        color: token.colors.font.primary,
        opacity: 0.5,
        padding: '4px 8px',
        height: 24,
        fontWeight: 400
    }

    const breadcrumbItems = [{
        title: <Button style={breadCrumbButtonStyle}
            type={"link"}
            styles={{ icon: { height: 16 } }}
            onClick={() => setCurrentPath(basePath)}
            icon={<IconHome fontSize={16} strokeWidth={1.5} />} />
    }]
        .concat(currentPath.replace(basePath, "").split("/").filter(v => v != "").map(i => (
            {
                title: <Button type={"link"}
                    style={breadCrumbButtonStyle}
                    onClick={() => {
                        const p = currentPath.substring(0, currentPath.indexOf(i) + i.length);
                        setCurrentPath(p)
                    }}>{i}</Button>
            })));

    return (<FilesListContainer>
        <Spinner loading={loading || props.loading} />
        {/*props.folders && props.folders.length > 0 && <Select
            defaultValue={basePath}
            style={{ width: "100%" }}
            onChange={(id) => {
                if (!props.folders) return;
                const folder = props.folders.find(v => v.id == id)
                if (!folder) return;
                setSelectedFolder(folder);
                setBasePath(folder.path);
                setCurrentPath(folder.path);
            }}
            options={props.folders.map(p => ({ value: p.id, label: p.path }))}
        />*/}
        <ConfigProvider 
        theme={{
            components: {
                Breadcrumb: {
                    separatorMargin: 0
                },
                Checkbox: {
                    colorPrimary: token.colors.ui.succes,
                    colorPrimaryHover: token.colors.ui.succes
                }
            },

        }}>
            {props.tableView && !isMobileOnly && <TableHeadContainer>
                <Col flex='auto'>{t('file.name')}</Col>
                <Col flex={`${formatColumnWidth}px`}>{t('file.format')}</Col>
                <Col flex={`${sizeColumnWidth}px`}>{t('file.size')}</Col>
            </TableHeadContainer>}
            
            {breadcrumbItems.length > 1 && !isMobileOnly && <Breadcrumb
                style={{
                    backgroundColor: token.colors.ui.bgLight,
                    borderRadius: 4,
                    width: 'fit-content',
                    height: 24,
                    alignItems: 'center',
                    marginLeft: (isMobileOnly || props.tableView) ? 16 : 24,
                    marginBottom: (isMobileOnly || props.tableView) ? 4 : 12

                }}
                separator={<span style={{ display: 'inline-flex', height: '100%', alignItems: 'center', opacity: 0.6 }}>•</span>} items={breadcrumbItems} />
            }
            {
                breadcrumbItems.length > 1 && isMobileOnly && <MobileBreadCrumb
                onClick={()=>{
                    const lastSlashIndex=currentPath.lastIndexOf('/')
                    if(lastSlashIndex===-1)
                        return

                    setCurrentPath(prev=>(prev.substring(0, lastSlashIndex) || '/'))
                }}
                >
                    <IconArrowLeft fontSize={16} opacity={0.5} strokeWidth={1.5}/>
                    <span>{currentPath.split('/').at(-1)}</span>
                </MobileBreadCrumb>
            }
            <ListContainer>
                <List
                    dataSource={items}
                    renderItem={(item) => <FilesListRow item={item}
                        tableView={props.tableView}
                        multipleSelect={multipleSelect}
                        selected={selectedFiles.some(f => f.path == item.path)}
                        selectable={props.selectable}
                        onClick={() => {
                            if (item.type == ExternalFileType.Dir)
                                setCurrentPath(item.path)
                        }}
                        onSelectionChange={(event) => {
                            event.stopPropagation();
                            event.preventDefault();

                            let res = [...selectedFiles];
                            if (event.target.checked && !selectedFiles.some(f => f.path == item.path)) {
                                if (multipleSelect)
                                    res.push(item)
                                else
                                    res = [item]
                            }

                            if (!event.target.checked)
                                res = selectedFiles.filter(f => f.path != item.path)

                            setSelectedFiles(res)
                            if (onChange)
                                onChange(res)

                        }} />}

                />
                </ListContainer>
        </ConfigProvider>
    </FilesListContainer>)
}

const ButtonStyled=styled(Button)<{color: string, bgColor: string, bgColorHovered: string}>`
        flex-grow: 1;
        border-radius: 8px;
        font-weight: 300;
        border: none;
        box-shadow: none;
        height: 44px;
        background-color: ${({bgColor})=>bgColor} !important;
        color: ${({color})=>color} !important;
        width: calc(50% - 4px);
        transition: all .3s ease-in-out;

        &:hover{
            background-color: ${({bgColorHovered})=>bgColorHovered} !important;
        }
`

const AttachFilesButton: React.FC<SelectFilesProps & {
    anchor?: React.RefObject<HTMLElement>
    centerJustify: CenterJustify
    fullView?: boolean
    icon?: React.JSX.Element
    className?: string
}> = (props) => {
    const { t } = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selected, setSelected] = useState(props.selectedItems ?? []);
    const {token}=theme.useToken()

    const foldersModalTitle = selected.length>0? t("file.changeAttachedFolder") :  t("file.attachFolderHeader")

    let { buttonTitle } = props

    const handleOk = () => {

        if (props.onFinish) props.onFinish(selected)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const modalTitle = props.onlyFolders? foldersModalTitle : t('file.attachFilesFromFolder', { folderName: props.folders ? props.folders[0].name : '' })

    return (<>
        {isModalOpen && <Dropdowned
            title={modalTitle}
            opened={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            anchor={props.anchor}
            backdrop centerJustify={props.centerJustify}>
            <div style={{ padding: isMobile ? '16px 0px' : '24px 0px',
                width: isMobile ? 'auto' : 717,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                maxHeight: '100%' }}>
                {!isMobile && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: isMobile ? '0px 16px 16px 16px' : '0px 24px 16px 24px' }}>
                    <span style={{ fontSize: 16, fontWeight: 600 }}>{modalTitle}</span>
                    <Button
                        style={{ width: 24, height: 24, border: 'none', boxShadow: 'none', color: 'inherit' }}
                        styles={{ icon: { display: 'flex', alignContent: 'center' } }}
                        icon={<IconClose style={{ fontSize: 24, strokeWidth: 1.5 }} />}
                        onClick={() => setIsModalOpen(false)}
                    />
                </div>}
                <div style={{
                    overflowY: 'auto',
                    height: 297
                }}>
                <FilesList {...props} onChange={(items) => {
                    setSelected(items)
                    if (props.onChange) props.onChange(items)
                }} />
                </div>
                <div style={{
                    padding: isMobile? '0px 16px' : '0px 24px',
                    display: 'flex',
                    gap: 8,
                    marginTop: 16
                }}>
                    <ButtonStyled
                    color={token.colors.font.white}
                    bgColor={token.colors.ui.accent}
                    bgColorHovered={token.colors.ui.accentHover}
                    onClick={()=>{handleOk()}}
                    >
                        {t('file.attach')}</ButtonStyled>
                    <ButtonStyled
                    color={token.colors.font.primary}
                    bgColor={token.colors.ui.bgLight}
                    bgColorHovered={token.colors.ui.bgLight3}
                    onClick={()=>handleCancel()}
                    >{t('cancel')}</ButtonStyled>
                </div>
            </div>
        </Dropdowned>}
        <Button {...props.btnProps} className={props.className} icon={props.icon? props.icon : <IconFiles fontSize={16} strokeWidth={1.5} />}
            onClick={() => {
                setIsModalOpen(true)
            }}
        ><span className='btn-title'>{buttonTitle}</span></Button></>)
}

export default AttachFilesButton