import {Space, theme} from "antd";
import Text from "antd/es/typography/Text";
import TooltipButton from "../TooltipButton";
import React, {ReactElement, useEffect, useState} from "react";
import styled from "styled-components";
import type {GlobalToken} from "antd/es/theme/interface";

export interface IconState<T> {
    state: T;
    icon: ReactElement;
    tooltipText: string;
}

interface IconStateSwitcherProps<T> {
    items: IconState<T>[]
    title?: ReactElement;
    defaultValue: T
    onChange: (k: T) => void
}


export const IconStateSwitcherStyled = styled(Space)<{ $token: GlobalToken }>`
    align-items: center;
    background-color: ${({$token}) => $token.colors.ui.bgLight3};
    border-radius: ${({$token}) => $token.baseBorderRadius}px;
    transition: all .3s ease-in-out;
    padding: 4px 4px;

    .ant-space-item {
        display: flex;
    }
`

const TooltipButtonStyled=styled(TooltipButton)`
    border: none;
    box-shadow: none;
    width: 24px !important;
    height: 24px;
    background-color: transparent;
    color: ${({theme}) => theme.colors.font.primary};
    font-size: 16px;

    &:hover{
        background-color: ${({theme}) => theme.colors.ui.bgLight} !important;
        color: ${({theme}) => theme.colors.font.primary} !important;
    }

    svg{
        opacity: .5;

    }

    &.ant-btn-primary{
        color: ${({theme}) => theme.colors.font.accent};
        
        svg{
            opacity: 1;
        }
    }

    &.ant-btn-primary:hover {
        color: ${({theme}) => theme.colors.font.accent} !important;
    }
`

const IconStateSwitcher = (props: IconStateSwitcherProps<any>) => {
    const {token} = theme.useToken()
    const [mode, setMode] = useState(props.defaultValue)

    useEffect(() => {
        setMode(props.defaultValue)
    }, [props.defaultValue]);

    return <IconStateSwitcherStyled size={4} $token={token}>
        {props.title && <Text style={{opacity: 0.5, marginRight: 4, width: 33, paddingLeft: 6}}>{props.title}</Text>}
        {props.items.map((item, i) => {
            return <TooltipButtonStyled key={item.state}
                icon={item.icon}
                type={mode == item.state ? "primary" : "default"}
                onClick={() => {
                    setMode(item.state)
                    props.onChange(item.state)
                }}
                tooltipProps={{title: item.tooltipText}}
            >
            </TooltipButtonStyled>
        })}
    </IconStateSwitcherStyled>;
}

export default IconStateSwitcher;