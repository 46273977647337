import React from 'react'
import {Form, FormInstance, Input} from 'antd';
import {Project} from "../../generated-types";
import {useTranslation} from "react-i18next";
import ProjectIconEditor from "./ProjectIconEditor";


interface ProjectSettingsProps {
    project: Project,
    save: any,
    form: FormInstance
}

function ProjectSettings(props: ProjectSettingsProps) {
    const {t} = useTranslation();

    return (
        <Form layout="vertical" requiredMark={false} form={props.form}
              initialValues={props.project as any}
              onFinish={props.save}
        >
            <Form.Item
                name="title"
                label={t('project.title')}
                rules={[{required: true, message: "" + t('project.titlePlaceholder')}]}
            >
                <Input placeholder={"" + t('project.titlePlaceholder')}/>
            </Form.Item>

            <Form.Item
                name="description"
                label={"" + t('project.description')}
                rules={[
                    {
                        required: false
                    },
                ]}
            >
                <Input.TextArea rows={7} placeholder={t('project.descriptionPlaceholder')}/>
            </Form.Item>


            <Form.Item name="icon" hidden={true}></Form.Item>
            <Form.Item name="iconColor" hidden={true}></Form.Item>

            <div>
                <div>{t('project.settingsPage.icon')}</div>
                <ProjectIconEditor project={props.project} onChange={(icon, iconColor) => {
                    props.form.setFieldValue("icon", icon);
                    props.form.setFieldValue("iconColor", iconColor);
                }}/>
            </div>
        </Form>
    )
}

export default ProjectSettings