import React, {type SVGProps} from "react"

export function IconClock(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=clock, size=24" clipPath="url(#clip0_516_61394)">
        <g id="_&#208;&#160;&#208;&#142;&#208;&#160;&#194;&#187;&#208;&#160;&#209;&#149;&#208;&#160;&#226;&#132;&#150;_1">
            <path id="Vector"
                  d="M12.0001 20.3078C16.5883 20.3078 20.3078 16.5883 20.3078 12.0001C20.3078 7.41186 16.5883 3.69238 12.0001 3.69238C7.41186 3.69238 3.69238 7.41186 3.69238 12.0001C3.69238 16.5883 7.41186 20.3078 12.0001 20.3078Z"
                  stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
            <path id="Vector_2" d="M12 6.46167V11.5017C12 11.8063 12.1569 12.1017 12.4154 12.2678L14.7692 13.8371"
                  stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_516_61394">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}
