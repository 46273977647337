import React, {type SVGProps} from "react"

export function IconDetails(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=details, size=16">
        <g id="Frame">
            <path id="Vector" d="M10.3335 9.33337H3.3335" stroke="currentColor" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path id="Vector_2" d="M12.3335 6.66663H3.3335" stroke="currentColor" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path id="Vector_3" d="M10.3335 4H3.3335" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_4" d="M12.3335 12H3.3335" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </g>
</svg>

    )
}
