import React, {type SVGProps} from "react"

export function IconHistory(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=history, size=16" height="1em">
        <g id="Group 47">
            <path id="Vector 17"
                  d="M2.5 13.5V2.5C2.5 1.94772 2.94771 1.5 3.5 1.5H12.5C13.0523 1.5 13.5 1.94772 13.5 2.5V13.5C13.5 14.0523 13.0523 14.5 12.5 14.5H3.5C2.94772 14.5 2.5 14.0523 2.5 13.5Z"
                  stroke="currentColor"/>
            <path id="Vector 13" d="M7.5 12H11" stroke="currentColor" strokeLinecap="round"/>
            <path id="Vector 15" d="M5 1.5L5 14.5" stroke="currentColor" strokeLinecap="round"/>
            <path id="Vector 14" d="M7.5 10H11" stroke="currentColor" strokeLinecap="round"/>
        </g>
    </g>
</svg>

    )
}
