import {Maybe} from "../../generated-types";
import React, {useMemo} from "react";
import {AllIconsDict} from "../Icon/AllIcons";
import {allSizes, Icon} from "../Icon/Icon";
import {ProjectDefaultColor} from "./ProjectIconEditor";
import styled from "styled-components";

interface ProjectAvatarProps {
    // p: Project,
    size?: allSizes,
    icon?: Maybe<string>,
    iconColor?: Maybe<string>,
}

const StyledIcon = styled(Icon)<{ $color: string }>`
    background-color: ${p => p.$color};
    padding: 4px;
    border-radius: 3px;
    width: 24px;
    height: 24px;
    align-self: center;
`

const Hldr = styled.div<{ $color: string, $size: string }>`
    background-color: ${p => p.$color};
    max-height: ${p => p.$size}px;
    max-width: ${p => p.$size}px;
    min-width: ${p => p.$size}px;
    min-height: ${p => p.$size}px;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
`

const ProjectAvatar = (props: ProjectAvatarProps) => {
    const {size = "24"} = props

    const [color, icon] = useMemo(() => {
        const iconNode = props.icon && Object.keys(AllIconsDict).indexOf(props.icon) > -1 ? AllIconsDict[props.icon] : AllIconsDict.Projects;
        const clr = props.iconColor ?? ProjectDefaultColor;

        let iconSize: allSizes = "16"
        switch (size) {
            case "16":
                iconSize = "12";
                break;
            case "24":
                iconSize = "16";
                break;
            case "32":
                iconSize = "24";
                break;
            case "48":
                iconSize = "32";
                break;
            default:
                iconSize = "16";
                break;
        }

        return [clr, <StyledIcon icon={iconNode} $color={clr} size={iconSize}/>]
    }, [props.icon, props.iconColor])

    return <Hldr $color={color} $size={size}>
        {icon}
    </Hldr>;
}

export default ProjectAvatar