import React, {type SVGProps} from "react"

export function IconEdit(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=edit, size=24">
        <g id="_&#208;&#160;&#208;&#142;&#208;&#160;&#194;&#187;&#208;&#160;&#209;&#149;&#208;&#160;&#226;&#132;&#150;_1">
            <path id="Vector"
                  d="M7.76711 20.0737L19.8092 8.01333C20.3383 7.48421 20.3383 6.62667 19.8092 6.09754L17.8934 4.18175C17.3643 3.65263 16.5068 3.65263 15.9776 4.18175L3.9264 16.2604C3.83518 16.3516 3.78044 16.4793 3.78956 16.607L3.87167 19.7453C3.87167 20.0007 4.09061 20.2105 4.34605 20.2105H7.42956C7.55728 20.2105 7.67588 20.1558 7.76711 20.0737Z"
                  stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
            <path id="Vector_2" d="M14.2441 5.93335L18.0666 9.76493" stroke="currentColor" strokeMiterlimit="10"
                  strokeLinecap="round"/>
        </g>
    </g>
</svg>

    )
}
