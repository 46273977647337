import React, {type SVGProps} from "react"

export function IconArrowUp(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=arrow-up, size=16" clipPath="url(#clip0_31_5790)">
        <g id="Frame 3">
            <path id="Vector" d="M12 10L8 6L4 10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_31_5790">
            <rect width="16" height="16" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}
