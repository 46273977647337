import {Maybe, UsersUnion} from "../../generated-types";
import {TeamOutlined} from "@ant-design/icons";
import {Avatar, Tooltip} from "antd";
import React from "react";

interface UserAvatarProps {
    avatarUrl?: string | Maybe<string>,
    uu?: UsersUnion | null,
    style?: any,
    className?: string,
    size?: "12" | "16" | "24" | "32",
}

const UsersUnionsAvatar = (props: UserAvatarProps) => {
    const {uu, avatarUrl, size, ...otherProps} = props
    let sssize = 1 * (size as any ?? "24");

    const style = {...props.style};
    style.width = sssize + "px";
    style.height = sssize + "px";

    const iconSize = sssize - 8;
    const icon = <TeamOutlined style={{fontSize: iconSize}}/>;

    if (uu)
        return (<Tooltip key={uu.id} title={uu.title} placement="right" className={props.className}>
            <Avatar src={uu.avatarUrl} icon={icon} className={props.className} style={style}/>
        </Tooltip>)

    return (<Avatar {...otherProps} src={avatarUrl} icon={icon} className={props.className}/>)
}

export default UsersUnionsAvatar