import React, {type SVGProps} from "react"

export function IconSorting(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=sorting, size=16">
        <path id="Line 42"
              d="M8.5 2C8.5 1.72386 8.27614 1.5 8 1.5C7.72386 1.5 7.5 1.72386 7.5 2L8.5 2ZM7.5 2L7.5 14L8.5 14L8.5 2L7.5 2Z"
              fill="currentColor"/>
        <path id="Vector" d="M4 10L8 14L12 10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
</svg>

    )
}
