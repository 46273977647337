import React, {useRef, useState} from 'react';
import {Card, Empty, List, Table, Tabs, theme} from 'antd';
import ConnectYandexDisk from "./ConnectYandexDisk";
import {AttachedFolder, ExternalFile, Project, useAttachFolderMutation, useFilesInProjectQuery} from "../../generated-types";
import {useTranslation} from 'react-i18next';
import RefetchList from "../../queries/RefetchesList";
import AttachFilesButton, {FilesList} from "./AttachFilesButton";
import {editTaskContext} from "../Tasks/TaskEditor";
import DownloadFileButton from "./DownloadFileButton";
import {ExternalFileWithId} from "../Tasks/TaskCard";
import {CenterJustify} from "../Dropdowned";
import styled, { css } from 'styled-components';
import { IconFolder } from '../Icon/IconFolder';
import { ColumnsType } from 'antd/es/table';
import { isMobileOnly } from 'react-device-detect';
import { IconEdit } from '../Icon/IconEdit';
import { IconFiles } from '../Icon/IconFiles';
import { IconDocs } from '../Icon/IconDocs';

interface ProjectFilesListProps {
    project: Project,
    isAdmin: boolean
}

const paddingLeftRight=css`
    padding-left: ${p=>p.theme.isMobile? '16px' : '40px'};
    padding-right: ${p=>p.theme.isMobile? '16px' : '40px'};
`

const ProjectFilesContainer=styled.div<{$isMobile: boolean}>`
    
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    
`

const ProjectFilesHeader=styled.div`
display: flex;
gap: 8px;
padding: 24px 0 16px 0;
align-items: center;
${paddingLeftRight}
>.folder-name{
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
}
`

const ProjectFilesTableScrollContainer=styled.div`
    flex-grow: 1;
    overflow: auto
`

const ProjectFilesTableScrollContent=styled.div`
${paddingLeftRight}
box-sizing: content-box;
min-width: ${p=>p.theme.isMobile? '' : '990px'};
max-height: calc(100% - 70px);
${p=>!p.theme.isMobile && 'height: calc(100% - 70px);'}

`
const AttachFilesButtonStyled=styled(AttachFilesButton)`
    
        background-color: ${p=>p.theme.isMobile? p.theme.colors.ui.bgLight2 : p.theme.colors.ui.bgLight3};
        color: ${p=>p.theme.colors.font.accent} !important;
        padding: ${p=>p.theme.isMobile? '8px' : '12px 24px'};
        border: none !important;
        box-sizing: content-box;
        height: auto;
        box-shadow: none;
        align-items: center;

        &:hover{
            background-color: ${p=>p.theme.colors.ui.bgLight2} !important;
            
        }
        >.ant-btn-icon{
            stroke-width: 1.5;
            font-size: ${p=>p.theme.isMobile? '16px' : '24px'};
            display: flex;
            align-items: center;
        }
        >.btn-title{
            ${p=>p.theme.isMobile && 'display: none;'}
        }
`
const FilesTableContainer=styled.div<{$isMobile: boolean}>`
    background-color: ${({theme})=>theme.colors.ui.bgLight2};
    border-radius: 8px;
    height: 100%;
    overflow: auto;
    padding-top: ${({$isMobile})=>$isMobile? '16px' : '0'};
`

const EmptyStyled=styled(Empty)`
    max-width: 271px;

    &>.ant-empty-image{
        height: auto;
    }

    &>.ant-empty-footer{

        
        >.ant-btn{
            background-color: ${p=>p.theme.colors.ui.bgLight3};
            span{
                display: block;
            }
        }
    }
`
const ProjectFilesList: React.FC<ProjectFilesListProps> = ({project, isAdmin}) => {
    const {t} = useTranslation();
    const [inProgress, setInProgress] = useState(false);
    const attachFilesRef = useRef<HTMLDivElement>(null)

    const [attachFolder] = useAttachFolderMutation({
        onCompleted: () => {
            setInProgress(false)
            // m.destroy();
        },
        refetchQueries: [RefetchList.ProjectCard, RefetchList.YandexDiskGetFolderItems]
    })

    /*const filesInTasks = useFilesInProjectQuery({
        variables: {projectId: project.id as string}
    })
    const filesInProject = filesInTasks.data?.filesInProject;*/

    const onFolderChanged = (items: ExternalFile[])=>{
        setInProgress(true)
        // TODO: не очень-то получилось
        attachFolder({
            variables: {
                path: items[0].path,
                projectId: project.id,
            }
        })
    };

    const attachBtn = <AttachFilesButtonStyled
        icon={project.attachedFolders.length > 0? <IconEdit /> : <IconFiles/>}
        centerJustify={CenterJustify.center}
        basePath={"/"} loading={inProgress} onFinish={onFolderChanged} selectable={true} onlyFolders={true}
        buttonTitle={t(project.attachedFolders.length===0? 'file.projectAttachFolderBtn' : 'file.changeAttachedFolder') as string} title={t('file.projectAttachFolder') as string}
        selectedItems={project.attachedFolders.map(({name, path}) => ({name, path} as unknown as ExternalFile))}
        /*btnProps={{
            style: {backgroundColor: token}
        }}*/
    />;
    
    if (project.attachedFolders.length == 0) {
        return <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <ConnectYandexDisk allowAttachToken={isAdmin}>
                <EmptyStyled image={<IconDocs fontSize={48} opacity={0.5}/>} description={t(isAdmin? 'file.noConnectedStorage' : 'file.adminHasToAttachFolder')}>
                    {isAdmin && attachBtn}
                </EmptyStyled>
            </ConnectYandexDisk>
        </div>
    }

    /*const tabs = project.attachedFolders.map((f, i) => {
        const id = String(i + 1);
        return {
            label: f.path,
            key: id,
            children: <FilesList folders={[f]} selectable={false}/>,
            closable: true,
        };
    });
    tabs.unshift({
        label: t("file.filesInTasks"),
        key: "",
        closable: false,
        children:
            <List
                grid={{ gutter: 16, column: 3 }}
                dataSource={filesInProject}
                renderItem={(item) => (
                    <List.Item>
                        <Card
                            title={<>{item.path}<DownloadFileButton file={item as unknown as ExternalFileWithId} /></>}
                        >{
                            item.tasks.map(t=> {
                                return <List.Item.Meta
                                    description={<a onClick={(e)=>{
                                        editTaskContext({taskId: t.id});
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }} >{t.title}</a>}
                                    // description={<Breadcrumb items={description} />}
                                />
                            })
                        }</Card>
                    </List.Item>
                )}
            />
    })*/
        
        return (
        <ConnectYandexDisk allowAttachToken={isAdmin}>
            <ProjectFilesContainer $isMobile={isMobileOnly}>
                <ProjectFilesHeader>
                    <IconFolder fontSize={24} opacity={0.5} />
                    <span className='folder-name'>{project.attachedFolders[0]?.name}</span>
                    {isAdmin && attachBtn}
                </ProjectFilesHeader>
                {/*<Tabs tabBarExtraContent={isAdmin && attachBtn} items={tabs} style={{marginBottom: 0}}/>*/}
                <ProjectFilesTableScrollContainer>
                    <ProjectFilesTableScrollContent>
                        <FilesTableContainer $isMobile={isMobileOnly}>
                            {<FilesList folders={project.attachedFolders} selectable={false} tableView/>}
                        </FilesTableContainer>
                    </ProjectFilesTableScrollContent>
                </ProjectFilesTableScrollContainer>
            </ProjectFilesContainer>
        </ConnectYandexDisk>
    )
};

export default ProjectFilesList;