import {theme} from 'antd';
import React from 'react';
import styled from "styled-components";

interface PointDividerProps {
    value?: any;
    hideZero?: boolean;
    valueOpacity?: number;
}


const Container = styled.span<{ color: string }>`
    color: $color;
`

const Point = styled.span`
    padding: 4px;
    font-weight: 900;
    opacity: 0.5
`
const Value = styled.span<{$valueOpacity: number}>`
    opacity: ${({$valueOpacity})=>$valueOpacity};
`

const PointDivider = (props: PointDividerProps) => {
    const {token: {colors}} = theme.useToken();

    if (!props.value && (props?.hideZero !== false))
        return <></>

    return (
        <Container color={colors.ui.white}><Point>·</Point><Value $valueOpacity={props.valueOpacity?? 0.5}>{props.value}</Value></Container>
    )
};

export default PointDivider;