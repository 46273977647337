import React, {useMemo} from "react";
import styled from "styled-components";

interface SettingsPanelProps {
    children: React.ReactNode
    height?: number
    title?: string
}

const StyledDiv = styled.div<{}>`
    background-color: ${p => p.theme.colors.ui.white};
    padding: 24px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 12px;

    .title {
        font-size: 16px;
        font-weight: 400;
    }
`

export const SettingsPanel: React.FC<SettingsPanelProps> = (props) => {
    const style = useMemo(() => {
        return {minHeight: props.height};
    }, [props.height])

    return (
        <StyledDiv style={style}>
            {props.title && <div className={"title"}>{props.title}</div>}
            {props.children}
        </StyledDiv>
    )
};

export default SettingsPanel;