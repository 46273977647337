import React, {type SVGProps} from "react"

export function IconStop(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=stop, size=24">
        <g id="Frame">
            <g id="Group 9">
                <line id="Line 21" x1="20.5" y1="3" x2="20.5" y2="21.4917" stroke="currentColor"/>
                <g id="Group 7">
                    <path id="Vector" d="M12.7568 17.5432L18.0542 12.2458L12.7568 6.94842" stroke="currentColor"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <line id="Line 22" x1="17.8369" y1="12.1199" x2="3.00006" y2="12.1199" stroke="currentColor"/>
                </g>
            </g>
        </g>
    </g>
</svg>

    )
}
