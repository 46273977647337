import React, {ReactElement, type SVGProps} from "react"

export type allSizes = "12" | "16" | "24" | "32" | "48";

export function Icon(props: SVGProps<SVGSVGElement> & { size?: allSizes, icon: ReactElement }) {
    let {icon, size, color} = props
    if (size) {
        icon = React.cloneElement(
            icon, {style: {...icon.props.style, width: size + "px", height: size + "px", color: color}}
        );
    }
    return <>{icon}</>
}