import React, {useMemo, useRef, useState} from 'react';
import {Button} from "antd";
import {TasksView} from "../../../generated-types";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import Dropdowned from "../../Dropdowned";
import {CustomDropdownItem} from "../../AntCastomComponents/SelectMenuDropdown";
import {IconSort} from "../../Icon/IconSort";
import SelectMenuDropdown2 from "../../AntCastomComponents/SelectMenuDropdown2";
import {isMobile} from "react-device-detect";

export enum SORTFIELD {
    title = "title",
    responsible = "responsible",
    column = "column",
    deadline = "deadline",
    importance = "importance",
    position = "position",
    createDate = "createDate"
}

export const DisplaySortFields = [SORTFIELD.column, SORTFIELD.title, SORTFIELD.responsible, SORTFIELD.importance, SORTFIELD.deadline]

export enum DIRECTION {
    ASC = 1,
    DESC = -1
}

interface TaskViewSortDropdownProps {
    sort: SORTFIELD;
    direction: DIRECTION;
    taskView: TasksView;
    onChange: (value: SORTFIELD, sortDirection: DIRECTION) => void
}


const DropdownItemStyled = styled.div<{}>`
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    height: 21px;
    padding: 4px 16px;
    position: relative;
    box-sizing: border-box;
    font-size: 12px;
    cursor: pointer;


    &.selected {
        background-color: ${({theme}) => theme.colors.ui.bgLight};
        border-left: 2px solid ${({theme}) => theme.colors.ui.accent};
        padding-left: 14px;
    }

    &:hover {
        background-color: ${({theme}) => theme.colors.ui.bgLight};
    }
`

const Btn = styled(Button)<{}>`
    width: 165px;
    background-color: ${({theme}) => theme.colors.ui.bgLight3};
    color: ${({theme}) => theme.colors.font.primary};
    border: 0;
    box-shadow: unset;
    //flex-direction: row-reverse;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    height: auto;
    padding-inline: 9px;

    &:hover {
        background-color: ${({theme}) => theme.colors.ui.bgLight};
    }
`

const TaskViewSortDropdown = ({sort, direction, onChange}: TaskViewSortDropdownProps) => {
    const btnRef = useRef<HTMLButtonElement>(null)
    const [opened, setOpened] = useState(false)
    const {t} = useTranslation();

    const items = useMemo(() => {
        return DisplaySortFields.map((s: SORTFIELD) => {
            return [s + DIRECTION[DIRECTION.ASC], s + DIRECTION[DIRECTION.DESC]]
        }).flat(1).map((key: string) => {
            return {
                label: t("task.sortFields." + key),
                selected: key == sort + DIRECTION[direction],
                key: key,
            } as CustomDropdownItem
        });
    }, [sort, direction])

    return <>
        <Btn
            iconPosition={"start"}
            styles={{icon: {display: "flex", alignItems: 'center'}}}
            icon={<IconSort strokeWidth={'1.5px'}/>}
            onClick={() => setOpened(true)}
            ref={btnRef}
        >
            {!isMobile && t('sort')}
        </Btn>
        {opened && <Dropdowned opened={opened}
				                       onClose={() => setOpened(false)}
				                       anchor={btnRef}
				>
					<SelectMenuDropdown2
						items={items}
						renderItem={i => {
                return <DropdownItemStyled
                    className={i.selected ? "selected" : undefined}>{i.label}</DropdownItemStyled>
            }}
						onSelect={k => {
                let key = k as string;
                let sort = key.replace('ASC', '').replace('DESC', '');
                let direction = key.replace(sort, '');
                setOpened(false)
                onChange(SORTFIELD[sort as SORTFIELD], direction == 'ASC' ? DIRECTION.ASC : DIRECTION.DESC);
                return true;
            }}
					/>
				</Dropdowned>}
    </>
}

export default TaskViewSortDropdown;
