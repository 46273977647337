import React, {type SVGProps} from "react"

export function IconUserProject(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=user project, size=24">
        <g id="Group 8">
            <path id="Vector" d="M20.2622 14.7144L23.1193 11.8572L20.2622 9.00007" stroke="currentColor"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Line 22" d="M23.0024 11.8767L15.0002 11.8767" stroke="currentColor" strokeLinecap="round"/>
        </g>
        <g id="Frame 415">
            <path id="Vector_2"
                  d="M6.83763 12.3112C8.81849 12.3112 10.4243 10.6745 10.4243 8.65558C10.4243 6.63666 8.81849 5 6.83763 5C4.85678 5 3.25098 6.63666 3.25098 8.65558C3.25098 10.6745 4.85678 12.3112 6.83763 12.3112Z"
                  stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
            <g id="Group">
                <path id="Vector_3"
                      d="M12.5056 16.3402C11.5098 17.9113 9.3383 19.0002 6.90477 19.0002C4.32897 19.0002 2.12755 17.7791 1.1167 16.2002"
                      stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
                <path id="Vector_4"
                      d="M10.1843 13.6489C9.21091 14.1078 8.07277 14.5122 6.85974 14.5122C5.64672 14.5122 4.56848 14.1467 3.5127 13.6411"
                      stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
                <path id="Vector_5"
                      d="M1.13922 16.2391C0.86217 15.7724 0.996951 14.9479 1.55854 14.279C2.17254 13.5402 2.94378 13.4002 3.52034 13.6413"
                      stroke="currentColor" strokeMiterlimit="10"/>
                <path id="Vector_6"
                      d="M12.4457 16.4412C12.8725 15.8112 12.6704 15.0412 12.0863 14.3412C11.5023 13.6412 10.7909 13.4312 10.1694 13.6645"
                      stroke="currentColor" strokeMiterlimit="10"/>
            </g>
        </g>
    </g>
</svg>

    )
}
