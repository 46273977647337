import React from "react";
import {theme} from "antd";
import styled, {css} from "styled-components";


export const Container = styled.div`
    bottom: 24px;
    font-size: 120%;
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
`

export const Letter = styled.span<{ $first?: string }>`
    border-right: 1px solid rgb(255 255 255 / 3%);
    width: 11px;
    text-align: center;
    color: white;
    animation: flicker 10s linear infinite;

    ${({$first}) =>
            $first &&
            css`
                margin-left: 4px;
            `}

    &:hover {
        box-shadow: inset rgba(255, 255, 255, 0.48) 0 0 10px 3px;
    }
`

export const FooterLogo: React.FC<{}> = ({}) => {
    const bl = <div style={{display: "flex"}}>
        <Letter $first={"true"}>b</Letter>
        <Letter>a</Letter>
        <Letter>c</Letter>
        <Letter>k</Letter>
        <Letter>l</Letter>
        <Letter>i</Letter>
        <Letter>n</Letter>
        <Letter>e</Letter>
    </div>;

    const {
        token: {colors},
    } = theme.useToken();

    return <Container>
        <div className="logo">backline</div>
    </Container>
}