import React from 'react'
import styled from 'styled-components'


const TaskNumberContainer = styled.div`
    border-radius: 4px;
    padding: 6px;
    background-color: ${({ theme }) => theme.colors.ui.bgLight};
    font-size: 12px;
    line-height: 1;
    color: ${({ theme }) => theme.colors.font.primarySemitransparent};
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    width: fit-content
`
function TaskNumber({number}: {number: number}) {
  return (
    <TaskNumberContainer>#{number}</TaskNumberContainer>
  )
}

export default TaskNumber