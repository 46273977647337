import styled, {css} from "styled-components";
import Sider from "antd/es/layout/Sider";
import type {GlobalToken} from "antd/es/theme/interface";

export const LeftSider = styled(Sider)<{ $token: GlobalToken }>`
    max-height: 100vh;
    overflow-x: visible;

    &.ant-layout, &.ant-layout-sider {
        background: ${(p) => p.$token.colors.ui.bg};
    }

    & > .ant-layout-sider-zero-width-trigger {
        background: ${(p) => p.$token.colors.ui.bg};
        color: ${(p) => p.$token.colors.font.white};
    }

    &.ant-menu-root, &.ant-menu-inline {
        border-inline-end: none;
    }

    .ant-menu-item, .ant-menu-inline {
        margin-block: 0;
        margin-inline: 0;
    }
`

export const CustomMenu = styled.div<{ $collapsed?: string, $mobile?: string, $token: GlobalToken }>`
    height: calc(100vh - 200px);
    //height: 100%;
    background-color: ${(p) => p.$token.colors.ui.bg};
    overflow-y: scroll;
    overflow-x: hidden;
    //overflow-y: hidden;
    //&:hover {
    //    overflow-y: auto;
    //}
    display: flex;
    flex-direction: column;
    ${({
           $collapsed,
           $mobile
       }) => $mobile !== "true" && $collapsed !== "true" && css` padding-left: calc(-1 * (300px - 100%));`}; // Чтобы скроллинг не сдвигал контент меню
    ${({$mobile}) => $mobile === "true" && css` padding-left: 11px;`};

    // Чтобы скроллинг был слева
    direction: rtl;

    .title-container {
        direction: ltr;
        text-align: left;
        //padding: 0px 14px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        ${({$collapsed}) => $collapsed === "true" ? css` align-items: center;` : null}
    }


    .top-title-container {
        direction: ltr;
        text-align: left;
        //padding: 0px 14px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .title {
        color: ${(p) => p.$token.colors.font.white} !important;
        display: flex;
    }

    .top-title-container > .title {
        ${({$collapsed}) => $collapsed === "true" && css`
            display: flex;
            justify-content: center;
            padding: 14px 0;
            margin-left: -11px;
        `};
        ${({$collapsed}) => $collapsed !== "true" && css`
            padding: 12px 4px;
        `};
    }

    .title:hover {
        background-color: ${(p) => p.$token.colors.ui.accent05};
    }

    .title.selected {
        background-color: ${(p) => p.$token.colors.ui.accent05};
    }

    .project-title.selected {
        background-color: ${(p) => p.$token.colors.ui.accent05};
    }

    .divider {
        border-top: 1px solid ${(p) => p.$token.colors.ui.stroke};
        ${({$collapsed}) => $collapsed !== "true" && css`
            width: calc(100% - 28px);
            margin: 0 14px;
        `};

    }

    .items-holder {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-top: 5px;
    }

    .title-container > div {
        display: flex;
        gap: 8px;
        ${({$collapsed}) => $collapsed === "true" && css`
            width: 100%;
            justify-content: center;
        `};
    }

    .top-title-container > .items-holder > .title-container > .title {
        ${({$collapsed}) => $collapsed !== "true" && css` padding-left: 26px;`};
    }

    .top-title-container > .items-holder > .title-container > .items-holder > .project-title {
        ${({$collapsed}) => $collapsed !== "true" && css` padding-left: 70px;`};
    }

    .top-title-container > .items-holder > .project-title {
        ${({$collapsed}) => $collapsed !== "true" && css` padding-left: 35px;`};
    }

    .top-title-container > .items-holder {
        ${({$collapsed}) => $collapsed === "true" && css`
            margin-left: -11px;`};
    }

    /* ================= Кастомный скроллбар ========================== */

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        //background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${(p) => p.$token.colors.ui.bg};
        border-radius: 6px;
    }

    /* для FireFox */
    scrollbar-color: ${(p) => p.$token.colors.ui.stroke} ${(p) => p.$token.colors.ui.bg}; /* Цвет скролла, цвет ползунка */
    scrollbar-width: thin; /* Толщина скролла */
`

export const TitleContainer = styled.div`
    display: flex;
`

export const TitleText = styled.span<{ collapsed?: string }>`
    display: flex;
    align-items: center;
    margin-left: 6px;

    ${({collapsed}) =>
            collapsed &&
            css`
                display: none !important;
            `};
`
