import React, {type SVGProps} from "react"

export function IconAdditionalMenu(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=additional menu, size=24">
        <circle id="Ellipse 6074" cx="12.5" cy="4.5" r="2.5" fill="currentColor"/>
        <circle id="Ellipse 6075" cx="12.5" cy="12" r="2.5" fill="currentColor"/>
        <circle id="Ellipse 6076" cx="12.5" cy="19.5" r="2.5" fill="currentColor"/>
    </g>
</svg>

    )
}
