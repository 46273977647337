import React, {useMemo} from 'react';
import {message, notification, Popconfirm, Table} from 'antd';
import {
    AccessLevel,
    Project,
    ProjectMemberInvite,
    useProjectLeaveMutation,
    useProjectMemberChangeLevelMutation,
    useProjectMemberInviteRemoveMutation,
    useProjectRemoveMemberMutation,
    User,
    UsersUnion
} from "../../generated-types";
import {useTranslation} from "react-i18next";
import AccessLevelSelector from "../AccessLevelSelector";
import {showErrors, showLoading} from "../helpers";
import {authState} from "../../routes/Auth/authContext";
import {useReactiveVar} from "@apollo/client";
import {updateProjectInCache} from "../../subscriptions/allProjects";
import styled, {css} from "styled-components";
import ProjectMembersEditor2 from "./ProjectMembersEditor2";
import {IconSorting} from "../Icon/IconSorting";
import UserAvatar from "../User/UserAvatar";
import {IconDelete} from "../Icon/IconDelete";
import {IconStop} from "../Icon/IconStop";
import {allUsersUnions} from "../../subscriptions/allUsersUnions";
import TooltipButton from "../TooltipButton";
import {IconClock} from "../Icon/IconClock";
import {ColumnType} from "antd/lib/table";

interface ProjectMembersEditorProps {
    project: Project
}


const Holder = styled.div<{}>`
    ${p => !p.theme.isMobile && css`margin: 40px 24px;`}
    max-width: 1141px;

    .ant-table-content {
        overflow: scroll;
    }
`

const TableStyled = styled(Table)<{}>`
    ${p => !p.theme.isMobile && css`margin: 40px 24px;`}
    max-width: 1141px;

    .ant-table-column-sorters {
        justify-content: left;

        .ant-table-column-title {
            flex: 0;
        }
    }

    .ant-table-cell.ant-table-column-sort {
        background: none;
    }

    .ant-table-thead {

        .ant-table-cell.ant-table-column-sort {
            background: none;
        }

        .ant-table-cell {
            padding: 8px 16px;
            font-size: 14px;
            font-weight: 300;
            gap: 4px;
            background: none;
        }
    }

    .invited {
        color: ${p => p.theme.colors.ui.bgDark05};
        display: flex;
        align-items: center;
    }

    .role-column {
        display: flex;

        .role-holder {
            flex: 1;
        }

        .actions {
            .ant-btn {
                color: ${p => p.theme.colors.ui.bgDark05}
            }
        }
    }
`


interface DataType {
    key: string;
    user?: User;
    accessLevel: AccessLevel;
    uu?: UsersUnion;
    invite?: ProjectMemberInvite;
}

const ProjectMembersList: React.FC<ProjectMembersEditorProps> = ({project}) => {
    const {t} = useTranslation()
    const authInfo = useReactiveVar(authState);
    const [messageApi, contextHolder] = message.useMessage();
    const allUnions = useReactiveVar(allUsersUnions);

    const uuMembers = useMemo(() => {
        return allUnions.unions.find(uu => uu.id == project.usersUnionAsOwner?.id)?.members ?? []
    }, [allUnions, project.usersUnionAsOwner])
    const userIsInUnion = useMemo(() => {
        if (!uuMembers) return false;
        return uuMembers.some(m => m.user.id == authInfo.user.id)
    }, [uuMembers])

    const [ChangeLevel, opt2] = useProjectMemberChangeLevelMutation({});

    const [Remove, opt3] = useProjectRemoveMemberMutation({

        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });

    const isAdmin = project.members.some(m => m.user.id === authInfo.user.id && m.accessLevel === AccessLevel.Admin)

    const [RemoveInvite] = useProjectMemberInviteRemoveMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });

    const [leaveProject, {}] = useProjectLeaveMutation({
        onCompleted: (_) => {
            notification.success({
                message: t("project.leavedSuccessfully"),
                description: project.title,
            });
        }
    });


    const sortIcon = useMemo(() => {
        return (props: { sortOrder: any }) => (props.sortOrder == 'ascend' ? <IconSorting/> : <IconSorting/>)
    }, [])

    const columns: ColumnType<unknown>[] = useMemo(() => {
        return [
            {
                title: 'Имя',
                key: 'name',
                showSorterTooltip: false,
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sorter: (a: DataType, b: DataType) => ((a.user?.fullName ?? a.user?.username ?? '') > (b.user?.fullName ?? b.user?.username ?? '') ? 1 : -1),
                sortIcon: sortIcon,
                render: (d: DataType) => {
                    return <>
                        {/*<UserListItem user={d.user} />*/}
                        {d.user && <UserAvatar user={d.user} addTitle={true}/>}
                        {/* Этот кусок не отрисован в дизайне. Пока оставляем как есть */}
                        {d.uu && <div style={{margin: "0px 20px", color: "gray"}}><span
													style={{color: "#d7d7d7"}}>{t('usersUnion.team')}</span> {d.uu.title}</div>}
                        {d.invite?.usersUnion &&
													<div>{t('countInvitesUnionMembers', {count: d.invite.maxMembers})}</div>}
                    </>
                },
            },
            {
                title: 'Эл.почта',
                key: 'email',
                showSorterTooltip: false,
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sorter: (a: DataType, b: DataType) => ((a.user?.email ?? '') > (b.user?.email ?? '') ? 1 : -1),
                sortIcon: sortIcon,
                render: (d: DataType) => {
                    return <span style={{padding: 3}}>{d.user?.email ?? d.invite?.email}</span>
                },
            },
            {
                title: 'Роль',
                key: 'role',
                className: 'role-column',
                showSorterTooltip: false,
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sorter: (a: DataType, b: DataType) => ((a.user?.fullName ?? a.user?.username ?? '') > (b.user?.fullName ?? b.user?.username ?? '') ? 1 : -1),
                sortIcon: sortIcon,
                render: (d: DataType) => {
                    const isCurUser = d.user?.id === authInfo.user.id;

                    const selector = <AccessLevelSelector selected={d.accessLevel}
                                                          disabled={!isAdmin || project.archived}
                                                          onChange={(level: AccessLevel) => {
                                                              if (isCurUser) {
                                                                  messageApi.warning(t('youCantEditYourselfInProjectMembers'));
                                                                  return false;
                                                              } else if (d.user)
                                                                  ChangeLevel({
                                                                      variables: {
                                                                          input: {
                                                                              userId: d.user.id,
                                                                              projectId: project.id,
                                                                              level
                                                                          }
                                                                      }
                                                                  })
                                                              return true;
                                                          }
                                                          }/>;

                    return <>
                        <div className={'role-holder'}>
                            {!d.invite && selector}
                            {d.invite && d.user == null && d.uu == null && <span className={'invited'}>
                                <IconClock/>{t('invitedByEmail')}
                            </span>}
                            {d.invite && d.user != null && <span className={'invited'}>
                                <IconClock/>{t('invitedUser')}
                            </span>}
                            {d.invite && d.uu != null && <span className={'invited'}>
                                <IconClock/>{t('invitedUsersUnion')}
                            </span>}
                        </div>

                        {!project.archived && (isAdmin || isCurUser) && <div className={'actions'}>
													<Popconfirm
														icon={null}
														title={t('really')}
														description={d.invite ?
                                t('inviteSureRemove') : (isCurUser ? t('project.leaveConfirmDescription') : t('projectMembers.SureRemove'))}
														onConfirm={() => {
                                if (d.invite)
                                    RemoveInvite({variables: {id: d.invite.id}})
                                else if (d.user) {
                                    if (isCurUser && userIsInUnion) {
                                        leaveProject({
                                            variables: {
                                                projectId: project.id
                                            }
                                        })
                                        return false;
                                    } else if (isCurUser && isAdmin) {
                                        messageApi.warning(t('youCantEditYourselfInProjectMembers'));
                                        return false;
                                    } else
                                        Remove({
                                            variables: {
                                                input: {
                                                    userId: d.user.id,
                                                    projectId: project.id
                                                }
                                            }
                                        })
                                }
                            }}
														onCancel={() => {
                            }}
														okText={t('yes')}
														cancelText={t('no')}
													>
														<TooltipButton type="text" tooltipProps={{
                                title: isCurUser ? t('project.leaveTooltip') : t('remove')
                            }}>{
                                isCurUser ? <IconStop/> : <IconDelete/>
                            }</TooltipButton>
													</Popconfirm>
												</div>}
                    </>
                },
            }
        ] as ColumnType<unknown>[];
    }, []);

    const data: DataType[] = useMemo(() => {
        const members = project.members.map(m => {
            return {
                user: m.user,
                accessLevel: m.accessLevel,
                uu: m.asUnion,
            } as DataType
        })

        const invites = project.invites.map(i => {
            return {
                user: i.user,
                accessLevel: i.accessLevel,
                uu: i.usersUnion,
                invite: i,
            } as DataType
        })

        return [...members, ...invites];
    }, [project.members, project.invites])

    return (
        <Holder>
            {contextHolder}
            {showErrors([opt2, opt3])}
            {showLoading([opt2, opt3])}
            <ProjectMembersEditor2 project={project} totalMembers={data.length}/>

            <TableStyled
                columns={columns}
                dataSource={data}
                bordered={false}
                pagination={false}
            />
        </Holder>
    );
};

export default ProjectMembersList;