import React, { useEffect, useRef, useState } from 'react'
import { DragPreviewImage, DropTargetOptions, useDrag, useDrop } from 'react-dnd'
import styled from 'styled-components'
import { GeneralBoardColumnType } from './GeneralBoardColumn'
import { message } from 'antd'
import { theme } from 'antd'
import { isMobile } from 'react-device-detect'
import { draggingCardSize } from '../CommonDragLayer'

const boxImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGUExURdva0////yqEAr4AAAABdFJOUwE34ejwAAAAAWJLR0QB/wIt3gAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB+cIDhcDNATKr+sAAAAKSURBVAjXY2AAAAACAAHiIbwzAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA4LTE0VDIzOjAzOjQyKzAwOjAwy5RdQAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wOC0xNFQyMzowMzo0MiswMDowMLrJ5fwAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDgtMTRUMjM6MDM6NTIrMDA6MDAhdsS9AAAAAElFTkSuQmCC'

const GenearalBoardCardContainer = styled.div<{$isOver: boolean, $isMobile?: boolean }>`
width: 100%;
border-radius: 8px;
background-color: ${({theme})=>theme.colors.ui.white};
position: relative;
min-width: 298px;
transition: all .3s ease-in-out;


${({$isOver,theme, $isMobile})=>$isOver && `&::before{
    content: '';
    background-color: ${theme.colors.ui.accent};
    position: absolute;
    width: 100%;
    height: 2px;
    top: ${$isMobile? '-3px' : '-6px'};
    border-radius: 1px;
}`}

&:hover{
    background-color: ${({theme})=>theme.colors.ui.bgLight2}
}
`

export type GeneralBoardItemType<T> = {
    id: number | string,
    columnId: number | string,
    item: T
}

interface GeneralBoardCardProps<I, C> {
    signature: string,
    item: GeneralBoardItemType<I>,
    onItemRender: (item: I) => React.JSX.Element,
    onItemDropped: (droppedItem: I, droppedOnItem?: I, column?: C) => void,
    isBoardDragging: boolean,
    touchEventsDelay: number
}

function GeneralBoardCard<I, C>({ signature, item, onItemRender, onItemDropped, isBoardDragging, touchEventsDelay }: GeneralBoardCardProps<I, C>) {

    const [canDrag, setCanDrag]=useState(false)
    const holdTimer=useRef<NodeJS.Timeout | null>(null)
    const itemCardRef=useRef<HTMLDivElement>(null)

    const [{isDragging}, dragRef, preview] = useDrag(() => ({
        type: signature,
        item: item.item,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }), 
        
    }), [])

    const [{ isOver }, dropRef] = useDrop(() => (
        {
            accept: signature,
            drop(itemDropped: I, monitor) {
                onItemDropped(itemDropped, item.item)
            },
            collect: (monitor) => ({
                isOver: monitor.getItem()!==item.item && monitor.isOver(),
                canDrop: monitor.canDrop()
            }),
        }
    ), [])

    useEffect(()=>{
        if(isDragging && itemCardRef.current){
            const {width, height}=itemCardRef.current.getBoundingClientRect()
            
            draggingCardSize({width: width, height})
        }
    }, [isDragging])

    dropRef(itemCardRef)
    //нужно как-то сообщать пользователю, что элемент можно перетаскивать после зажатия, пока сделал сообщение
    const touchStartHandler=()=>{
        holdTimer.current=setTimeout(() => {
            setCanDrag(true)
          }, touchEventsDelay);
    }

    const touchEndHandler=()=>{
        if(holdTimer.current){
            clearTimeout(holdTimer.current)
            holdTimer.current=null
        }
        if(canDrag){
            setCanDrag(false)
        }
    }

    useEffect(()=>{
        if(canDrag){
            message.success('Элемент можно перетаскивать')
        }
    }, [canDrag])
    
    return (<>
        <DragPreviewImage connect={preview} src={boxImage}/>
        <GenearalBoardCardContainer
        ref={dragRef} 
        onTouchStart={touchStartHandler}
        onTouchEnd={touchEndHandler}
        $isMobile={isMobile}
        $isOver={isOver}
        >
            <div ref={itemCardRef} style={{opacity: isDragging? 0 : 1}}>
                {
                    onItemRender(item.item)
                }
            </div>
        </GenearalBoardCardContainer>
    </>
    )
}

export default GeneralBoardCard