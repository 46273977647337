import { Card, Col, theme } from 'antd'
import React, { forwardRef, Ref } from 'react'
import { useDrop } from 'react-dnd'
import styled from 'styled-components'
import GeneralBoardCard, { GeneralBoardItemType } from './GeneralBoardCard'
import { isMobile } from 'react-device-detect'
import Scrollbar from 'react-scrollbars-custom'

const GeneralBoardColumnContainer = styled.div`
width: 100%;
height: 100%;
align-self: center;
`
const GeneralBoardColumnTitle = styled.div`
display: flex;
flex-direction: column;
`
const GeneralBoardColumnBody = styled.div<{ $isMobile?: boolean, $isOver: boolean }>`
    display: flex;
    padding: ${({ $isMobile }) => $isMobile ? '4px 0px 30px 0px' : '12px 0px 30px 0px'};
    gap: ${({ $isMobile }) => $isMobile ? '4px' : '12px'};
    flex-direction: column;
    min-height: 100%;

    ${({$isOver,theme})=>$isOver && `&::after{
    content: '';
    background-color: ${theme.colors.ui.accent};
    width: 100%;
    height: 2px;
    border-radius: 1px;
}`}

`

const CardStyled = styled(Card) <{ $isOver: boolean }>`
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: unset;
        box-shadow: none;
        border: none;
        padding: 0;
        overflow: visible;
        
        >.ant-card-head{
            background-color: unset;
            box-shadow: none;
            border: none;
            padding: 0;
            flex-shrink: 0;
            z-index: 2;
            
        }

        >.ant-card-body{
            flex-grow: 1;
            overflow-y: hidden;
            padding: 0;
            padding: 0;
            overflow: visible;
            position: relative;

            &::before{
                position: absolute;
                content: '';
                width: 100%;
                top: 0;
                transform: translateY(-50%);
                background: linear-gradient(to bottom, ${({theme}) => theme.colors.ui.bgLight}, rgba(255, 255, 255, 0));
                height: 40px;
                z-index: 1;
            }
        }
`

export type GeneralBoardColumnType<T> = {
    id: number | string,
    title: string
    item: T
}

interface GeneralBoardColumnProps<I, C> {
    signature: string,
    items: GeneralBoardItemType<I>[],
    onItemRender: (item: I) => React.JSX.Element,
    column: GeneralBoardColumnType<C>,
    columnTitle: string | JSX.Element,
    onItemDropped: (droppedItem: I, droppedOnItem?: I, column?: C) => void
    stickyTopElement?: (column: C) => React.JSX.Element
    isBoardDragging: boolean
    cursorType?: string,
    touchEventsDelay: number
    width?: number | string
}

function GeneralBoardColumn<I, C>({ signature,items, onItemRender, column, columnTitle, 
    onItemDropped, stickyTopElement, isBoardDragging, 
    cursorType, touchEventsDelay, width }: GeneralBoardColumnProps<I, C>) {

    const {token}=theme.useToken()

    const [{ isOver, canDrop }, drop] = useDrop(() => (
        {
            accept: signature,
            drop(itemDropped: I, monitor) {
                if (monitor.isOver({ shallow: true })) {
                    onItemDropped(itemDropped, undefined, column.item)
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver({shallow: true}),
                canDrop: monitor.canDrop(),
                
            })
        }
    ), [])

    const header = <GeneralBoardColumnTitle>
        {columnTitle}
        {stickyTopElement && stickyTopElement(column.item)}
    </GeneralBoardColumnTitle>

    return (
        <Col
            role='COLUMN'
            style={{ display: 'flex', 
            flexDirection: 'column', 
            height: '100%', 
            flexShrink: 0, 
            width: width, 
            overflow: 'visible',
            padding: isMobile? '0px 4px' : '0px 16px',
            minWidth: 330 }}>
            <GeneralBoardColumnContainer ref={drop}>
                <CardStyled className={''}
                    $isOver={isOver}
                    title={header}
                >
                    <Scrollbar style={{overflow: 'visible'}}
                    wrapperProps={{
                        renderer: (props) => {
                            const { elementRef, ...restProps } = props;
                            return <div {...restProps} ref={elementRef} style={{
                                inset: 0,
                                position: 'absolute',
                                overflow: 'hidden'
                            }}/>;
                          },
                    }}

                    thumbYProps={{
                        renderer: (props)=>(<div ref={props.elementRef}
                        {...props}
                        style={{
                            ...props.style,
                            backgroundColor: token.colors.ui.bgDark,
                            opacity: 0.2
                        }}/>)
                    }}
                    trackYProps={{
                        
                        renderer: (props)=>(<div
                        ref={props.elementRef}
                        {...props} style={{
                            ...props.style,
                            width: 4,
                            height: 'calc(100% - 42px)',
                            position: 'absolute',
                            top: 0,
                            right: isMobile? -6 : -8,
                            marginTop: isMobile ? 4 : 12,
                            backgroundColor: token.colors.ui.bgLight3
                        }}/>)
                    }}>
                        <GeneralBoardColumnBody
                            $isMobile={isMobile}
                            $isOver={isOver && canDrop}
                            style={{ cursor: cursorType }}>
                            {
                                items.map((item) => <GeneralBoardCard
                                    key={`GB-item-${column.id}-${item.id}`}
                                    signature={signature}
                                    item={item}
                                    onItemRender={onItemRender}
                                    onItemDropped={onItemDropped}
                                    isBoardDragging={isBoardDragging}
                                    touchEventsDelay={touchEventsDelay}
                                />)
                            }
                        </GeneralBoardColumnBody>
                    </Scrollbar>
                </CardStyled>
            </GeneralBoardColumnContainer>
        </Col>
    )
}

export default GeneralBoardColumn