import React, {type SVGProps} from "react"

export function IconSearch(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=search, size=24" clipPath="url(#clip0_31_5645)">
        <g id="Frame 3">
            <path id="Vector" d="M17 17L21 21" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2"
                  d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_31_5645">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}
