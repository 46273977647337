import React, {type SVGProps} from "react"

export function IconArchive(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=archive, size=24">
        <path id="Vector"
              d="M2.5 6.5296V17.75C2.5 18.9927 3.50735 20 4.75 20H19.25C20.4927 20 21.5 18.9927 21.5 17.75V6.5296M2.5 6.5296V5.67435C2.5 4.61155 3.50735 3.75 4.75 3.75H19.25C20.4927 3.75 21.5 4.61155 21.5 5.67435V6.5296"
              stroke="currentColor"/>
        <path id="Vector_2"
              d="M2.5 7H7.90215C8.12278 7 8.33217 7.09713 8.4747 7.26555L9.4003 8.35945C9.54283 8.52788 9.75222 8.625 9.97285 8.625H12H14.0272C14.2478 8.625 14.4572 8.52788 14.5997 8.35945L15.5253 7.26555C15.6678 7.09713 15.8772 7 16.0979 7H21.5"
              stroke="currentColor"/>
        <path id="Vector_3" d="M9 14H15" stroke="currentColor" strokeLinecap="round"/>
    </g>
</svg>

    )
}
