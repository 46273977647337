import React from 'react'
import styled from 'styled-components'
import { Task } from '../../generated-types'
import { Space, Tooltip } from 'antd'
import { t } from 'i18next'
import { IconFiles } from '../Icon/IconFiles'
import { IconSubTasks } from '../Icon/IconSubTasks'


const TaskIconsContainer=styled.div`
    /*flex-grow: 1;*/
    align-self: end;
    display: flex;
    justify-content: end;
`

const SpanStyled=styled.span`
font-size: 12px;
display: flex;
align-items: center;
gap: 4px;

>svg{
    stroke-width: 1.5;
    width: 16px;
    height: 16px;
}
`

function TaskFilesAndChildTasks({task}: {task: Task}) {
  return ((task.childTasks?.length ?? 0) > 0 || (task.files?.length ?? 0) > 0) ? <TaskIconsContainer>
        <Space>
        {task.files.length>0 && <Tooltip title={t('task.files')}>
            <SpanStyled>
                <IconFiles/>
                 {task.files.length}
            </SpanStyled>
            </Tooltip>}
        {task.childTasks && task.childTasks.length>0 && <Tooltip title={t('task.childTasks')}>
            <SpanStyled>
                <IconSubTasks/>{task.childTasks.filter(ct => ct?.resolved).length}/{task.childTasks.length}
            </SpanStyled>
        </Tooltip>
        }
    </Space>
    </TaskIconsContainer>
    : 
    <></>
  
}

export default TaskFilesAndChildTasks