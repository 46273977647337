import jwtDecode, {JwtPayload} from "jwt-decode";
import {makeVar, ReactiveVar} from "@apollo/client";
import client from "../../ApolloClient";

export const tokenKey = "token";

interface AuthState {
    jwt: JwtPayload | null;
    user: any
}

function emptyState(): AuthState {
    return {
        jwt: null,
        user: null
    }
}

export const authState: ReactiveVar<AuthState> = makeVar<AuthState>(emptyState());
export const yandexAuthState: ReactiveVar<boolean>=makeVar<boolean>(false)//наверное надо придумать что-то другое, состояние не всегда актуально, из-за времени обновления токена

const decode = (token: string | null) => {
    const state = emptyState()

    if (token) {
        const decodedToken: JwtPayload = jwtDecode(token);
        if ((decodedToken.exp || 0) * 1000 < Date.now()) {
            localStorage.removeItem(tokenKey);
            console.log('TOKEN EXPIRED')
        }
        else {
            state.jwt = decodedToken;
            state.user = (decodedToken as any).user;
            // Из-за того, что везде используется строгое сравнение (===) вместо (==) требуется приведение к строке
            state.user.id = ""+state.user.id
            yandexAuthState(state.user.yandexAuth)
        }
    }
    else {
        console.log('NO AUTH TOKEN')
    }
    authState(state);
}

decode(localStorage.getItem(tokenKey));

export function saveToken(token: string) {
    localStorage.setItem(tokenKey, token);
    decode(token);
}

export function clearToken() {
    localStorage.removeItem(tokenKey);
    decode(null);
    client.cache.reset()
    client.cache.gc()
    client.resetStore();
}