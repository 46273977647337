import React, {type SVGProps} from "react"

export function IconMenu(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=menu, size=24">
        <g id="Group 49">
            <path id="Vector" d="M3.92383 6H20.0762" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2" d="M3.92383 12H20.0762" stroke="currentColor" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path id="Vector_3" d="M3.92383 18H20.0762" stroke="currentColor" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </g>
    </g>
</svg>

    )
}
