import React, {type SVGProps} from "react"

export function IconRock(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=rock, size=32">
        <g id="Emoji">
            <path id="Vector"
                  d="M16.3052 28.7273L8.6748 21.374L9.74826 13.5756C9.74826 12.7892 10.2026 12.1143 10.8536 11.8108L14.4318 12.6059C14.4318 11.8195 14.8861 11.1446 15.5371 10.8411L19.0178 11.6158L19.1152 11.6362V2.90885C19.1152 2.12242 19.5695 1.44751 20.2205 1.14399L23.6039 14.3902L23.7987 14.5453C24.6624 14.5453 25.4127 16.4847 25.4127 16.4847L25.4145 16.4993L16.3052 28.7273Z"
                  fill="#EF9645"/>
            <g id="Group">
                <path id="Vector_2"
                      d="M14.4304 15.3002C13.8599 15.8326 13.4937 16.5957 13.4937 17.4549C13.4937 18.1851 13.7625 18.8445 14.1915 19.3545C13.8721 19.9528 13.2642 20.364 12.557 20.364C11.5229 20.364 10.6836 19.4951 10.6836 18.4246V12.6063C10.6836 12.3222 10.7464 12.0546 10.8522 11.8112C11.1454 11.1372 11.7973 10.6669 12.557 10.6669C13.5911 10.6669 14.4304 11.5358 14.4304 12.6063V15.3002Z"
                      fill="#FFDC5D"/>
                <path id="Vector_3"
                      d="M6 4.8482C6 3.77765 6.83928 2.90879 7.8734 2.90879C8.90751 2.90879 9.74679 3.77765 9.74679 4.8482V18.4241C9.74679 19.2377 10.1009 20.0328 10.6826 20.5778C11.7766 21.5087 13.3456 21.5892 14.4293 20.5759C14.6186 20.3974 14.789 20.2006 14.9258 19.9766L14.923 19.9737C15.1113 20.0852 15.367 20.3635 16.3037 20.3635H18.6801C16.9856 21.6871 15.8353 24.2326 15.8353 27.1514C15.8353 27.4191 16.0452 27.6363 16.3037 27.6363C16.5622 27.6363 16.772 27.4191 16.772 27.1514C16.772 23.4093 18.8206 20.3635 21.3384 20.3635C22.0063 20.4401 22.1318 19.3938 21.4555 19.3938H16.3037C15.2696 19.3938 14.4303 18.5249 14.4303 17.4544C14.4303 16.3838 15.2696 15.515 16.3037 15.515H23.7973C24.704 15.515 25.1845 16.0929 25.4131 16.4992C25.5311 16.7096 25.5864 16.8764 25.5892 16.8871L26.6074 20.3635C26.7038 20.6884 27.056 22.1778 26.9923 22.4881C26.9923 27.1514 22.5936 32 17.2404 32C11.1172 32 6 26.909 6 20.3635C6.00749 20.3703 6 4.8482 6 4.8482Z"
                      fill="#FFDC5D"/>
                <path id="Vector_4"
                      d="M19.114 14.5456H16.3039C15.9742 14.5456 15.6622 14.6154 15.3672 14.724V11.6365C15.3672 11.3523 15.4299 11.0847 15.5358 10.8413C15.829 10.1674 16.4809 9.69706 17.2406 9.69706C18.2747 9.69706 19.114 10.5659 19.114 11.6365V14.5456ZM20.0507 14.5456V1.93941C20.0507 1.65529 20.1134 1.38765 20.2193 1.14425C20.5125 0.470307 21.1644 0 21.9241 0C22.9582 0 23.7975 0.868857 23.7975 1.93941V14.5456H20.0507Z"
                      fill="#FFDC5D"/>
            </g>
        </g>
    </g>
</svg>

    )
}
