import {editTaskContext} from "./TaskEditor";
import React from "react";
import {Task, TasksView} from "../../generated-types";
import {FormatInterval} from "../TimeIntervalSelector";
import styled from "styled-components";


const AStyled=styled.a<{$resolved: boolean}>`
    display: inline-block;
    cursor: pointer;
    font-weight: 600;
    text-decoration: ${({$resolved})=>$resolved? 'line-through' : 'none'};
    color: ${({theme})=>theme.colors.font.primary} !important;
    white-space: normal;
    max-height: 3em;
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 2; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: normal; 
`

const TaskTitle = ({task, openOnClick = true, tasksView}: {task: Task, openOnClick?: boolean, tasksView?: TasksView}) => {

    return (
        <AStyled
        $resolved={task.resolved}
         onClick={(e) => {
            if (openOnClick)
                editTaskContext({taskId: task.id, taskView: tasksView});
            e.preventDefault();
            e.stopPropagation();
        }}>
           
                {/*<span
                    style={{paddingRight: 5}}>{task.startTime && task.endTime && FormatInterval(task.startTime, task.endTime)}</span>*/}
                {task.title}
            
        </AStyled>)
}

export default TaskTitle