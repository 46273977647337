import { theme } from 'antd'
import React, { PropsWithChildren } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { ThemeProvider } from 'styled-components'

function StyledThemeProvider({children}:PropsWithChildren) {

    const { token } = theme.useToken()

    return (
        <ThemeProvider theme={{...token, isMobile: isMobileOnly}}>
            {children}
        </ThemeProvider>
    )
}

export default StyledThemeProvider