import React, {ReactElement, useEffect, useMemo, useRef, useState} from 'react'
import {Button} from 'antd'
import {useTranslation} from "react-i18next";
import {useReactiveVar} from "@apollo/client";
import {allProjects} from "../../subscriptions/allProjects";
import {allUsersUnions} from "../../subscriptions/allUsersUnions";
import {allPersonalTags} from "../../subscriptions/allPersonalTags";
import {onlyUnique} from "../../utils";
import Dropdowned from "../Dropdowned";
import styled, {css} from "styled-components";
import {IconFilter} from "../Icon/IconFilter";
import {IconArrowDown} from "../Icon/IconArrowDown";
import {Icon} from "../Icon/Icon";
import UserAvatar from "../User/UserAvatar";
import UsersUnionsAvatar from "../UsersUnion/UsersUnionsAvatar";
import Checkbox from "../Checkbox";
import {isMobile} from "react-device-detect";
import TagDisplayItem from "../Tagger/TagDisplayItem";
import {Project, User} from "../../generated-types";

export enum AvailableFilters {
    flags = 'flags',
    tags = 'tags',
    usersUnions = 'usersUnions',
    members = 'members',
}

export enum AvailableFlags {
    iamMember = 'iamMember',
    invites = 'invites',
    active = 'active',
    archived = 'archived'
}

export type FiltersType = {
    [k in AvailableFilters]: boolean
}

interface ProjectsFilterProps {
    disable: boolean
    projects: Project[]
    excludeFlags?: AvailableFlags[]
    onFilterChanged?: (filter: FilterKeyItem[]) => FilterKeyItem[]
    showFilters: FiltersType
}

const Btn = styled(Button)<{ $filterEnabled: string }>`
    background-color: ${({theme}) => theme.colors.ui.bgLight3};
    color: ${({theme}) => theme.colors.font.primary};
    box-shadow: unset;
    border: none;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 14px;
    height: 100%;

    ${({$filterEnabled, theme}) => $filterEnabled === 'true' && css`
        .ant-btn-icon > svg {
            color: ${theme.colors.ui.accent};
            fill: ${theme.colors.ui.accent};
        }
    `};

    div {
        flex-grow: 1;
        text-align: left;
    }

    .ant-btn-icon {
        display: flex;
    }

    &:hover {
        background-color: ${({theme}) => theme.colors.ui.bgLight};
    }
`

const DropdownedContent = styled.div<{ $columnWidth: string, $isMobile: string }>`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 80vh;
    max-width: 80vw;
    min-width: 660px;

    .filter-item {
        display: flex;
        flex-direction: column;
        background-color: ${p => p.theme.colors.ui.bgLight};
        width: ${p => p.$columnWidth};
        padding: 0px 12px 12px 12px;
        border-radius: 8px;
        font-size: 12px;
        gap: 4px;

        ${p => p.$isMobile != 'true' && css`
            overflow-y: auto;
            overflow-x: hidden;
        `};

        .title {
            background-color: ${p => p.theme.colors.ui.bgLight};
            position: sticky;
            top: 0;
            font-size: 12px;
            z-index: 10;
            padding-top: 12px;
        }

        div {
            font-size: 12px;
            display: flex;
            width: 100%;
            height: 24px;
            align-items: center;

            .item {
                line-height: 1.2;
            }
        }

        .user-title {
            padding-left: 3px;
            overflow: hidden;
            line-break: anywhere;
            white-space: nowrap;
            //text-wrap: pretty;
        }

        .uu-title {
            display: flex;
            gap: 3px;
            overflow: hidden;
            line-break: anywhere;
            white-space: nowrap;
        }
    }

    .tag-item {
        margin: 0 !important;
    }

    .row {
        display: flex;
        gap: 4px;
    }

    .row.title {
        align-content: center;
    }

    .ant-btn {
        padding: 10px 0;
        height: 22px;
        color: ${p => p.theme.colors.font.primarySemitransparent};
    }

    .ant-btn:hover {
        color: ${p => p.theme.colors.font.accent} !important;
    }

    .row.first {
        max-height: 153px;
    }

    .row.second {
        max-height: 237px;
    }
`

function FilterItems({items, title, type, onChange, selectedItems}: {
    type: string,
    title: string,
    items: { key: string, selected?: boolean, r: ReactElement }[]
    onChange: (type: string, key: string, value: boolean) => void,
    selectedItems: FilterKeyItem[]
}) {
    return <div className={"filter-item"}>
        <div className={"title"}>{title}</div>
        {items.map(v => {
            return <div key={v.key}
                        onClick={(e) => {
                            onChange(type, v.key, !selectedItems.some(si => si.type == type && si.key == v.key))
                            e.preventDefault()
                            e.stopPropagation()
                        }}>
                <Checkbox key={v.key} className={"item"}
                          checked={selectedItems.some(si => si.type == type && si.key == v.key)}
                >{v.r}</Checkbox>
            </div>
        })}
    </div>
}

export interface FilterKeyItem {
    key: string;
    type: AvailableFilters;
    value: boolean;
}

let timer: any;

function ProjectsFilter({
                            projects,
                            onFilterChanged,
                            showFilters,
                            disable,
                            excludeFlags = []
                        }: ProjectsFilterProps) {
    const btnRef = useRef<HTMLButtonElement>(null)
    const [opened, setOpened] = useState(false)
    const [filterEnabled, setFilterEnabled] = useState(false)
    const [filterKeyItems, setFilterKeyItems] = useState<FilterKeyItem[]>([])
    const {t} = useTranslation();

    showFilters = showFilters ?? {
        flags: true, members: true, projects: true, usersUnions: true, tags: true, priority: true
    } as FiltersType

    const allP = useReactiveVar(allProjects);
    const allUnions = useReactiveVar(allUsersUnions);
    const personalTags = useReactiveVar(allPersonalTags);

    // Надо собрать все теги с проектов и команд, которые есть в задачах и отобразить в списке тегов
    const tags = useMemo(() => {
        const proj = allP.projects.flatMap(p => p.tags)
        const unions = allUnions.unions.flatMap(u => u.tags)
        const allTags = proj.concat(unions).concat(personalTags.tags);
        const res = projects.map((t) => {
            return allTags.filter((ct) => t.attachedTags.some(tt => tt === ct.id))
        }).flat().filter(onlyUnique).map(key => {
            return {
                key: key.id, r: <TagDisplayItem tag={key} removable={false} onClick={() => {
                    onChangeFilter("tags", key.id, true)
                }}/>
            }
        })
        return res;
    }, [projects])

    const flags = useMemo(() => {
        return [AvailableFlags.iamMember, AvailableFlags.invites, AvailableFlags.active, AvailableFlags.archived]
            .filter(v => !excludeFlags?.some(vv => vv == v))
            .map(key => {
                return {key, r: <div>{t('projectsFilter.filterFlags.' + key)}</div>}
            })
    }, []);

    useEffect(() => {
        setFilterEnabled(filterKeyItems.length > 0);

        // таймер чтобы не сразу применялся фильтр, т.к. это может сказаться на производительности
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            if (onFilterChanged) {
                setFilterKeyItems(onFilterChanged(filterKeyItems))
            }
        }, 500)
    }, [filterKeyItems]);

    const members = useMemo(() => {
        return projects.map((t) => t.members).flat().map(t => t.user)
            .filter(onlyUnique)
            .map((key: User) => {
                // TODO: универсальный компонент для имени пользователя с аватаркой
                return {
                    key: key.id, r: <div>
                        <UserAvatar user={key} size={"16"} addTitle={true}/>
                    </div>
                }
            })
    }, [projects])


    const teams = useMemo(() => {
        return projects
            .map(t => t.usersUnionAsOwner)
            .filter(onlyUnique)
            .filter(v => v != undefined)
            .map(uu => ({
                key: uu?.id as any,
                r: <div className={"uu-title"}><UsersUnionsAvatar size={"16"} uu={uu}/>{uu?.title}</div>
            }))
    }, [projects]);

    if (tags.length == 0) showFilters[AvailableFilters.tags] = false;
    if (members.length == 0) showFilters[AvailableFilters.members] = false;
    if (teams.length < 2) showFilters[AvailableFilters.usersUnions] = false;

    const onChangeFilter = (type: string, key: string | undefined, value: boolean) => {
        const v = {type, key, value} as FilterKeyItem;
        const exists = filterKeyItems.some(i => i.type == type && i.key == key);
        if (value && !exists)
            setFilterKeyItems([...filterKeyItems, v])
        else if (!value && exists)
            setFilterKeyItems(filterKeyItems.filter(i => !(i.type == type && i.key == key)));
    }

    let columnWidth = "33%"
    if (isMobile)
        columnWidth = "100%"

    const itemsFirst = [];
    if (showFilters.flags)
        itemsFirst.push(<FilterItems key={"flags"} items={flags} title={t('projectsFilter.filterShow')}
                                     type={AvailableFilters.flags}
                                     selectedItems={filterKeyItems}
                                     onChange={onChangeFilter}/>)

    if (showFilters.usersUnions)
        itemsFirst.push(<FilterItems key={"filterUsersUnion"} items={teams} title={t('projectsFilter.filterUsersUnion')}
                                     type={AvailableFilters.usersUnions}
                                     selectedItems={filterKeyItems}
                                     onChange={onChangeFilter}/>)

    if (showFilters.members)
        itemsFirst.push(<FilterItems key={"filterTaskMember"} items={members} title={t('projectsFilter.filterMember')}
                                     type={AvailableFilters.members}
                                      selectedItems={filterKeyItems}
                                      onChange={onChangeFilter}/>)
    if (showFilters.tags)
        itemsFirst.push(<FilterItems key={"filterTags"} items={tags} title={t('projectsFilter.filterTags')}
                                     type={AvailableFilters.tags}
                                      selectedItems={filterKeyItems}
                                      onChange={onChangeFilter}/>)

    return <>
        <Btn
            $filterEnabled={filterEnabled.toString()}
            iconPosition={"start"}
            // styles={{icon: {display: "flex", alignItems: 'center'}}}
            icon={<Icon size={"24"} icon={<IconFilter/>}/>}
            onClick={() => setOpened(true)}
            ref={btnRef}
            disabled={disable}
        >
            {!isMobile && <div>{t('filter')}</div>}
            <IconArrowDown/>
        </Btn>
        {opened && <Dropdowned opened={opened} onClose={() => setOpened(false)} anchor={btnRef}>
					<DropdownedContent $columnWidth={columnWidth} $isMobile={isMobile.toString()}>
						<div className={'row'}>
							<div style={{flexGrow: 1}} className={"title"}>{t('filters')}</div>
							<Button type={"link"} onClick={() => {
                  setFilterKeyItems([])
              }}>{t('filtersClear')}</Button>
						</div>
              {!isMobile && <div className={"row first"}>{itemsFirst}</div>}
              {isMobile && itemsFirst}
					</DropdownedContent>
				</Dropdowned>}
    </>
}

export default ProjectsFilter