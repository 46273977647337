import React, {useRef, useState} from 'react';
import {Button, GlobalToken, theme} from "antd";
import {
    Project,
    ProjectChangePriorityAndStatusMutation,
    useProjectChangePriorityAndStatusMutation
} from "../../generated-types";
import {useTranslation} from "react-i18next";
import {IconArrowDown} from '../Icon/IconArrowDown';
import SelectMenuDropdown, {CustomDropdownItem} from '../AntCastomComponents/SelectMenuDropdown';
import Dropdowned from '../Dropdowned';
import styled from "styled-components";
import {ProjectStatuses, statuses} from "../UsersUnion/UsersUnionProjectsBoard";

const getStyleByStatus = (s: ProjectStatuses, token: GlobalToken) => {
    switch (s) {
        case ProjectStatuses.new:
            return {color: token.colors.ui.project3}
        case ProjectStatuses.active:
            return {color: token.colors.ui.accent}
        case ProjectStatuses.finished:
            return {color: token.colors.ui.project4}
        case ProjectStatuses.frozen:
            return {}
        default:
            return {}
    }
}


interface ProjectChangeColumnProps {
    project: Project
    allowEdit?: boolean
}

const ProjectChangeColumnSelectRow = ({status}: { status: string }) => {

    const {t} = useTranslation()
    const {token} = theme.useToken()
    return <div
        style={{width: '100%', height: 16, display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 8}}>
        <span style={getStyleByStatus(status as ProjectStatuses, token)}>•</span>
        <span style={{fontSize: 12, lineHeight: 1, textAlign: 'start'}}>{t(`project.status.${status}`)}</span>
    </div>
}

export const ButtonStyled = styled(Button)<{ $token: GlobalToken }>`
    width: 165px;
    color: ${({$token}) => $token.colors.font.primary};
    background-color: ${({$token}) => $token.colors.ui.bgLight3};
    border: 0;
    box-shadow: unset;
    flex-direction: row-reverse;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    height: auto;

    &:hover {
        background-color: ${({$token}) => $token.colors.ui.bgLight};
    }
`

const ProjectChangeColumn = ({project, allowEdit = true}: ProjectChangeColumnProps) => {
    const btnRef = useRef<HTMLButtonElement>(null)
    const [opened, setOpened] = useState(false)
    const {t} = useTranslation();
    const {token} = theme.useToken();

    const [moveProject] = useProjectChangePriorityAndStatusMutation({
        optimisticResponse: ({projectId, status, priority}) => {
            const res = {
                projectChangePriorityAndStatus: {
                    id: projectId,
                    priority,
                    status
                }
            } as ProjectChangePriorityAndStatusMutation;
            return res;
        }
    })

    const dropdownItems = statuses.map((status: string) => ({
        label: <ProjectChangeColumnSelectRow status={status}/>,
        type: 'item',
        selected: status === project.status,
        key: status,
        onClick: () => {
            setOpened(false)
            moveProject({
                variables: {
                    projectId: project.id,
                    usersUnionId: project.usersUnionAsOwner!.id,
                    priority: project.priority ?? 0,
                    status: status
                },
            })

        }
    }) as CustomDropdownItem)


    return <>
        <ButtonStyled $token={token}
                styles={{icon: {display: "flex", alignItems: 'center'}}}
                icon={<IconArrowDown strokeWidth={'1.5px'}/>}
                onClick={() => setOpened(true)}
                ref={btnRef}
        >
            {dropdownItems.find((value: CustomDropdownItem) => value.selected)?.label}
        </ButtonStyled>
        {opened && <Dropdowned opened={opened}
				                       title={t('project.chooseStatus')}
				                       onClose={() => setOpened(false)}
								               anchor={btnRef}
				>
					<SelectMenuDropdown items={dropdownItems}/>
				</Dropdowned>}
    </>
}

export default ProjectChangeColumn;
