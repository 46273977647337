import React from 'react';
import {
    ProjectTransferRequest,
    useProjectTransferAcceptMutation,
    useProjectTransferRejectMutation,
    useProjectTransferRequestsQuery,
    User,
} from "../../generated-types";
import {useTranslation} from "react-i18next";
import {pollInterval} from "../../ApolloClient";
import {useNavigate} from "react-router-dom";
import TopAlertsList from "./TopAlertsList";

const ProjectTransfersList: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const transfers = useProjectTransferRequestsQuery({
        pollInterval: pollInterval * 2
    })

    const [accept] = useProjectTransferAcceptMutation({onCompleted: (data)=>{
            navigate(`/project/${data.projectTransferAccept.id}`)
            transfers.refetch({})
        } });
    const [reject] = useProjectTransferRejectMutation({onCompleted: ()=>{
        transfers.refetch({})
    }});

    const acceptInvite = (transfer: ProjectTransferRequest) => {
        if (!transfer) return;
        accept({
            variables: {
                transferId: transfer.id
            }
        })
    };

    const declineInvite = (transfer: ProjectTransferRequest) => {
        if (!transfer) return;
        reject({
            variables: {
                transferId: transfer.id
            }
        })
    };

    if (!transfers.data?.projectTransferRequests)
        return null;

    const requests = transfers.data?.projectTransferRequests as ProjectTransferRequest[];

    return <TopAlertsList<ProjectTransferRequest>
        onRender={(i) => {
            return <div style={{display: "flex"}}>
                {i.usersUnion
                    ? t('project.transferAcceptRejectInUsersUnion', {title: i.usersUnion?.title, name: i.project.title})
                    : t('project.transferAcceptRejectInPersonal', {name: i.project.title})
                }
            </div>
        }}
        items={requests}
        getUser={i => i.requester as User}
        acceptTitle={t('project.acceptInvite')}
        declineTitle={t('project.declineInvite')}
        declinePopupTitle={t('project.transferRejectTitle')}
        declinePopupText={t('project.transferRejectBody')}
        onAccept={acceptInvite}
        onDecline={declineInvite}
    ></TopAlertsList>
};

export default ProjectTransfersList;