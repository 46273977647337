import React, {type SVGProps} from "react"

export function IconSubTasks(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=sub-tasks, size=24">
        <g id="Frame">
            <path id="Vector"
                  d="M17 17C17 18.1046 17.8954 19 19 19C20.1046 19 21 18.1046 21 17C21 15.8954 20.1046 15 19 15C17.8954 15 17 15.8954 17 17Z"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2"
                  d="M17 7C17 8.10457 17.8954 9 19 9C20.1046 9 21 8.10457 21 7C21 5.89543 20.1046 5 19 5C17.8954 5 17 5.89543 17 7Z"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_3"
                  d="M3 7C3 8.10457 3.8954 9 5 9C6.1046 9 7 8.10457 7 7C7 5.89543 6.1046 5 5 5C3.8954 5 3 5.89543 3 7Z"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_4" d="M17 7L7 7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_5" d="M17 17L16 17C13.5 17 13 15 13 15L11 9C11 9 10.5 7 8 7L7 7" stroke="currentColor"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </g>
</svg>

    )
}
