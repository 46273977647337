import React, {type SVGProps} from "react"

export function IconCheck(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=check, size=16">
        <path id="Vector" d="M1 8.07699L5.36625 11.89C5.5325 12.0367 5.80375 12.0367 5.97 11.89L15 4" stroke="currentColor"
              strokeMiterlimit="10" strokeLinecap="round"/>
    </g>
</svg>

    )
}
