import React, {useEffect, useMemo, useState} from 'react'
import {Affix, Button, Col, Collapse, CollapseProps, Divider, Row, Switch, Tooltip, Typography} from 'antd'
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {FolderAddOutlined, SaveOutlined} from "@ant-design/icons";
import {
    Project,
    useProjectsListLazyQuery,
    User,
    UsersUnion,
    UsersUnionMemberInvite,
    useUsersUnionsListLazyQuery,
} from "../../generated-types";
import UsersUnionNew from "../UsersUnion/UsersUnionNew";
import {authState} from "../../routes/Auth/authContext";
import {useReactiveVar} from "@apollo/client";
import ProjectsGroup from "./ProjectsGroup";
import {allUsersUnions} from "../../subscriptions/allUsersUnions";
import {AllProjects, allProjects} from "../../subscriptions/allProjects";
import Search from 'antd/es/input/Search';
import Spinner from "../Spinner";

const {Title} = Typography;

interface ProjectsGroupTitleProps {
    owner: any
    linkToItem?: boolean
    additionalInfo?: any
}

const ProjectsGroupTitle = (props: ProjectsGroupTitleProps) => {
    const {t} = useTranslation()
    const {owner} = props;
    let id: string = "";
    let name: string | null = null;
    let caption: string | null = null;

    //TODO: почему не получается нормально работать с юнионами?
    if (!owner.__typename) {
        name = (owner as User).username;
    } else if (owner.__typename == "User") {
        id = "/User/" + (owner as User).id;
        // caption = t("aMemberInProjectOfUser");
        name = (owner as User).username;
    } else if (owner.__typename == "UsersUnion") {
        id = "/UsersUnion/" + (owner as UsersUnion).id;
        // caption = t("aMemberInProjectOfUsersUnion");
        name = (owner as UsersUnion).title;
    }

    return <Col span={24}><Title level={5} style={{height: "100%"}}>
        <>{caption}
            {props.linkToItem == false ? name : <Link to={id}>{name}</Link>}
        </>
        {props.additionalInfo}
    </Title>
    </Col>
}

function groupBy<T>(arr: T[], fn: (item: T) => any): Record<string, T[]> {
    return arr.reduce<Record<string, T[]>>((prev, curr) => {
        const groupKey = fn(curr);
        const group = prev[groupKey] || [];
        group.push(curr);
        return {...prev, [groupKey]: group};
    }, {});
}

const LinkToUsersUnionProjectInvites = ({usersUnion}: { usersUnion: UsersUnion }) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    if (usersUnion.projectInvites?.length == 0)
        return null;
    return <>
        <Divider type={"vertical"}></Divider>
        <Button type="link" icon={<FolderAddOutlined rev={undefined}/>}
                onClick={() => navigate(`/usersUnion/${usersUnion.id}/projects`)}
        >{t('usersUnion.projectsInvitesLink.key', {count: usersUnion.projectInvites.length})}</Button>
    </>
}

function ProjectsList({onlyMyProjects, onlyUsersUnions, onlyPeoples}: {
    onlyMyProjects?: boolean,
    onlyUsersUnions?: boolean,
    onlyPeoples?: boolean
}) {
    const [search, setSearch] = useState<string>();
    const {t} = useTranslation();
    const authInfo = useReactiveVar(authState);
    const [onlyArchived, setOnlyArchived] = useState<boolean>(false);

    const showAll = !onlyMyProjects && !onlyUsersUnions && !onlyPeoples;

    let myUnions = useReactiveVar(allUsersUnions);
    let allP = useReactiveVar(allProjects);

    const [archivedUsersUnions, {data: usersUnions, loading: loading1}] = useUsersUnionsListLazyQuery({
        variables: {
            showArchived: onlyArchived
        }
    })
    const [archivedProjectsList, {data: projects, loading: loading2}] = useProjectsListLazyQuery({
        variables: {
            filter: {
                archived: onlyArchived
            }
        }
    })

    useEffect(() => {
        if (onlyArchived) {
            archivedUsersUnions();
            archivedProjectsList();
        }
    }, [onlyArchived]);


    if (onlyArchived) {
        myUnions = {
            unions: usersUnions?.usersUnions as UsersUnion[] ?? [],
            invites: usersUnions?.usersUnionsInvites as UsersUnionMemberInvite[] ?? []
        };
        allP = new AllProjects(projects?.projects as Project[]);
    }


    let myUnionsProjects: UsersUnion[] = [];
    if (search) {
        if (myUnions.unions.some((u) => u.title.toUpperCase().includes(search.toUpperCase()))) {
            myUnionsProjects = myUnions.unions.filter(u => u.title.toUpperCase().includes(search.toUpperCase()))
        } else if (myUnions.unions.some(u => u.ownedProjects.some(p => p.title.toUpperCase().includes(search.toUpperCase())))) {
            myUnionsProjects = myUnions.unions.filter(u => u.ownedProjects.some(p => p.title.toUpperCase().includes(search.toUpperCase())))
        }
    } else myUnionsProjects = myUnions.unions

    const elements1: any[] = useMemo(() => {
        const elements: any[] = [];

        if (showAll || onlyMyProjects) {
            if (!onlyMyProjects)
                elements.push(<Divider key={"personalProjects"} orientation={"left"}>{t("personalProjects")}</Divider>);

            elements.push(<span key={"my" + authInfo.user.id} style={{width: "100%", paddingTop: 10}}>
              <ProjectsGroup projects={allP.myProjects(search)} context={authInfo.user} archived={onlyArchived}/>
        </span>);
        }

        // Список проектов, к которым у текущего пользователя есть доступ от лица объединения
        const accessAsUnionsToProjects: any[] = []

        if (myUnions && (showAll || onlyUsersUnions)) {
            if (!onlyUsersUnions)
                elements.push(<Divider key={"usersUnionTeams"}
                                       orientation={"left"}>{t("usersUnion.teams") as string}</Divider>);

            const items1: CollapseProps['items'] = []

            for (const i in myUnionsProjects) {
                const uu = myUnionsProjects[i];
                let projects = allP.myUnionsProjects(search).filter(p => p.usersUnionAsOwner && p.usersUnionAsOwner.id == uu.id);

                projects = projects.concat(accessAsUnionsToProjects.filter(aup => aup.accessAsUnion.id == uu.id))

                const ttttt = (projects.length).toString().slice(-1)

                // TODO: не получилось запустить plural плагин для i18next
                let projectsCount = 'projectsCount_many';
                if (ttttt === '0') projectsCount = 'projectsCount_zero'
                else if (ttttt === '1') projectsCount = 'projectsCount_one'
                else if (ttttt === '2') projectsCount = 'projectsCount_two'
                else if ((ttttt === '3' || ttttt === '4') && (projects.length < 10 || projects.length > 20)) projectsCount = 'projectsCount_few'

                items1.push({
                    key: "uu" + uu.id,
                    label: <ProjectsGroupTitle owner={uu} additionalInfo={<>
                        <span
                            style={{color: 'lightgray', padding: 5}}>{t(projectsCount, {count: projects.length})}</span>
                        <LinkToUsersUnionProjectInvites usersUnion={uu}/>
                    </>}/>,
                    children: <ProjectsGroup projects={projects} context={uu as UsersUnion} archived={onlyArchived}/>
                });
            }

            const expanded = items1.map(v => v.key) as string[];

            elements.push(<Collapse items={items1} defaultActiveKey={expanded}
                                    style={{width: '100%', border: "none"}}/>)
        }

        let otherUsers = groupBy(allP.otherUsersProjects(search), v => v.userAsOwner?.id);
        if (otherUsers && (showAll || onlyPeoples)) {
            if (!onlyPeoples)
                elements.push(<Divider key={"otherUsersProjects"}
                                       orientation={"left"}>{t("otherUsersProjects")}</Divider>);
            for (const i in otherUsers) {
                if (!i) continue
                const projects = (otherUsers[i] as Array<Project>).filter(p => {
                    if (p.accessAsUnion == null) return true
                    accessAsUnionsToProjects.push(p);
                    return false;
                });
                if (projects.length == 0)
                    break;

                const u = projects[0].userAsOwner as User;
                if (!u) continue

                elements.push(<span key={"u" + u.id} style={{width: "100%"}}>
                      <ProjectsGroupTitle owner={u} linkToItem={true}/>
                      <ProjectsGroup projects={projects} archived={onlyArchived}/>
                </span>);
            }
        }

        const otherUnions = allP.otherUnions(search);
        if (otherUnions.length > 0 && (showAll || onlyUsersUnions)) {
            if (!onlyUsersUnions)
                elements.push(<Divider key={"otherUsersUnionsProjects"}
                                       orientation={"left"}>{t("otherUsersUnionsProjects")}</Divider>);

            for (const i in otherUnions) {
                const uu = otherUnions[i];
                let projects = allP.projects.filter(p => p.usersUnionAsOwner && p.usersUnionAsOwner.id == uu.id);

                projects = projects.concat(accessAsUnionsToProjects.filter(aup => aup.accessAsUnion.id == uu.id))

                elements.push(<span key={"uu" + uu.id} style={{width: "100%"}}>
                        <ProjectsGroupTitle owner={uu} linkToItem={false}/>
                        <ProjectsGroup projects={projects} archived={onlyArchived}/>
                    </span>);
            }
        }
        return elements;
    }, [myUnionsProjects, myUnions, showAll, onlyUsersUnions, onlyPeoples, onlyMyProjects,allP]);

    let title = t('mainPage');
    if (onlyMyProjects) title = t("personalProjects");
    if (onlyUsersUnions) title = t("usersUnion.teams");
    if (onlyPeoples) title = t("otherUsersProjects");

    return <div style={{width: "100%"}}>
        <Spinner loading={[loading2, loading1]} />
        <Affix offsetTop={0}>
            <Row wrap={true} style={{background: "#EBEBEB"}}>
                <Col xs={0} sm={0} md={8} style={{display: 'flex', alignItems: "center", paddingLeft: 50}}>
                    <Title level={4} style={{margin: "1em", lineHeight: 0.4}}>{title}
                    </Title>
                    <Tooltip title={t('project.showArchivedProjects')}>
                        <Switch
                            checkedChildren={<SaveOutlined />}
                            unCheckedChildren={<SaveOutlined />}
                            onChange={(checked: boolean) => setOnlyArchived(checked)}
                            checked={onlyArchived}
                        />
                    </Tooltip>
                </Col>
                <Col xs={24} sm={24} md={12} style={{alignSelf: "center"}}><Search
                    placeholder={t('search') as string}
                    onSearch={(value) => setSearch(value)}
                    allowClear
                    style={{minWidth: 300, alignSelf: "center"}}/>
                </Col>
                <Col xs={24} sm={24} md={4} style={{display: "flex", placeItems: "center", padding: "0px 10px"}}>
                    <UsersUnionNew/>
                </Col>
            </Row>
        </Affix>

        <div style={{padding: "0px 20px"}}>
            <Row gutter={[8, 8]} datatype="flex">{elements1}</Row>
        </div>
    </div>
}

export default ProjectsList