import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {ApolloProvider, makeVar, ReactiveVar, useReactiveVar} from '@apollo/client';
import {RouterProvider} from "react-router-dom";
import AppRouter from "./AppRouter";
import client from "./ApolloClient";
import {App, ConfigProvider, theme, ThemeConfig} from "antd";
import {selectedLanguage} from "./i18n";
import StyledThemeProvider from './Components/StyledThemeProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export class UiAlgorithm extends String {
    public static dark: string = 'dark'
    public static light: string = 'light'
    public static compact: string = 'compact'
}

export const UiTheme: ReactiveVar<UiAlgorithm> = makeVar<UiAlgorithm>(UiAlgorithm.light);

//Цвета из макета, стили глобально делятся на ui И font (Как в ТЗ)
const colors={
    ui: {
        bg: '#12121E',
        bgDark: '#1d1d29',
        bgLight: '#EFF0F5',
        bgLight2: '#F9F9FC',
        bgLight3: '#DEDEE3',
        accent: '#FF6321',
        white: '#FFFFFF',
        succes: '#259F11',
        project1: '#F96D46',
        project2: '#CB63C1',
        project3: '#5AA4DA',
        project4: '#6CCB5D',
        priorityHigh: 'rgba(255, 49, 49, 0.7)',
        priorityMedium: '#FFEE94',
        priorityMediumHover: '#FFE768',
        priorityHighHover: '#FF3131',
        succesHover: '#107400',
        redHover: '#CE0000',
        accentHover: '#DB4200',

        // Этих цветов не было в исходной палитре
        accent05: 'rgba(255,99,33,0.5)', // TODO: этот цвет нужно убрать. Еще раз проговорить с Костей
        stroke: '#50505aff',
        red01: '#ff313110',
        bgDark03: 'rgba(29, 29, 41, 0.3)',
        bgDark05: 'rgba(29, 29, 41, 0.5)'
    },

    font: {
        primary: '#1d1d29',
        primarySemitransparent:  'rgba(29, 29, 41, 0.3)',
        red: '#FF3131',
        red01: '#ff313112',
        accent: '#FF6321',
        white: '#FFFFFF'
    }
}

//Кастомные токены, прокидываем в тему, чтобы из компонентов был доступ к цветам с макета и к каким-то особым свойствам
export interface CustomToken{
            colorTextAccent: string,
            colors: typeof colors
            baseBorderRadius: number
}

const Container: React.FC = () => {
    const uiTheme = useReactiveVar(UiTheme);
    const lang = useReactiveVar(selectedLanguage);
    let algorithm = theme.darkAlgorithm;
    switch (uiTheme) {
        case UiAlgorithm.compact:
            algorithm = theme.compactAlgorithm;
            break
        case UiAlgorithm.light:
            algorithm = theme.defaultAlgorithm;
            break
    }

    const customTheme={
        algorithm: algorithm,

        //Наши значения для глобальных стилей
        token: {
            fontFamily: 'Geologica',
            colorErrorText: colors.font.red,
            colorText: colors.font.primary,
            colorTextAccent: colors.font.accent,
            baseBorderRadius: 8,
            colors: colors,
            colorPrimary: colors.ui.accent,

            //Размеры экранов:
            //Мобилка
            screenXSMin: 375,
            screenXSMax: 599,
            screenXS: 375,

            //Планшеты
            screenSMMin: 600,
            screenSMMax: 1023,
            screenSM: 600,

            //Старые компы с низким разрешением
            screenMDMin: 1024,
            screenMDMax: 1365,
            screenMD: 1024,

            //Компы
            screenLGMin: 1366,
            screenLGMax: 1919,
            screenLG: 1366,

            //Далее под высокие разрешения, посмотрим, пригодятся или нет, но без них ant ругается
            screenXLMin: 1920,
            screenXLMax: 2560,
            screenXL: 1920,

            screenXXLMin: 2560,
            screenXXLMax: 3840,
            screenXXL: 2560
        },

        //Переопределение стилей антовских стандартных компонентов
        components: {

            Button: {
                ghostBg: colors.ui.bgLight,
                defaultGhostColor: colors.ui.accent,
                defaultGhostBorderColor: colors.ui.bgLight,
                textFontSize: 14
            },

            Input: {
                colorBgBase: colors.ui.bgLight,
                colorBgContainer: colors.ui.bgLight,
                lineWidth: 0,
                hoverBg: colors.ui.bgLight3,
                algorithm: true,
                activeBg: colors.ui.bgLight3,
                colorTextPlaceholder: colors.font.primarySemitransparent,
                color: colors.font.primary
            },

            Drawer: {
                colorBgElevated: colors.ui.white,
                colorIcon: colors.ui.bgDark,
                colorIconHover: colors.ui.accent,
                colorBgTextHover: 'unset'
            }
        }
    } as ThemeConfig

    return <ConfigProvider csp={{ nonce: 'YourNonceCode' }} theme={customTheme} locale={lang.locale}>
        <StyledThemeProvider>
            <App>
                <RouterProvider router={AppRouter} />
            </App>
        </StyledThemeProvider>
    </ConfigProvider>;
}

//TODO: return back React.StrictMode
root.render(
  // <React.StrictMode>
      <ApolloProvider client={client}>
          <Container />
      </ApolloProvider>
  // </React.StrictMode>
);

reportWebVitals();
