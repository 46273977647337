import React, {type SVGProps} from "react"

export function IconTasks(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=tasks, size=24" clipPath="url(#clip0_29_5459)">
        <g id="Frame 3">
            <path id="Vector" d="M9 6H20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2" d="M3.80078 5.8001L4.60078 6.60009L6.60077 4.6001" stroke="currentColor"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_3" d="M3.80078 11.8001L4.60078 12.6001L6.60077 10.6001" stroke="currentColor"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_4" d="M3.80078 17.8001L4.60078 18.6001L6.60077 16.6001" stroke="currentColor"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_5" d="M9 12H20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_6" d="M9 18H20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_29_5459">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}
