import React, {useState} from 'react';
import {Alert, Button, Col, Form, message, notification, Popconfirm, Row, Tabs, Tooltip, Watermark} from 'antd';
import {useTranslation} from "react-i18next";
import {
    AccessLevel,
    PaymentSlotType,
    User,
    UsersUnion,
    UsersUnionMember,
    useUsersUnionAddMembersMutation,
    useUsersUnionLeaveMutation,
    useUsersUnionQuery,
    useUsersUnionUpdateMutation
} from "../../generated-types";
import UsersUnionMembersEditor from "./UsersUnionMembersEditor";
import {useNavigate, useParams} from "react-router-dom";
import UsersUnionSettings from "./UsersUnionSettings";
import {authState} from "../../routes/Auth/authContext";
import {useReactiveVar} from "@apollo/client";
import Spinner from "../Spinner";
import UsersUnionFilesList from "../File/UsersUnionFilesList";
import UsersUnionProjectsList from "./UsersUnionProjectsList";
import {ExportOutlined} from "@ant-design/icons";
import {CustomPageHeader} from "../AntCastomComponents/PageHeader";
import {isMobile} from "react-device-detect";

const UsersUnionCard: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const [form] = Form.useForm();
    const { id, tab } = useParams();
    const { loading, error, data, refetch } = useUsersUnionQuery({
        variables: {
            id: id || "",
            showArchived: false,
            slotType: PaymentSlotType.UsersUnionMembers
        }
    });

    const [selectedTab, setTab] = useState<string>(tab ?? 'projects');

    const [usersUnionAddMembers] = useUsersUnionAddMembersMutation({
        onCompleted: () => {
            refetch();
        }
    });
    const [usersUnionUpdate] = useUsersUnionUpdateMutation({
        onCompleted:()=>success()
    });

    const success = () => {
        messageApi.open({
            type: 'success',
            content: t('editDataMassage'),
            style: {
                marginTop: '20vh',
            },
        });
    };
    const authInfo = useReactiveVar(authState);


    const [leaveUsersUnion, { }] = useUsersUnionLeaveMutation({
        onCompleted: () => {
            notification.success({
                message: t("usersUnion.leavedSuccessfully"),
                description: union.title,
            });
            navigate("/")
        }
    });


    if (!id)
        return <div>define usersUnion id please</div>

    if (loading) return <Spinner loading />;
    if (error) return<Alert type="error" message={error.message} banner />;

    let union: any = data == null ? null : data.usersUnion;


    if (union.members.length == 0)
        return <div>No access</div>


    if (!union)
        return <div>define right usersUnion id please</div>

    const empty = data?.usersUnion?.paymentAccount?.emptySlotsCount ?? 0;

    const openAddSlotsModal = () => {
        // TODO: тут надо разобраться в структурах с этими TypesWithAmounts и понять, зачем они везде используются
        // addSlotsContext({
        //     slotsType: PaymentSlotType.UsersUnionProjectMember
        // });
    }

    const invitedProjects = union.projectInvites.map((v: any) =>v.project);

    const isAdmin = union.members.some((m: UsersUnionMember)=>m.user.id == authInfo.user.id && m.accessLevel == AccessLevel.Admin)

    const save = (id:string, input: any) => {
        usersUnionUpdate({variables: {id, input}})
    };

    const toggleOnlyArchivedProjects = (onlyArchived: boolean)=>{
        refetch({
            showArchived: onlyArchived
        });
    }

    const content: any = {
        projects: <UsersUnionProjectsList key={union.id} union={union} onChange={toggleOnlyArchivedProjects}/>,
        // tasks: <NotImplemented/>
    };

    let items: any[] = [
        {
            label: `${t('usersUnion.projects')} (${union.ownedProjects.length}${invitedProjects.length ? "+"+invitedProjects.length : ""})`,
            key: "projects",
            children: <></>
        },
        // {
        //     label: t('usersUnion.tasks'),
        //     key: "tasks",
        //     children: <></>
        // }
        ];

    if (union.currentUserIsMember) {
        content.files = <UsersUnionFilesList usersUnion={union} isAdmin={isAdmin}/>
        content.members = <UsersUnionMembersEditor usersUnion={union as UsersUnion} isAdmin={isAdmin}
           //вставить проверку на наличие свободных слотов
            membersAdded={({users, emails})=>{
                if(users.length <= empty){
                    const input = {
                        emails: emails,
                        users: users.map(u => (u as User).id).filter(v => v != undefined),
                        usersUnionId: union.id
                    };
                    usersUnionAddMembers({variables: {input}})
                    return true;
                } else {
                    openAddSlotsModal();
                    return false
                }
        }
        } />
        content.settings = <>
            <UsersUnionSettings union={union} form={form} save={save} saveButton={true} deleteButton={isAdmin}/>
            {contextHolder}</>

        items.push(
        {
            label: t('usersUnion.files'),
            key: "files",
            children: <></>
        })
        items.push({
            label: t('usersUnion.members') + " ("+union.members.length+")",
            key: "members",
            children: <></>
        })

        if (isAdmin)
            items.push({
                label: t('usersUnion.settings'),
                key: "settings",
                children: <></>
            })
    }

    return (
        <Watermark
            content={data?.usersUnion?.paymentAccount?.isBlocked ? t('paymentAccount.unionIsBlocked')! : undefined}>
            <CustomPageHeader
                title={union.title}
                subTitle={t('usersUnion.team')}
                extra={[]}
                footer={
                    <Tabs defaultActiveKey={tab} activeKey={selectedTab} items={items}
                          tabBarExtraContent={<Tooltip title={t('usersUnion.leaveTooltip')}>
                              <Popconfirm
                                  icon={null}
                                  title={t('usersUnion.leaveConfirmTitle')}
                                  description={t('usersUnion.leaveConfirmDescription')}
                                  okButtonProps={{type: "primary", className: "red"}}
                                  onConfirm={() => {
                                      leaveUsersUnion({
                                          variables: {
                                              usersUnionId: union.id
                                          }
                                      })
                                  }}
                                  onCancel={() => {
                                  }}
                                  okText={t('yes')}
                                  cancelText={t('no')}
                              >
                                  <Button type={"text"} icon={<ExportOutlined/>}>{t("usersUnion.leave")}</Button>
                              </Popconfirm>
                          </Tooltip>}
                          onChange={setTab}/>
                }
            >
            </CustomPageHeader>
            <Row justify="space-around" align="middle"
                 style={{margin: isMobile ? 0 : "0px 15px 0px 15px", flexGrow: 1}}>
                <Col span={24}>
                    {content[selectedTab]}
                </Col>
            </Row>
        </Watermark>

    )
};

export default UsersUnionCard;
