import React, {useEffect, useState} from "react";
import styled from "styled-components";
import ProjectAvatar from "./ProjectAvatar";
import {Maybe, Project} from "../../generated-types";
import {Icon} from "../Icon/Icon";
import {useTranslation} from "react-i18next";
import {AllIconsDict, AvailableIcons} from "../Icon/AllIcons";
import {Button, ColorPicker} from "antd";
import {IconEdit} from "../Icon/IconEdit";


const defaultColors = [
    '#FF6321',
    'rgba(245,34,45)',
    'rgba(250,219,20)',
    'rgba(139,187,17)',
    'rgba(82,196,26)',
    'rgba(19,168,168)',
    'rgba(22,119,255)',
    'rgba(47,84,235)',
    'rgba(114,46,209)',
    'rgba(235,47,150)',
];

export const ProjectDefaultColor = defaultColors[0];

const defaultIcons = [
    AvailableIcons.Team,
    AvailableIcons.Attantion,
    AvailableIcons.Tasks,
    AvailableIcons.Calend,
    AvailableIcons.Projects,
    AvailableIcons.Home,
    AvailableIcons.History,
    AvailableIcons.Filter,
    AvailableIcons.DragTask,
    AvailableIcons.Stop,
    AvailableIcons.SubTasks,
    AvailableIcons.Start,
    AvailableIcons.Search,
    AvailableIcons.Tag,
    AvailableIcons.Files,
    AvailableIcons.Docs,
    AvailableIcons.Details,
    AvailableIcons.Man,
    AvailableIcons.Comment,
    AvailableIcons.Favourites
]

interface ProjectIconEditorProps {
    project: Project,
    onChange: (icon?: Maybe<string>, iconColor?: Maybe<string>) => void
}

const StyledDiv = styled.div<{}>`
    background-color: ${p => p.theme.colors.ui.bgLight2};
    padding: 16px;
    border-radius: 8px;
    gap: 10px;
    display: flex;

    .clr-holder {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }

    .icons-holder {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }

    .clr-btn {
        min-height: 24px;
        height: 24px;
        min-width: 24px;
        width: 24px;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
    }

    .icon-btn {
        min-height: 24px;
        height: 24px;
        min-width: 24px;
        width: 24px;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${p => p.theme.colors.ui.bgLight3};
        position: relative;
    }

    .btn:hover::before {
        content: '';
        position: absolute;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
        border: 1px solid ${p => p.theme.colors.ui.accent};
        border-radius: 6px;
    }

    .isSelected::before {
        content: '';
        position: absolute;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
        border: 1px solid ${p => p.theme.colors.ui.accent};
        border-radius: 6px;
    }

    .icon-settings {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
`

export const ProjectIconEditor: React.FC<ProjectIconEditorProps> = ({project, onChange}) => {
    const {t} = useTranslation();
    const [iconColor, setIconColor] = useState(project.iconColor)
    const [icon, setIcon] = useState(project.icon)
    const [pickerOpened, setPickerOpened] = useState(false)

    useEffect(() => {
        onChange(icon, iconColor)
    }, [icon, iconColor]);

    if (project.iconColor && defaultColors.indexOf(project.iconColor) < 0)
        defaultColors.push(project.iconColor)

    return (
        <StyledDiv>
            <div><ProjectAvatar icon={icon} iconColor={iconColor} size={"48"}/></div>
            <div className={"icon-settings"}>

                <div>{t('project.settingsPage.color')}</div>
                <div className={"clr-holder"}>
                    {defaultColors.map((clr, i) => {
                        const isSelected = clr == iconColor;
                        return <div key={i} style={{backgroundColor: clr}}
                                    onClick={() => {
                                        setIconColor(clr)
                                    }}
                                    className={"btn clr-btn" + (isSelected ? " isSelected" : "")}></div>
                    })}

                    <div style={{position: "relative"}}>
                        <ColorPicker
                            onOpenChange={v => setPickerOpened(v)}
                            open={pickerOpened}
                            size={"small"}
                            style={{opacity: 50, position: "absolute"}}
                            styles={{
                                popupOverlayInner: {zIndex: 1100},
                            }}
                            value={iconColor}
                            onChange={(ccc, ccc2) => {
                                setIconColor(ccc2)
                            }}
                            presets={[
                                {
                                    label: null,
                                    colors: defaultColors,
                                }
                            ]}
                        />
                        <Button ghost size={"small"} onClick={() => {
                            setPickerOpened(!pickerOpened)
                        }} icon={<IconEdit/>}>
                            {t('project.settingsPage.selectColor')}
                        </Button>
                    </div>
                </div>

                <div>{t('project.settingsPage.icon')}</div>
                <div className={"icons-holder"}>
                    {defaultIcons.map((icn, i) => {
                        const isSelected = icn == icon;
                        return <div key={i} className={"btn icon-btn" + (isSelected ? " isSelected" : "")}
                                    onClick={() => {
                                        setIcon(icn)
                                    }}
                        >
                            <Icon icon={AllIconsDict[icn]} size={"16"}/>
                        </div>
                    })}
                </div>
            </div>
        </StyledDiv>
    )
};

export default ProjectIconEditor;