import {Card, Col, message, Row, Space, theme, Tooltip} from 'antd';
import React, {useMemo} from 'react';
import {EditTaskInput, Task, TasksView, useTaskSaveMutation} from "../../generated-types";
import {useTranslation} from 'react-i18next';
import {editTaskContext} from "./TaskEditor";
import Tagger from "../Tagger/Tagger";
import ProjectBreadcrumb from "../ProjectBreadcrumb";
import TaskMembers from "./TaskMembers";
import TaskResolved, {ResolvedCard} from "./TaskResolved";
import TaskTitle from "./TaskTitle";
import {makeVar, ReactiveVar} from "@apollo/client";
import {isMobile, isMobileOnly} from "react-device-detect";
import styled from 'styled-components';
import {allPriorities} from './TaskImportance';
import TaskDates from './TaskDates';
import {IconFiles} from '../Icon/IconFiles';
import {IconSubTasks} from '../Icon/IconSubTasks';
import TaskNumber from './TaskNumber';
import TaskFilesAndChildTasks from './TaskFilesAndChildTasks';
// import TaskResolvedBlur from "./TaskResolvedBlur";

const dateFormat = 'DD.MM';

const boxImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGUExURdva0////yqEAr4AAAABdFJOUwE34ejwAAAAAWJLR0QB/wIt3gAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB+cIDhcDNATKr+sAAAAKSURBVAjXY2AAAAACAAHiIbwzAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA4LTE0VDIzOjAzOjQyKzAwOjAwy5RdQAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wOC0xNFQyMzowMzo0MiswMDowMLrJ5fwAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDgtMTRUMjM6MDM6NTIrMDA6MDAhdsS9AAAAAElFTkSuQmCC'

const TaskCardRow = styled(Row) <{ $isMobile: boolean, $gapped?: boolean, $reverse?: boolean }>`
    padding-left: ${({ $gapped }) => $gapped ? '32px' : '0px'};
    row-gap: 8px;
    column-gap: ${({$isMobile})=>$isMobile? '4px' : '8px'};
    height: fit-content;
    flex-direction: ${({$reverse})=>$reverse? 'row-reverse' : 'row'};

    &:empty{
        display: none;
    }
`

const TaskCardContent=styled.div`
    flex-grow: 1; 
    display: flex; 
    flex-direction: column;
    gap: 8px;

    &:empty{
        display: none;
    }
`

const TaskIconsContainer=styled.div`
    /*flex-grow: 1;*/
    align-self: end;
    display: flex;
    justify-content: end;
`
const CardGridStyled = styled(Card.Grid) <{ $resolved: boolean, $statusColor: string, $isMobile: boolean }>`
        width: 100% !important;
        position: relative;
        padding: ${({ $isMobile }) => $isMobile ? '12px 8px' : '16px'} !important;
        box-shadow: none !important;
        opacity: ${({ $resolved }) => $resolved ? 0.5 : 1};
        box-sizing: border-box;
        

        &::before{
            position: absolute;
            content: '';
            width: 40px;
            height: 4px;
            border-radius: 2px;
            background-color: ${({ $statusColor }) => $statusColor};
            top: 0;
        }
`

const SpanStyled=styled.span`
font-size: 12px;
display: flex;
align-items: center;
gap: 4px;

>svg{
    stroke-width: 1.5;
    width: 16px;
    height: 16px;
}
`
interface TasksBoardItemProps {
    task: Task,
    taskView?: TasksView,
    showProjectTitle: boolean
}

export const taskToTaskInput = (task: Task, tags: string[]) => {
    const { title, description, project, members, files, status, startTime, startDate, endTime, endDate, importance } = task
    const res = {
        startTime, startDate, endTime, endDate, importance, description,
        title,
        members: members.map(m => ({ userId: m.user.id, memberType: m.memberType })) ?? [],
        files: files.map(f => ({ externalFilePath: f.path, name: f.name })) ?? [],
        projectId: project.id,
        tags: tags,
        status
    } as EditTaskInput

    return res
}
export const droppedOnTask: ReactiveVar<Task | null> = makeVar<Task | null>(null);

const TasksBoardItem: React.FC<TasksBoardItemProps> = ({task, showProjectTitle, taskView}) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();

    const [taskSave] = useTaskSaveMutation();

    const onTagDelete = (tags: string[]) => {
        taskSave({
            variables: {
                id: task.id,
                task: taskToTaskInput(task, tags)
            },
            onError: (error, clientOptions) => {

                message.error(t('tags.tagDelitionErrorMessage'))
            },
        })
    }
    const showDates = task.startDate || task.endDate;
    const showMembers = task.members.length > 0
    const showTags=task.tags.length > 0
    const showTaskContent=showDates || showMembers || showTags

    let gridStyle: React.CSSProperties = ResolvedCard(task.resolved, {
    });

    const openTask = (e: any) => {
        editTaskContext({taskId: task.id, taskView: taskView});
        e.preventDefault();
        e.stopPropagation();
    }

    const priorityColor = useMemo(() => {
        const priorities = allPriorities(token)
        const color = priorities[task.importance]?.bgColor
        return color ? color : 'unset'

    }, [task.importance])


    return <CardGridStyled
        $statusColor={priorityColor}
        $resolved={task.resolved}
        $isMobile={isMobileOnly}
        style={gridStyle} onClick={openTask}>
        {showProjectTitle && <div><ProjectBreadcrumb projectId={task.project.id} /></div>}
        <Col style={{gap: 8, display: 'flex', flexDirection: 'column'}}>
            <TaskCardRow $isMobile={isMobileOnly} wrap={false} align={'middle'}>
                <Col style={{ height: 24 }}>
                    <TaskResolved
                        id={task.id}
                        value={task.resolved}
                        key={"" + task.resolved}
                        disabled={task.project.archived || task.project.paymentAccount?.isBlocked}
                    />
                </Col>
                <Col flex={'auto'}>
                    <TaskTitle task={task} tasksView={taskView}/>
                </Col>
                <Col>
                    <TaskNumber number={task.number}/>
                </Col>
            </TaskCardRow>
            <TaskCardRow $isMobile={isMobile} wrap $gapped $reverse={!showTaskContent}>
            {showTaskContent && <TaskCardContent>
                    {(showMembers || showDates) && <TaskCardRow $isMobile={isMobileOnly}>
                        {task.members.length > 0 && <TaskMembers task={task} />}
                        {showDates && <TaskDates task={task} />}
                    </TaskCardRow>}

                    {task.tags.length > 0 && <TaskCardRow $isMobile={isMobileOnly}>
                        <Tagger key={task.tags.reduce((m, v) => m + v, "")} projectId={task.project.id}
                            defaultValue={task.tags} onCloseTag={onTagDelete}
                            allowAdd={false} allowRemove={false} maxTags={5} maxLines={1} />
                    </TaskCardRow>}
                </TaskCardContent>}
                <TaskFilesAndChildTasks task={task}/>
            </TaskCardRow>
        </Col>
    </CardGridStyled>
}

export default TasksBoardItem