import React, {useEffect, useState} from "react";
import Text from "antd/es/typography/Text";
import {Button, Col, InputNumber, Row} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import ButtonGroup from "antd/es/button/button-group";

interface ProjectUnionInvitesEditorProps {
    maxMembers?: number;
    onChange: (value: number) => void;
}

export const ProjectUnionInvitesEditor: React.FC<ProjectUnionInvitesEditorProps> = ({maxMembers, onChange}) => {
    const {t} = useTranslation();
    const [value, setValue] = useState<number>(1);

    const onChangeInput = (v: number | null) => {
        if (v && v > 1)
            setValue(v);
    }

    const onClickButton = (type: string) => {
        if (value < 1) {
            setValue(1)
            return;
        }

        if (type === 'increment') {
            setValue((prev) => prev + 1);
        } else if (value > 1) {
            setValue((prev) => prev - 1);
        }
    }

    useEffect(() => {
        if (onChange) onChange(value)
    }, [value]);


    return <Row style={{alignItems: 'baseline', gap: 12}}>
            {maxMembers && <Col>
	            <Text style={{marginTop: 12, display: 'block'}}>
                {t('usersUnion.processProjectInvite.maxMembers', {count: maxMembers})}
            </Text>
            </Col>}
            <Col>
                <Text style={{marginTop: 12, display: 'block'}}>
                    {t('membersList.userUnionPlaces')}
                </Text>
            </Col>
            <Col>
                <ButtonGroup>
                    <Button
                        onClick={() => onClickButton('decrement')}
                        disabled={value <= 1}
                        icon={<MinusOutlined/>}
                        type={'primary'}
                    />
                <InputNumber
                    style={{width: 40, borderRadius: 0}}
                    controls={false}
                    value={value}
                    min={1} defaultValue={value}
                    onChange={(value) => onChangeInput(value)}/>
                <Button
                    icon={<PlusOutlined/>}
                    onClick={() => onClickButton('increment')}
                    type={'primary'}
                />
                </ButtonGroup>
            </Col>
        </Row>
}