import React, {type SVGProps} from "react"

export function IconAttantion(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=attantion, size=16" clipPath="url(#clip0_671_33917)">
        <path id="Vector"
              d="M7.99985 15.579C12.1856 15.579 15.5788 12.1858 15.5788 8.00009C15.5788 3.81435 12.1856 0.421143 7.99985 0.421143C3.81411 0.421143 0.420898 3.81435 0.420898 8.00009C0.420898 12.1858 3.81411 15.579 7.99985 15.579Z"
              stroke="currentColor" strokeMiterlimit="10"/>
        <g id="Group">
            <path id="Vector_2" d="M8 4.44629V9.65892" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
            <path id="Vector_3"
                  d="M8.00023 12.4463C8.34904 12.4463 8.63181 12.1635 8.63181 11.8147C8.63181 11.4659 8.34904 11.1831 8.00023 11.1831C7.65142 11.1831 7.36865 11.4659 7.36865 11.8147C7.36865 12.1635 7.65142 12.4463 8.00023 12.4463Z"
                  fill="currentColor"/>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_671_33917">
            <rect width="16" height="16" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}
