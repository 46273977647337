import React, {CSSProperties, useState} from 'react'
import TooltipButton from '../TooltipButton'
import {IconCopyLink} from '../Icon/IconCopyLink'
import {message, theme} from 'antd'
import {useTranslation} from 'react-i18next'
import {generatePath} from "react-router-dom";
import {routes} from "../../AppRouter";

interface TaskLinkButtonProps {
    taskId: string
    projectId: string
    fullView?: boolean
    style?: CSSProperties
}

function TaskLinkButton(props: TaskLinkButtonProps) {

    const { token } = theme.useToken()
    const { t } = useTranslation()
    const [hover, setHover]=useState(false)

    const backgroundColor=hover? token.colors.ui.bgLight3 : token.colors.ui.bgLight

    const buttonStyle: CSSProperties = {
        color: token.colors.font.primary,
        border: 'unset',
        boxShadow: 'unset',
        backgroundColor: props.fullView? 'unset' : backgroundColor,
        borderRadius: 8,
        ...props.style,
        fontSize: 16,
        padding: 8
    }

    const clickHandler=()=>{
        const path = generatePath(routes.projectTask, {
            taskId: props.taskId,
            projectId: props.projectId
        })
        navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}${path}`)

        .then(()=>{
            message.success(t("task.copyLinkSucces"))
        })
        .catch(error=>{
            message.error(t("task.copyLinkError"))
        })
    }

    return <TooltipButton tooltipProps={{ title: t("task.copyLink") }}
        style={buttonStyle}
        styles={{ icon: { opacity: props.fullView? 1 : 0.5, strokeWidth: 1.5} }}
        icon={<IconCopyLink />}
        onMouseEnter={()=>setHover(true)}
        onMouseLeave={()=>setHover(false)}
        onClick={clickHandler}
        >
        {props.fullView && <span style={{fontSize: 14}}>{t("task.copyLink")}</span>}
    </TooltipButton>
}

export default TaskLinkButton