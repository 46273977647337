import React, {type SVGProps} from "react"

export function IconEnter(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=enter, size=24">
        <g id="Frame">
            <path id="Vector" d="M10.25 19.25L6.75 15.75L10.25 12.25" stroke="currentColor" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path id="Vector_2" d="M6.75 15.75H12.75C14.9591 15.75 16.75 13.9591 16.75 11.75V4.75" stroke="currentColor"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </g>
</svg>

    )
}
