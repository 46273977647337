import React, {useState} from 'react';
import {Select} from 'antd';
import {useTranslation} from 'react-i18next';
import {AccessLevel} from "../generated-types";
import styled from "styled-components";

interface AccessLevelSelector {
    selected: AccessLevel,
    disabled?: boolean,
    onChange: (Level: AccessLevel) => boolean
}


const SelectStyled = styled(Select)<{}>`
    div.ant-select-selector {
        border: 0;
    }
`

const AccessLevelSelector: React.FC<AccessLevelSelector> = (props) => {
    const {t} = useTranslation();
    const [val, setVal] = useState<AccessLevel>(props.selected);

    return <SelectStyled
        defaultValue={props.selected}
        variant={"borderless"}
        popupMatchSelectWidth={false}
        disabled={props.disabled}
        value={val}
        onChange={(v: any) => {
            let res = props.onChange(v as AccessLevel);
            if (!res) return;
            setVal(v);
        }}
        options={[
            { value: AccessLevel.Admin, label: t('accessLevel.admin') },
            { value: AccessLevel.Member, label: t('accessLevel.member') }
        ]}
    />
};

export default AccessLevelSelector;