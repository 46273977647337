import React, {type SVGProps} from "react"

export function IconUser(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=user, size=16" height="1em">
        <g id="Frame 415">
            <path id="Vector"
                  d="M7.83763 8.31116C9.81849 8.31116 11.4243 6.6745 11.4243 4.65558C11.4243 2.63666 9.81849 1 7.83763 1C5.85678 1 4.25098 2.63666 4.25098 4.65558C4.25098 6.6745 5.85678 8.31116 7.83763 8.31116Z"
                  stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
            <g id="Group">
                <path id="Vector_2"
                      d="M13.5056 12.3402C12.5098 13.9113 10.3383 15.0002 7.90477 15.0002C5.32897 15.0002 3.12755 13.7791 2.1167 12.2002"
                      stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
                <path id="Vector_3"
                      d="M11.1843 9.64889C10.2109 10.1078 9.07277 10.5122 7.85974 10.5122C6.64672 10.5122 5.56848 10.1467 4.5127 9.64111"
                      stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
                <path id="Vector_4"
                      d="M2.13922 12.2391C1.86217 11.7724 1.99695 10.9479 2.55854 10.279C3.17254 9.54015 3.94378 9.40015 4.52034 9.64127"
                      stroke="currentColor" strokeMiterlimit="10"/>
                <path id="Vector_5"
                      d="M13.4457 12.4412C13.8725 11.8112 13.6704 11.0412 13.0863 10.3412C12.5023 9.64118 11.7909 9.43118 11.1694 9.66451"
                      stroke="currentColor" strokeMiterlimit="10"/>
            </g>
        </g>
    </g>
</svg>

    )
}
